import React, { useState } from "react";
import "./ManageImgCard.scss";
import Button from "components/inputs/Button";
import { icons } from "utils/constants";
import DeleteModal from "../DeleteModal";
import { Blurhash } from "react-blurhash";
const ManageImgCard = ({
  title,
  Image,
  imgUrl,
  handleDelete,
  setimageCopper = () => {},
  handleUpload = () => {},
  hash,
}) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const handleUpdateImg = async (event) => {
    if (event.target.value) {
      setimageCopper(event.target.value);
      handleUpload(event.target?.file);
    }
  };
  const deleteImage = () => {
    handleDelete();
    setIsDeleteModal(false);
  };
  const onClose = () => {
    setIsDeleteModal(false);
  };
  const defaultBlurhash = "LEHV6nWB2yk8pyo0adR*.7kCMdnj";
  const blurhashToUse = hash && hash.length === 86 ? hash : defaultBlurhash;

  return (
    <div id="manageImg-container">
      <div className="Card-8 my-3">
        <p className="text-16-700 font-Avenir">{title}</p>
        <div className="row my-2">
          <div className="col-3">
            {imgUrl ? (
              <img src={imgUrl} className="card-image" alt="userImg" />
            ) : (
              <div
                style={{
                  borderRadius: "50%",
                  overflow: "hidden",
                  display: "inline-block",
                  height: "94px",
                }}
              >
                <Blurhash
                  hash={blurhashToUse}
                  width={94}
                  height={94}
                  punch={1}
                  style={{ borderRadius: "50%" }}
                />
              </div>
            )}
          </div>
          <div className="col-7 fa-center">
            <div className="position-relative">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (e?.target?.files[0]) {
                    handleUpdateImg({
                      target: {
                        value: URL.createObjectURL(e?.target?.files[0]),
                        file: e?.target?.files[0],
                      },
                    });
                  }
                }}
              />

              <Button
                btnText="Upload Image"
                className="font-Avenir text-16-400 pointer"
                btnStyle="BLUE-8"
              />
            </div>
          </div>
          <div className="col-2 fc-center">
            <button
              className="fc-center flex-column"
              disabled={!Image}
              onClick={() => setIsDeleteModal(true)}
            >
              <img src={icons.deleteIcon} alt="delete" />
              <span className="font-Avenir text-10-400 mb-0 mt-2 color-ffff">
                Remove
              </span>
            </button>
          </div>
        </div>
      </div>
      {isDeleteModal && (
        <DeleteModal
          onClose={onClose}
          onDelete={deleteImage}
          question="Do you want to delete this image?"
        />
      )}
    </div>
  );
};

export default ManageImgCard;
