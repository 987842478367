import React, { useEffect, useState } from "react";
import "./AddUserDetails.scss";
import { commonRoute, icons } from "utils/constants";
import userImg1 from "assets/images/common/user9.png";
import userImg2 from "assets/images/common/user10.png";
import userImg3 from "assets/images/common/user8.png";
import Button from "components/inputs/Button";
import { useNavigate } from "react-router-dom";
import TextInput from "components/inputs/TextInput";
import { Formik } from "formik";
import * as Yup from "yup";
import { getDataFromLocalStorage, storeLocalStorageData } from "utils/helpers";
import Dropdown from "components/inputs/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getCountryData, getStateCityData, storeCountryId } from "store/slices";
const AddUserDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const profileRDX = useSelector((state) => ({
  //   countryList: state.profile.countryList?.map((ele, index) => ({
  //     id: ele.name.common,
  //     label: ele.name.common,
  //   })),
  // }));
  const profileRDX = useSelector((state) => ({
    countryList: state.profile.countryList?.map((ele, index) => ({
      id: ele.id,
      label: ele.name,
    })),
    stateList: state.profile.stateList?.map((ele, index) => ({
      id: ele.id,
      label: ele.name,
      city: ele.cities,
    })),
  }));
  const { countryList, stateList } = profileRDX || {};
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    city: "",
    state: "",
    country: "",
  });

  useEffect(() => {
    const storedValues = getDataFromLocalStorage("registerData");
    if (storedValues) {
      setInitialValues({
        name: storedValues?.name || "",
        email: storedValues?.email || "",
        city: storedValues?.city || "",
        state: storedValues?.state || "",
        country: storedValues?.country || "",
      });
    }
    if (storedValues?.country) {
      const country = countryList.find(
        (ele) => ele.label === storedValues?.country
      );
      dispatch(getStateCityData(country?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .transform((value) => value.trim())
      .matches(/^[a-zA-Z\s]+$/, "Number or Special character  is not allowed.")
      .required("First name is required."),
    email: Yup.string()
      .test("isValidEmail", "Email is not valid.", function (value) {
        if (value) {
          return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
        }
        return true;
      })
      .required("Email is required."),
    city: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
    state: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
    country: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
  });
  const handleSubmitForm = async (values) => {
    storeLocalStorageData({ registerData: values });
    navigate(commonRoute.setPin);
  };
  const fetchData = async () => {
    await dispatch(getCountryData());
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="add-userDetails-container">
        <div className="row">
          {/* left side */}
          <div className="col-xxl-6 col-7">
            <div className="deep-analytics mt-5 ps-5 relative">
              <h2 className="text-35-500 color-ffff">Get rewarded</h2>
              <p className="text-35-300 color-C700">
                on every network you establish
              </p>
            </div>
            <div className="backRound-shadow">
              <span className="pink-dot">
                <div className="w-23 h-23 rounded-circle bg-1B3F"></div>
              </span>
              <span className="green-dot">
                <div className="w-11 h-11 rounded-circle bg-AE0C"></div>
              </span>
              <div className="big-round-shadow"></div>
              <div className="medium-round-shadow"></div>
              <div className="small-round-shadow"></div>
              <div className="user-data-1">
                <div className="user-rewards">
                  <img src={userImg1} alt="img" />
                  <div className="reward-block">
                    <span className="G-icon">G</span>
                    <p className="mb-0 text-31-500 color-ffff">2505</p>
                  </div>
                </div>
              </div>
              <div className="user-data-2">
                <div className="user-rewards">
                  <img src={userImg2} alt="img" />
                  <div className="reward-block">
                    <span className="G-icon">G</span>
                    <p className="mb-0 text-31-500 color-ffff">5420</p>
                  </div>
                </div>
              </div>
              <div className="user-data-3">
                <div className="user-rewards">
                  <img src={userImg3} alt="img" />
                  <div className="reward-block">
                    <span className="G-icon">G</span>
                    <p className="mb-0 text-31-500 color-ffff">1586</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* right side */}
          <div className="col-xxl-6 col-5 my-4">
            <div className="user-info-form">
              <span className="yellow-dot">
                <div className="w-22 h-22 rounded-circle bg-BC00"></div>
              </span>
              <span className="green-dot2">
                <div className="w-17 h-17 rounded-circle bg-AE0C"></div>
              </span>
              <div className="form-details">
                <img src={icons.logoImg} alt="logo" className="logo-img" />
                <div className="mt-5 color-ffff">
                  <h3 className="text-26-750 ">
                    Let’s <br />
                    Get Started
                  </h3>
                  <p className="text-20-400 opacity-75 mb-0">
                    few simple steps
                  </p>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmitForm}
                  enableReinitialize
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                      setFieldError,
                    } = props;
                    const { name, email, city, state, country } = values;

                    const handleInputChange = (e) => {
                      const { value, id } = e.target;

                      if (!touched[id]) {
                        touched[id] = true;
                      }
                      if (value === "") {
                        setFieldError(id, "");
                      }
                      handleChange(e);
                    };
                    let cityOption =
                      stateList?.find((o) => o.label === state)?.city || [];
                    return (
                      <form>
                        <div className="my-5">
                          <TextInput
                            placeholder="Your name"
                            id="name"
                            className={`mb-3 ${
                              touched.name && errors.name ? "b-3030" : ""
                            }`}
                            value={name}
                            onChange={handleInputChange}
                          />
                          <TextInput
                            placeholder="Your e-mail"
                            id="email"
                            className={`mb-3 ${
                              touched.email && errors.email ? "b-3030" : ""
                            }`}
                            value={email}
                            onChange={handleInputChange}
                          />
                          <Dropdown
                            id="country"
                            placeholder="Country"
                            optionValue="label"
                            optionKey="label"
                            onChange={(e) => {
                              dispatch(storeCountryId(e));
                              dispatch(getStateCityData(e.target.data.id));
                              handleInputChange(e);
                            }}
                            options={countryList}
                            value={country}
                            className={`mb-3`}
                            errorClass={`${
                              touched.country && errors.country
                                ? "b-3030 border-redius-3030"
                                : ""
                            }`}
                            selectStyle="SEARCH-25 mb-3"
                            isSearchable={true}
                            searchIcon={false}
                          />

                          <Dropdown
                            id="state"
                            placeholder="State"
                            optionValue="label"
                            optionKey="label"
                            onChange={handleInputChange}
                            options={stateList}
                            value={state}
                            className={`mb-3 ${
                              touched.state && errors.state ? "b-3030" : ""
                            }`}
                            errorClass={`${
                              touched.state && errors.state
                                ? "b-3030 border-redius-3030"
                                : ""
                            }`}
                            selectStyle="SEARCH-25 mb-3"
                            isSearchable={true}
                            searchIcon={false}
                            disabled={country === ""}
                            isClearable={true}
                          />
                          <Dropdown
                            id="city"
                            placeholder="city"
                            optionValue="name"
                            optionKey="name"
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                            options={cityOption}
                            value={city}
                            className={`mb-3 ${
                              touched.city && errors.city ? "b-3030" : ""
                            }`}
                            errorClass={`${
                              touched.city && errors.city
                                ? "b-3030 border-redius-3030"
                                : ""
                            }`}
                            selectStyle="SEARCH-25 mb-3"
                            isSearchable={true}
                            searchIcon={false}
                            disabled={state === ""}
                            isClearable={true}
                          />
                        </div>
                        <Button
                          btnText="Next"
                          className="NEXT w-100"
                          rightIcon={icons.nextIcon}
                          btnType="submit"
                          onClick={handleSubmit}
                        />
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUserDetails;
