import React, { useEffect, useState } from "react";
import "./ChooseUserName.scss";
import user14 from "assets/images/common/user14.png";
import user13 from "assets/images/common/user13.png";
import user12 from "assets/images/common/user12.png";
import user15 from "assets/images/common/dummyProfile.png";
import budge1 from "assets/images/common/diamond.png";
import { commonRoute, icons } from "utils/constants";
import TextInput from "components/inputs/TextInput";
import Button from "components/inputs/Button";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { addBusinessName, uploadUserMedia } from "store/slices";
import {
  getDataFromLocalStorage,
  objectToFormData,
  storeLocalStorageData,
} from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { capitalize } from "lodash";

const ChooseUserName = () => {
  const nameData = getDataFromLocalStorage("registerData");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState({ profileErr: false, userNameErr: false });
  const [iserror, setisError] = useState("");
  const [userProfile, setUserProfile] = useState({
    profileImg: "",
    userName: nameData?.name || "",
    isVerify: false,
  });

  const { loading } = useSelector((state) => ({
    loading: state.auth.loading,
  }));
  useEffect(() => {
    const media = getDataFromLocalStorage("media");
    const identifier = getDataFromLocalStorage("identifier");
    const nameData = getDataFromLocalStorage("registerData");

    const verified = media && identifier ? true : false;
    setUserProfile({
      profileImg: media || "",
      userName: nameData?.name || "",
      isVerify: verified,
    });
  }, []);

  const handleProfileUpload = async (event) => {
    if (!event.target.files.length)
      return setError({ ...error, profileErr: true });
    const file = event.target.files[0];
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);
    const formData = objectToFormData({ media: compressedFile });
    const res = await dispatch(uploadUserMedia(formData));
    if (res.status === 200) {
      const { profileMedia } = res?.data?.userIntroduction;
      storeLocalStorageData({
        media: profileMedia?.cdn_url + "/" + profileMedia?.thumbnail,
      });
      setUserProfile({
        ...userProfile,
        profileImg: profileMedia?.cdn_url + "/" + profileMedia?.thumbnail,
      });
    }
  };
  const addUserName = (e) => {
    if (userProfile.isVerify) {
      return setUserProfile((prev) => ({
        ...prev,
        isVerify: false,
        userName: e.target.value,
      }));
    }
    if (iserror !== "") setisError("");
    setUserProfile((prev) => ({
      ...prev,
      userName: e.target.value,
    }));
  };
  const handleVerifyName = async () => {
    if (userProfile.userName === "")
      return setError({ ...error, userNameErr: true });
    if (error.userNameErr) setError({ ...error, userNameErr: false });
    const payload = { identifier: userProfile.userName };
    const res = await dispatch(addBusinessName(payload));
    if (res.status === 200) setUserProfile({ ...userProfile, isVerify: true });
    else {
      if (res?.data?.[0]?.msg) {
        setisError(
          capitalize(
            res?.data?.[0]?.msg
              ? " Choose a other user name , User Already Exits"
              : res?.data?.[0]?.msg
          )
        );
        setUserProfile({ ...userProfile, isVerify: false });
      }
    }
  };
  return (
    <div id="userName-container">
      <span className="pink-dot">
        <div className="w-22 h-22 rounded-circle bg-1B3F"></div>
      </span>
      <span className="yellow-dot">
        <div className="w-22 h-22 rounded-circle bg-BC00"></div>
      </span>
      <span className="green-dot">
        <div className="w-14 h-14 rounded-circle bg-AE0C"></div>
      </span>
      <div className="row">
        {/* left side  */}
        <div className="col-xxl-6 col-7">
          <div className="backRound-shadow">
            <div className="big-round-shadow"></div>
            <div className="medium-round-shadow"></div>
            <div className="small-round-shadow"></div>
            {/* userData */}
            <div className="user-data-1">
              <div className="user-Counts">
                <img src={user12} alt="img" />
                <div className="budge-count">
                  <div className="bg-0000 rounded-circle h-59 w-59 fc-center">
                    <img src={icons.rightIcon} alt="" />
                  </div>
                  <span className="text-31-500 color-ffff">302</span>
                </div>
              </div>
            </div>
            <div className="user-data-2">
              <div className="user-Counts">
                <img src={user13} alt="img" />
                <div className="budge-count">
                  <img src={icons.budgeIcon} alt="" />
                  <span className="text-31-500 color-ffff">250</span>
                </div>
              </div>
            </div>
            <div className="user-data-3">
              <div className="user-Counts">
                <img src={user14} alt="img" />
                <div className="budge-count">
                  <img src={budge1} alt="" />
                  <span className="text-31-500 color-ffff">180</span>
                </div>
              </div>
            </div>
          </div>
          <div className="referring">
            <h2 className="text-35-500 color-ffff">Connect with more people</h2>
            <p className="text-35-300 color-C700">
              and earn more trust in the market
            </p>
          </div>
        </div>
        {/* Right side  */}
        <div className="col-xxl-6 col-5">
          <div className="userName-form">
            <div className={`userImg ${error.profileErr ? "b-3030" : ""}`}>
              <input
                type="file"
                accept="image/*"
                onChange={handleProfileUpload}
                disabled={loading}
              />
              {loading ? (
                <div className="loading fc-center">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <img
                  src={icons.cameraIcon}
                  alt="camera"
                  className="camera-icon"
                />
              )}
              <img
                src={userProfile?.profileImg || user15}
                alt=""
                className="rounded-circle"
                width={190}
                height={190}
              />
            </div>
            <h3 className="text-26-750 ">Choose username</h3>
            <p className=" opacity-50 text-15-400 my-2">
              Choose a username which will be your personalized link
            </p>
            <div className="mt-3 mb-2 d-flex tic-rel">
              <TextInput
                placeholder="Username"
                className={`${
                  error.userNameErr ? "b-3030" : ""
                }opacity-100 w-75 mx-auto text-center`}
                value={userProfile?.userName}
                onChange={addUserName}
                error={iserror}
                center="justify-content-center"
              />
              {userProfile.isVerify &&
              userProfile.userName === getDataFromLocalStorage("identifier") ? (
                <img
                  src={icons.GreenTick}
                  alt="bluetick"
                  className="tic-abs  cpe-5 cmt-12"
                  width={25}
                  height={20}
                />
              ) : null}
            </div>
            <button
              className="color-9CF3 text-14-400 bb-9CF3"
              onClick={handleVerifyName}
              disabled={
                userProfile.isVerify &&
                userProfile.userName === getDataFromLocalStorage("identifier")
              }
            >
              Verify
            </button>
            <p className="mt-5 opacity-50 text-12-400">
              This is how your link will look like
            </p>
            {userProfile.userName ? (
              <div className="d-flex">
                {userProfile.isVerify &&
                userProfile.userName ===
                  getDataFromLocalStorage("identifier") ? (
                  <img
                    src={icons.GreenTick}
                    alt="bluetick"
                    className="cpe-5 cmt-12"
                    width={25}
                    height={20}
                  />
                ) : null}
                <p className="mt-2 text-19-400">
                  www.mygrid.club/{userProfile.userName}
                </p>
              </div>
            ) : (
              <p className="mt-2 text-19-400">www.mygrid.club</p>
            )}
            <div className="fb-center mt-5 pt-3 w-100">
              <Button
                btnText="back"
                btnStyle="color-ffff opacity-50"
                onClick={() => navigate(commonRoute.setPin)}
              />
              <Button
                btnText="Next"
                btnStyle="NEXT w-120"
                rightIcon={icons.nextIcon}
                disabled={
                  !userProfile.isVerify ||
                  !userProfile?.profileImg ||
                  !getDataFromLocalStorage("identifier")
                }
                onClick={() => navigate(commonRoute.addDescription)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUserName;
