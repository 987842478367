import React from "react";
import "./ConnectUnreadUserCard.scss";
import UserFaceImg from "assets/images/common/dummyProfile.png";
import moment from "moment/moment";
import { capitalize } from "lodash";
const ConnectUnreadUserCard = ({ isSelected, className, data }) => {
  return (
    <div id="unread-msg-container" className={className} key={data?.id}>
      <div className={`${isSelected ? "b-9EF8" : ""} Card-8`}>
        {data?.prime ? <span className="prime-btn">Prime</span> : ""}
        <div className="user-unread">
          <img
            src={
              data?.user_introduction?.profile_media?.id
                ? `${data?.user_introduction?.profile_media?.cdn_url}/${data?.user_introduction?.profile_media?.thumbnail}`
                : UserFaceImg
            }
            alt="user"
          />
          <div className="flex-grow-1">
            <p className="mb-1 text-12-400">{capitalize(data?.name)}</p>
            <p className="text-9-400 color-grey-350">
              {data?.user_introduction
                ? data?.user_introduction?.headline
                : "-"}{" "}
              | {data?.city}, {data?.country}
            </p>
            <p className="mb-0 text-9-400 color-grey-350">
              {data?.lastMessage?.text ? `${data?.lastMessage?.text}` : ""}
            </p>
          </div>
          {data?.unreadCounts > 0 && !isSelected ? (
            <div className="w-20 px-2 h-20 bg-9EF8 fc-center rounded-circle text-11-400">
              {data?.unreadCounts}
            </div>
          ) : (
            ""
          )}
        </div>
        <p className="mb-0 text-end text-7-400 color-grey-350">
          {data?.lastMessage
            ? moment(data?.lastMessage?.createdAt).format("DD/MM/YYYY")
            : ""}
        </p>
      </div>
    </div>
  );
};

export default ConnectUnreadUserCard;
