import { createSlice } from "@reduxjs/toolkit";
import { api } from "services/api";
import { handelCatch, handelResponse } from "./globalSlice";
import toast from "react-hot-toast";
import { cloneDeep } from "lodash";
import { getDataFromLocalStorage, storeLocalStorageData } from "utils/helpers";
import axios from "axios";

const initialState = {
  userData: {},
  servicesList: [],
  ratingList: [],
  countryList: [],
  primeMebmberShipCoinCount: [],
  stateList: [],
  bannaerList: [],
  countryId: {}

};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfileState: (state) => initialState,
    storeUserData(state, action) {
      state.userData = action.payload;
    },
    storeServicesList(state, action) {
      state.servicesList = action.payload;
    },
    storeRatingList(state, action) {
      state.ratingList = action.payload;
    },
    storeCountryList(state, action) {
      state.countryList = action.payload;
    },
    storeStateList(state, action) {
      state.stateList = action.payload;
    },
    storePrimeMebmberShipCoinCount(state, action) {
      state.primeMebmberShipCoinCount = action.payload;
    },
    storeBannerList(state, action) {
      state.bannaerList = action.payload;
    },
    storeCountryId(state, action) {
      state.countryId = action.payload;
    },

  },
});

export const {
  resetProfileState,
  storeUserData,
  storeServicesList,
  storeRatingList,
  storeCountryList,
  storePrimeMebmberShipCoinCount,
  storeStateList,
  storeBannerList,
  storeCountryId,
} = profileSlice.actions;
export const getUserData = () => async (dispatch, getState) => {
  try {
    const res = await api.get(`/me`);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeUserData(responseData?.data));
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const uploadProfileMedia = (formData) => async (dispatch, getState) => {
  try {
    const res = await api.post(`/profile-media`, formData, {
      "Content-type": "multipart/form-data",
    });
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(
        storeUserData({
          ...getState().profile.userData,
          userIntroduction: responseData?.data?.userIntroduction,
        })
      );
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const uploadCoverMedia = (formData) => async (dispatch, getState) => {
  try {
    const res = await api.post(`/cover-media`, formData, {
      "Content-type": "multipart/form-data",
    });
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(
        storeUserData({
          ...getState().profile.userData,
          userIntroduction: responseData?.data?.userIntroduction,
        })
      );
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const uploadBannerMedia = (formData) => async (dispatch, getState) => {
  try {
    const res = await api.post(`/banner-media`, formData, {
      "Content-type": "multipart/form-data",
    });
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(
        storeUserData({
          ...getState().profile.userData,
          bannerMedia: responseData?.data?.media,
        })
      );
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const deleteMedia = (endPoint) => async (dispatch, getState) => {
  try {
    const res = await api.delete(`/${endPoint}`);
    const responseData = await dispatch(
      handelResponse({ ...res, status: 200 })
    );
    if (responseData?.status === 200) {
      await dispatch(getUserData());
      toast.dismiss();
      toast.success(responseData?.message);
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const updateUserDetails = (payload) => async (dispatch, getState) => {
  try {
    const res = await api.put(`/me`, payload);
    const responseData = await dispatch(handelResponse({ ...res, status: 200 }));
    if (responseData?.status === 200) {
      dispatch(storeUserData({ ...getState().profile.userData, ...payload }));
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getServicesList = () => async (dispatch, getState) => {
  try {
    const res = await api.get(`/services`);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeServicesList(responseData?.data));
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const addNewServiceDetails =
  (formData) => async (dispatch, getState) => {
    try {
      const res = await api.post(`/services`, formData, {
        "Content-type": "multipart/form-data",
      });
      const responseData = await dispatch(handelResponse(res));
      if (responseData?.status === 200) {
        dispatch(
          storeServicesList([
            ...getState().profile.servicesList,
            responseData.data,
          ])
        );
      }
      return responseData;
    } catch (error) {
      return dispatch(handelCatch(error));
    }
  };
export const updateServiceDetails =
  (payload, id) => async (dispatch, getState) => {
    try {
      const res = await api.put(`/services/${id}`, payload);
      const responseData = await dispatch(
        handelResponse({ ...res, status: 200 })
      );
      if (responseData?.status === 200) {
        const updateList = cloneDeep(getState().profile.servicesList).map(
          (ele) => (ele.id === id ? { ...responseData } : ele)
        );
        dispatch(storeServicesList(updateList));
        toast.dismiss();
        toast.success("Service details updated successfully.");
      }
      return responseData;
    } catch (error) {
      return dispatch(handelCatch(error));
    }
  };
export const uploadServiceMedia =
  (formData, id) => async (dispatch, getState) => {
    try {
      const res = await api.post(`/services/${id}/media`, formData, {
        "Content-type": "multipart/form-data",
      });
      const responseData = await dispatch(handelResponse(res));
      if (responseData?.status === 200) {
        const updateList = cloneDeep(getState().profile.servicesList).map(
          (ele) => (ele.id === id ? { ...responseData?.data } : ele)
        );
        dispatch(storeServicesList(updateList));
        toast.dismiss();
        toast.success("Service Image updated successfully.");
      }
      return responseData;
    } catch (error) {
      return dispatch(handelCatch(error));
    }
  };
export const handleDeleteService = (id) => async (dispatch, getState) => {
  try {
    const res = await api.delete(`/services/${id}`);
    const responseData = await dispatch(
      handelResponse({ ...res, status: 200 })
    );
    if (responseData?.status === 200) {
      dispatch(getServicesList());
      toast.dismiss();
      toast.success(responseData?.message);
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getRatingList = () => async (dispatch, getState) => {
  try {
    const businessCardId = getDataFromLocalStorage("businessCardId");
    const res = await api.get(`/business-cards/${businessCardId}/card-ratings?page=0`);
    const responseData = await dispatch(handelResponse(res));

    if (responseData?.status === 200) {
      dispatch(storeRatingList(responseData?.data?.data));
      storeLocalStorageData({ UserRatingNumber: responseData?.data?.total });

    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const deleteRatingData = (id) => async (dispatch, getState) => {
  try {
    const res = await api.delete(`/card-ratings/${id}`);
    const responseData = await dispatch(
      handelResponse({ ...res, status: 200 })
    );
    if (responseData?.status === 200) {
      toast.dismiss();
      toast.success(responseData?.message);
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
// export const deleteLinkData = (id) => async (dispatch, getState) => {
//   try {
//     const res = await api.delete(`/links/${id}`);
//     const responseData = await dispatch(
//       handelResponse({ ...res, status: 200 })
//     );
//     if (responseData?.status === 200) {
//       toast.dismiss();
//       toast.success(responseData?.message);
//     }
//     return responseData;
//   } catch (error) {
//     return dispatch(handelCatch(error));
//   }
// };
export const sendEnquiry = (payload) => async (dispatch, getState) => {
  try {
    const res = await api.post(`/enquiries`, payload);
    const responseData = await dispatch(handelResponse(res));

    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export default profileSlice.reducer;

export const getCountryData = () => async (dispatch, getState) => {
  try {
    const res = await api.get(`/countries`);

    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeCountryList(responseData?.data));
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
// export const getStateCityData = () => async (dispatch, getState) => {
//   try {
//     const res = await axios.get(`https://restcountries.com/v3.1/all`);
//     const responseData = await dispatch(handelResponse(res));
//     if (responseData?.status === 200) {
//       dispatch(storeCountryList(responseData?.data));
//     }
//     return responseData;
//   } catch (error) {
//     return dispatch(handelCatch(error));
//   }
// };
export const getStateCityData = (id) => async (dispatch, getState) => {
  try {
    const res = await api.get(`/countries/${id}/states`);

    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeStateList(responseData?.data));
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getPromotionBanner = () => async (dispatch, getState) => {
  try {
    const res = await api.get(`/promotion-banners`);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeBannerList(responseData?.data))
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};