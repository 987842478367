import { useEffect, useState } from "react";
import "./DashboardLineChart.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getGraphData } from "store/slices";
import moment from "moment";
import { icons } from "utils/constants";
import { totalChartCount } from "utils/helpers";
import Select from "react-select";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (context) => {
          // Display the x-axis value as the title
          return "";
        },
        label: (context) => {
          // Display only the x-axis value on hover
          return context?.raw;
        },
      },
    },
  },

  scales: {
    x: {
      grid: {
        drawTicks: false,
      },
    },
    y: {
      suggestedMin: 0,
      grid: {
        color: "#303030",
        drawTicks: false,
      },
      ticks: {
        display: false,
        min: 0,
      },
    },
  },
};

const DashboardLineChart = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    labels: [],
    // labels: ["1", "2", "", "" , "2" ,"2"],
    datasets: [
      {
        label: "",
        data: [],
        // data: [2,3,5,6,4],
        borderColor: "#3f7ea1",
        tension: 0.5,
        pointBorderColor: "rgba(0,0,0,0)",
      },
    ],
  });
  const [selectTime, SetselectTime] = useState("30");

  const timeset = [
    {
      time: "30",
    },
    {
      time: "60",
    },
    {
      time: "90",
    },
    {
      time: "180",
    },
  ];
  const analyticsRDX = useSelector((state) => state.analytics);
  const { analyticsGraphData } = analyticsRDX;

  const fetchData = async () => {
    const currentDate = moment().format("YYYY-MM-DD");
    const last30Days = moment().subtract("30", "days").format("YYYY-MM-DD");
    const query = new URLSearchParams({
      fromDate: last30Days,
      toDate: currentDate,
    });
    await dispatch(getGraphData(query));
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (analyticsGraphData?.length) {
      const tempCount = [];
      const tempLabels = [];
      const list = analyticsGraphData;
      list?.forEach((ele, i) => {
        if (i % 8 === 0) tempLabels.push(moment(ele?.date).format("Do MMM"));
        else tempLabels.push("");
        tempCount.push(ele?.count);
      });

      setData({
        labels: tempLabels,
        datasets: [
          {
            label: "Dataset 1",
            data: tempCount,
            borderColor: "#3f7ea1",
            tension: 0.5,
            pointRadius: 0,
          },
        ],
      });
    }
  }, [analyticsGraphData]);
  // const handleChangetime = async (e) => {
  //   SetselectTime(e.target.value)
  //   const currentDate = moment().format('YYYY-MM-DD');
  //   const lastSelectDays = moment().subtract(parseInt(e.target.value), 'days').format('YYYY-MM-DD');
  //   const query = new URLSearchParams({
  //     fromDate: lastSelectDays,
  //     toDate: currentDate,
  //   });
  //   await dispatch(getGraphData(query));
  // }

  const handleChangetime = async (selectedOption) => {
    if (!selectedOption) {
      SetselectTime(null);
      return;
    }

    SetselectTime(selectedOption.value);
    const currentDate = moment().format("YYYY-MM-DD");
    const lastSelectDays = moment()
      .subtract(parseInt(selectedOption.value), "days")
      .format("YYYY-MM-DD");
    const query = new URLSearchParams({
      fromDate: lastSelectDays,
      toDate: currentDate,
    });

    await dispatch(getGraphData(query));
  };
  const SelectComponent = () => {
    const options = timeset?.map((ele, i) => ({
      key: i,
      value: ele.time,
      label: `Last ${ele.time} days`,
    }));
    const selectedOption = options.find(
      (option) => option.value === selectTime
    );
    return (
      <Select
        value={selectedOption}
        onChange={handleChangetime}
        options={options}
        styles={{
          control: (provided) => ({
            ...provided,
            wordWrap: "normal",
            backgroundColor: "black",
            border: "none",
            fontSize: "13px",
            outline: "none",
            padding: "0px",
            marginTop: "-10px",
            boxShadow: "none",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            width: 0,
            backgroundColor: "transparent",
            margin: 0,
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            marginLeft: "-12px",
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingLeft: "2px",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "#737373",
          }),
          menu: (provided) => ({
            ...provided,
            color: "#737373",
            background: "black",
          }),
          menuList: (provided) => ({
            ...provided,
            fontSize: "13px",
            boxShadow: "2px -1px 300px -26px rgba(255,255,255,1)",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#2684FF" : "black",
            ":hover": {
              backgroundColor: state.isSelected ? "#2684FF" : "#1b1b1b",
            },
          }),
        }}
      />
    );
  };
  return (
    <>
      <div className="fb-center mb-3">
        <div>
          <p className="text-8-400 color-grey-73">
            <span className="me-2 d-flex">
              <img src={icons.clockIcon} alt="clock" height={17} width={17} />{" "}
              <SelectComponent />
            </span>
          </p>
          <div className="fa-center gap-3">
            <p className="text-16-400 mb-0">Profile Views</p>
          </div>
        </div>
        <div className="text-end">
          <h4 className="m-0 text-33-700">
            {totalChartCount(analyticsGraphData)}
          </h4>
          <p className="mb-0 text-9-400">22 on web | 34 on Grid</p>
        </div>
      </div>

      <div id="dashboard-line-chart-container">
        <Line options={options} data={data} />
      </div>
    </>
  );
};

export default DashboardLineChart;
