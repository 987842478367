
const commonRoute = {
  home: "/",
  verifyOTP: "/verify-otp",
  addDetails: "/add-details",
  setPin: "/set-pin",
  chooseUserName: "/choose-username",
  addDescription: "/add-description",
  addLinks: "/add-links",
  congratulation: "/congratulation",
  dashboard: "/dashboard",
  myConnects: "/my-connects",
  analytics: "/analytics",
  profile: "/profile",
  greetings: "/greetings",
  discoverAll: "/discover-all",
};

export { commonRoute };