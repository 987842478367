import React, { useEffect, useState } from "react";
import "./EditUserDetails.scss";
import TextInput from "components/inputs/TextInput";
import { icons } from "utils/constants";
import TextArea from "components/inputs/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserIntroductions,
  getCountryData,
  storeUserData,
  updateUserDetails,
} from "store/slices";
import _ from "lodash";
import Dropdown from "components/inputs/Dropdown";
function EditUserDetails() {
  const dispatch = useDispatch();
  // const profileRDX = useSelector((state) => state.profile);
  const profileRDX = useSelector((state) => ({
    countryList: state.profile.countryList?.map((ele, index) => ({
      id: ele.id,
      label: ele.name,
    })),
    stateList: state.profile.stateList?.map((ele, index) => ({
      id: ele.id,
      label: ele.name,
      city: ele.cities,
    })),
    userData: state.profile.userData,
  }));
  const { userData, countryList, stateList } = profileRDX || {};
  const namePattern = /^[a-zA-Z\s]+$/;
  const [fieldList, setFieldList] = useState([
    {
      field: "name",
      isDisable: true,
      value: userData?.name,
      inputType: "text",
      placeholder: "Your name",
    },
    {
      field: "country",
      isDisable: true,
      value: userData?.country,
      inputType: "select",
      placeholder: "Country",
    },
    {
      field: "state",
      isDisable: true,
      value: userData?.state,
      inputType: "state",
      placeholder: "State",
    },
    {
      field: "city",
      isDisable: true,
      value: userData?.city,
      inputType: "city",
      placeholder: "Your City",
    },
    {
      field: "headline",
      isDisable: true,
      value: userData?.userIntroduction?.headline,
      inputType: "text",
      placeholder: "Your headline",
    },
    {
      field: "description",
      isDisable: true,
      value: userData?.userIntroduction?.description,
      inputType: "textArea",
      placeholder: "Your name",
    },
  ]);
  const [error, setError] = useState({
    name: false,
    country: false,
    state: false,
    city: false,
    headline: false,
    description: false,
  });
  useEffect(() => {
    if (userData) {
      const updateFields = fieldList?.map((ele) => {
        const key = ele?.field;
        if (userData.hasOwnProperty(key)) {
          ele.value = userData[key];
        } else if (
          userData.userIntroduction &&
          userData.userIntroduction.hasOwnProperty(key)
        ) {
          ele.value = userData.userIntroduction[key];
        }
        return ele;
      });
      setFieldList(updateFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  const fetchData = async () => {
    await dispatch(getCountryData());
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (e) => {
    if (!e.target.value) {
      setError({ ...error, [e.target.name]: true });
    } else if (
      e.target.name !== "description" &&
      !namePattern.test(e.target.value)
    ) {
      setError({ ...error, [e.target.name]: true });
    } else if (error[e.target.name]) {
      setError({ ...error, [e.target.name]: false });
    }
    setFieldList((prev) => {
      const newData = prev.map((val, i) => {
        // eslint-disable-next-line eqeqeq
        if (i == e.target.id) {
          return { ...val, value: e.target.value };
        }

        return val;
      });
      return newData;
    });
  };

  const handleDisableAndSaveData = async (i, name) => {
    if (!fieldList[i]?.isDisable && !error[name]) {
      if (!(name === "description" || name === "headline")) {
        const payload = { [name]: fieldList[i]?.value };
        await dispatch(updateUserDetails(payload));
      } else {
        const payload = {
          description:
            name === "description"
              ? fieldList[i]?.value
              : userData.userIntroduction?.description,

          headline:
            name === "headline"
              ? fieldList[i]?.value
              : userData.userIntroduction?.headline,
        };
        const res = await dispatch(addUserIntroductions(payload));
        if (res.status === 200) {
          await dispatch(
            storeUserData({
              ...userData,
              userIntroduction: {
                ...userData?.userIntroduction,
                [name]: fieldList[i]?.value,
              },
            })
          );
        }
      }
    }

    setFieldList((prevFieldList) => {
      const updatedFieldList = prevFieldList.map((item, index) => {
        if (index === i) {
          return { ...item, isDisable: !item.isDisable };
        }
        return item;
      });

      return updatedFieldList;
    });
  };
  let cityOption =
    stateList?.find((o) => o.label === fieldList?.[2]?.value)?.city || [];
  return (
    <div id="edit-details">
      <div className="Card-8 my-3">
        <p className="text-16-700 mb-3">Details</p>
        {fieldList?.map((ele, i) => {
          return (
            <div className="row my-2" key={i}>
              <div className="col-2 text-16-400 pt-2">
                {_.capitalize(ele?.field)}
              </div>
              <div className="col-9 fa-center">
                {ele?.inputType === "text" ? (
                  <TextInput
                    name={ele?.field}
                    id={i}
                    onChange={handleChange}
                    value={ele?.value}
                    placeholder={ele?.placeholder}
                    disabled={ele?.isDisable}
                    className={`${error[ele?.field] ? "b-3030" : ""}`}
                  />
                ) : ele?.inputType === "select" ? (
                  <Dropdown
                    name={ele?.field}
                    id={i}
                    optionValue="label"
                    optionKey="label"
                    disabled={ele?.isDisable}
                    onChange={handleChange}
                    options={countryList}
                    value={ele?.value}
                    // selectStyle={"edit-user-dropdown"}
                    // className="bg-1B1B text-13-400 br-8 cursor-none"
                    // className={`${error[ele?.field] ? "b-3030" : ""}`}
                    selectStyle="SEARCH-DARK"
                    isSearchable={true}
                    searchIcon={false}
                    isClearable={true}
                  />
                ) : ele?.inputType === "state" ? (
                  <Dropdown
                    name={ele?.field}
                    id={i}
                    optionValue="label"
                    optionKey="label"
                    disabled={ele?.isDisable}
                    onChange={handleChange}
                    options={stateList}
                    value={ele?.value}
                    selectStyle="SEARCH-DARK"
                    isSearchable={true}
                    searchIcon={false}
                  />
                ) : ele?.inputType === "city" ? (
                  <Dropdown
                    name={ele?.field}
                    id={i}
                    optionValue="name"
                    optionKey="name"
                    disabled={ele?.isDisable}
                    onChange={handleChange}
                    options={cityOption}
                    value={ele?.value}
                    selectStyle="SEARCH-DARK"
                    isSearchable={true}
                    searchIcon={false}
                  />
                ) : (
                  <TextArea
                    name={ele?.field}
                    id={i}
                    value={ele?.value}
                    placeholder={ele?.placeholder}
                    disabled={ele?.isDisable}
                    rows={10}
                    onChange={handleChange}
                  />
                )}
              </div>
              <div className="col-1 pt-2">
                <button
                  onClick={() => {
                    handleDisableAndSaveData(i, ele?.field);
                  }}
                  disabled={error[ele?.field]}
                >
                  {ele?.isDisable ? (
                    <img src={icons.pencilIcon} alt="edit" />
                  ) : (
                    <img src={icons.blueCheckIcon} alt="edit" />
                  )}
                </button>
              </div>
              {/* </div>
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default EditUserDetails;
