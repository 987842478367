import { decrypt, encrypt } from "./encryptdecrypt";

// convert string into title case
export const titleCaseString = (value) => {
  if (typeof value !== "string") return "";
  return value.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()); // Capital first character of each word
};

// Trim all left space
export const trimLeftSpace = (value) => value.replace(/^\s+/g, ""); // Remove white space from left side

// Create array by given year
export const getYearByCount = (startYear, endYear) => {
  let returnValue = [];
  while (startYear <= endYear) {
    returnValue.push(`${startYear++}`);
  }
  return returnValue;
};
export function getHeaderData() {
  let header = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-App-Key": process.env.REACT_APP_X_APP_KEY
  };
  if (getDataFromLocalStorage("token")) {
    header = {
      ...header,
      ...{ "X-Access-Token": `${getDataFromLocalStorage("token")}` },
    };
  }
  return header;
}
export const storeLocalStorageData = (newData) => {
  const decryptData = decrypt(localStorage.userData || {});
  localStorage.userData = encrypt({ ...decryptData, ...newData });
};
export function getDataFromLocalStorage(key = "") {
  let returnValue = "";
  if (localStorage.userData) {
    const localObjectData = decrypt(localStorage.userData);
    if (key) {
      returnValue = localObjectData[key] ? localObjectData[key] : "";
    } else {
      returnValue = localObjectData;
    }
  }
  return returnValue;
}
export const objectToFormData = (obj) => {
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

export const totalChartCount = (graphData) => {
  const list = graphData;
  const sum = list?.reduce((accumulator, value) => {
    return accumulator + value.count;
  }, 0)
  return sum;
}
export const downloadURI = (uri, name) => {
  var link = document.createElement("a");
  link.href = uri;
  link.target = "_blank"
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const convertDescription = (utfString) => {
  let returnValue = "";
  if (utfString) {
    let geturl = new RegExp(
      /(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))/,
      "g"
    );
    let text = utfString.replace(/\n\r?/g, "<br />");
    let matchData = text.match(geturl);
    matchData?.forEach((e) => {
      text = text.replace(
        e,
        ` <a class="" href="${trimLeftSpace(
          e
        )}" target="_blank">${trimLeftSpace(e)}</a>`
      );
    });
    returnValue = text;
  }
  return returnValue;
};
export const convertToCSV = (objArray) => {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line !== '') line += ','

      line += array[i][index];
    }

    str += line + '\r\n';
  }
  return str;
}
export const exportCSVFile = (headers, items, fileTitle) => {

  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);
  var csv = convertToCSV(jsonObject);
  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
