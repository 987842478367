import Button from "components/inputs/Button";
import React from "react";
import Modal from "../Modal";

const DeleteModal = ({ question, onClose, onDelete }) => {
  return (
    <Modal closeBtn title="" onHide={onClose} size="md">
      <div className="py-4">
        <p className="color-ffff text-17-400">{question}</p>
      </div>
      <div className="fe-center p-3 pt-4 gap-2">
        <Button
          btnText="Cancel"
          btnStyle="BLACK-BLUE"
          className="rounded-pill text-17-400 w-120"
          onClick={onClose}
        />
        <Button
          btnText="Delete"
          btnStyle="BLACK-BLUE"
          className="rounded-pill text-17-400 w-120"
          onClick={onDelete}
        />
      </div>
    </Modal>
  );
};

export default DeleteModal;
