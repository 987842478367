import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import "font-awesome/css/font-awesome.min.css";
import callPng from "assets/images/icons/call.png";
import emailPng from "assets/images/icons/email.png";
import websitePng from "assets/images/icons/website.png";
import instagramPng from "assets/images/icons/instagram.png";
import facebookPng from "assets/images/icons/facebook.png";
import Button from "components/inputs/Button";
import { icons } from "utils/constants";
import Dropdown from "components/inputs/Dropdown";
import { SketchPicker } from "react-color";
import QRCode from "qrcode.react";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
const ImageEditor = ({ imgPath }) => {
  const [activeObject, setActiveObject] = useState(null);
  const [history, setHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);
  const [initialState, setInitialState] = useState({
    fontSize: 20,
    fontFamily: "Arial",
    fontColor: "#000000 ",
    stripColor: "#000000",
    leftAlignment: false,
    capitalize: false,
    bullets: false,
    lineHeight: false,
    bold: true,
    italic: false,
    underline: false,
    isTextAdded: false,
    isQRCode: false,
    isProfileImg: false,
    iscoverMedia: false,
    isWhatsApp: false,
    isContactNum: false,
    isEmail: false,
    isWebsite: false,
    isInstagram: false,
    isFaceBook: false,
    isFooter: false,
  });

  const { userData } = useSelector((state) => ({
    userData: state.profile.userData,
  }));
  const { isGoogleFont } = useSelector((state) => ({
    isGoogleFont: state.greeting.isGoogleFont,
  }));
  const logoImg =
    userData?.userIntroduction?.coverMedia?.cdn_url +
    "/" +
    userData?.userIntroduction?.coverMedia?.thumbnail;

  const profileURL =
    userData?.userIntroduction?.profileMedia?.cdn_url +
    "/" +
    userData?.userIntroduction?.profileMedia?.thumbnail;
  const whatsAppNum =
    userData?.businessCard?.contactDetail?.socialLinks?.whatsapp?.data?.[0]?.replace(
      "wa.me/",
      ""
    );
  const contactNum =
    userData?.businessCard?.contactDetail?.socialLinks?.phone?.data?.[0];
  const userEmail =
    userData?.businessCard?.contactDetail?.socialLinks?.mail?.data?.[0];
  const userWebsite =
    userData?.businessCard?.contactDetail?.socialLinks?.website?.data?.[0];

  const userFacebook =
    userData?.businessCard?.contactDetail?.socialLinks?.facebook?.data?.[0];
  const userInstagram =
    userData?.businessCard?.contactDetail?.socialLinks?.instagram?.data?.[0];
  const fabricRef = useRef(null);
  const canvasRef = useRef(null);

  const initFabric = (base64) => {
    fabricRef.current = new fabric.Canvas(canvasRef.current);
    const path = imgPath?.media?.cdn_url + "/" + imgPath?.media?.path;
    fabric.Image.fromURL(
      path,
      (img) => {
        img.scaleToWidth(522);
        img.set({ selectable: false, evented: false });
        fabricRef.current.setBackgroundImage(
          img,
          fabricRef.current.renderAll.bind(fabricRef.current)
        );
      },
      { crossOrigin: "anonymous" }
    );
    fabricRef.current.on("selection:created", handleSelection);
    fabricRef.current.on("selection:updated", handleSelection);
    fabricRef.current.on("selection:cleared", handleClearSelection);

    const footer = new fabric.Rect({
      width: fabricRef.current.width,
      height: 30,
      fill: initialState.stripColor, // Adjust the color as needed
      top: fabricRef.current.height - 30,
      selectable: true,
    });

    fabricRef.current.add(footer);
    footer.set("id", "isFooter");
    fabricRef.current.renderAll();
    handleSelectFontStyle("isFooter", true);
    saveHistory();
    if (userData) handleSetBasicInfo();
  };

  const disposeFabric = () => {
    fabricRef.current.dispose();

    fabricRef.current.off("selection:created", handleSelection);
    fabricRef.current.off("selection:updated", handleSelection);
    fabricRef.current.off("selection:cleared", handleClearSelection);
  };
  useEffect(() => {
    initFabric();

    return () => {
      disposeFabric();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgPath]);
  const handleSetBasicInfo = () => {
    if (userData?.businessCard?.identifier) generateQRCode();
    if (initialState?.isProfileImg) {
      addRemoveProfileImage();
    }
    if (initialState?.iscoverMedia) {
      addcoverMedia();
    }
    if (initialState?.isContactNum) {
      addContactNumber();
    }
    if (initialState?.isWhatsApp) {
      addWhatsAppDetails();
    }
    if (initialState?.isInstagram) {
      addInstagram();
    }
    if (initialState?.isEmail) {
      addEmail();
    }
    if (initialState?.isFaceBook) {
      addFaceBook();
    }
    if (initialState?.isWebsite) {
      addWebsite();
    }
  };

  const handleClearSelection = () => {
    setActiveObject(null);
  };
  const saveHistory = () => {
    const json = JSON.stringify(fabricRef.current);
    setHistory([...history, json]);
  };
  const addText = () => {
    handleSelectFontStyle("isTextAdded");
    const text = new fabric.Textbox(
      `${initialState.bullets ? "• Your text here" : "Your text here"}`,
      {
        left: 200,
        top: 200,
        fontFamily: initialState?.fontFamily || "Arial", // Set the default font
        fontSize: initialState?.fontSize || 20, // Set the default font size
        fill: initialState.fontColor,
        underline: initialState?.underline,
        fontWeight: initialState?.bold ? "bold" : "",
        fontStyle: initialState?.italic ? "italic" : "",
        textAlign: "center",
      }
    );
    fabricRef.current.add(text);
    text.set("id", "isTextAdded");
    fabricRef.current.renderAll();
    saveHistory();
  };
  const changeFontFamily = (e) => {
    const activeObject = fabricRef.current.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("fontFamily", e.target.value);
      fabricRef.current.renderAll();
    }
    setInitialState((prev) => {
      return { ...prev, fontFamily: e.target.value };
    });
  };

  // Function to change font size
  const changeFontSize = (size) => {
    const activeObject = fabricRef.current.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("fontSize", size);
      fabricRef.current.renderAll();
    }
    setInitialState((prev) => {
      return { ...prev, fontSize: size };
    });
  };
  const changeTextCase = (caseType) => {
    handleSelectFontStyle("capitalize");
    const activeObject = fabricRef.current.getActiveObject();

    if (activeObject && activeObject.type === "textbox") {
      const text = activeObject.text;

      switch (caseType) {
        case "sentenceCase":
          activeObject.text =
            text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
          break;
        // case "uppercase":
        //   activeObject.text = text.toUpperCase();
        //   break;
        // case "lowercase":
        //   activeObject.text = text.toLowerCase();
        //   break;
        default:
          break;
      }

      fabricRef.current.renderAll();
      saveHistory();
    }
  };
  // Function to add bullets
  const addBulletPoints = () => {
    handleSelectFontStyle("bullets");
    const activeObject = fabricRef.current.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      const text = activeObject.text.replace("• ", "");
      activeObject.text = "• " + text.replace(/\n/g, "\n• ");
      fabricRef.current.renderAll();
      saveHistory();
    }
  };

  //add img from uploads
  const addImg = (event) => {
    if (!event.target.files.length) return;
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const imgData = e.target.result;
      fabric.Image.fromURL(
        imgData,
        (img) => {
          img.set({ left: 100, top: 100, crossOrigin: "anonymous" }); // Set initial position
          fabricRef.current.add(img);
        },
        { crossOrigin: "anonymous" }
      );
    };

    reader.readAsDataURL(file);
    saveHistory();
  };
  const backwardFunction = () => {
    if (history.length > 0) {
      const prevState = history.pop();
      setRedoHistory([...redoHistory, prevState]);
      fabricRef.current.loadFromJSON(prevState, () => {
        fabricRef.current.renderAll();
      });
    }
  };

  const forwardFunction = () => {
    if (redoHistory.length > 0) {
      const nextState = redoHistory.pop();
      setHistory([...history, nextState]);
      fabricRef.current.loadFromJSON(nextState, () => {
        fabricRef.current.renderAll();
      });
    }
  };
  const handleSelection = () => {
    const activeObject = fabricRef.current.getActiveObject();
    setActiveObject(activeObject);
    saveHistory();
  };
  const deleteActiveElement = () => {
    const activeObject = fabricRef.current.getActiveObject();
    setActiveObject(activeObject);
    removeItemFromCanvas(activeObject?.id);
  };

  const toggleStyle = (style) => {
    handleSelectFontStyle("bold");
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set(style, 700);
      fabricRef.current.renderAll();
      saveHistory();
    }
  };
  const handleColorChange = (color) => {
    setInitialState((prev) => {
      return { ...prev, fontColor: color.hex };
    });
    const activeObject = fabricRef.current.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("fill", color.hex);
      fabricRef.current.renderAll();
    }
  };
  const handleStripColorChange = (color) => {
    setInitialState((prev) => {
      return { ...prev, stripColor: color.hex };
    });
    const activeObject = fabricRef.current.getActiveObject();
    if (activeObject) {
      activeObject.set("fill", color.hex);
      fabricRef.current.renderAll();
      saveHistory();
    }
  };
  const downloadImage = () => {
    // Deselect all active objects
    const canvas = fabricRef.current;
    canvas.discardActiveObject();
    canvas.renderAll();

    const divContent = document.getElementsByClassName("canvas-container");
    html2canvas(divContent[0]).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const img = new Image();
      img.src = imgData;
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "edited_image.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  const toggleUnderline = () => {
    handleSelectFontStyle("underline");
    const activeObject = fabricRef.current.getActiveObject();

    if (activeObject && activeObject.type === "textbox") {
      const isUnderlined = activeObject.get("underline");
      activeObject.set("underline", !isUnderlined);
      fabricRef.current.renderAll();
      saveHistory();
    }
  };
  const toggleItalic = () => {
    const activeObject = fabricRef.current.getActiveObject();
    handleSelectFontStyle("italic");
    if (activeObject && activeObject.type === "textbox") {
      const isItalic = activeObject.get("fontStyle") === "italic";
      activeObject.set("fontStyle", isItalic ? "" : "italic");
      fabricRef.current.renderAll();
      saveHistory();
    }
  };
  const alignTextLeft = () => {
    handleSelectFontStyle("leftAlignment");
    const activeObject = fabricRef.current.getActiveObject();

    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("textAlign", "left");
      fabricRef.current.renderAll();
      saveHistory();
    }
  };

  const setLineHeight = () => {
    handleSelectFontStyle("lineHeight");
    const activeObject = fabricRef.current.getActiveObject();

    if (activeObject && activeObject.type === "textbox") {
      activeObject.set(
        "lineHeight",
        2 * parseInt(getComputedStyle(document.body).fontSize)
      );
      fabricRef.current.renderAll();
      saveHistory();
    }
  };

  const handleSelectFontStyle = (id, value) => {
    setInitialState((prev) => {
      return { ...prev, [id]: value || !prev[id] };
    });
  };
  const generateQRCode = (isAdded) => {
    if (initialState.isQRCode && isAdded) {
      removeItemFromCanvas("isQRCode");
    } else {
      const qrCodeElement = document.getElementById("qrCodeEl");
      const borderRect = new fabric.Rect({
        left: 375,
        top: 25,
        width: qrCodeElement.width,
        height: qrCodeElement.height,
        fill: "transparent",
        stroke: "white",
        strokeWidth: 4,
      });
      const qrImage = new fabric.Image(
        qrCodeElement,
        {
          left: 377,
          top: 25,
          width: qrCodeElement.width,
          height: qrCodeElement.height,
        },
        { crossOrigin: "anonymous" }
      );
      var group = new fabric.Group([qrImage, borderRect], {
        clipPath: borderRect,
      });
      fabricRef.current.add(group);
      group.set("id", "isQRCode");
      fabricRef.current.renderAll();
      handleSelectFontStyle("isQRCode", true);
      saveHistory();
    }
  };
  const addRemoveProfileImage = (isProfileImg) => {
    if (initialState.isProfileImg && isProfileImg) {
      removeItemFromCanvas("isProfileImg");
    } else {
      fabric.Image.fromURL(
        profileURL,
        (img) => {
          img.scaleToWidth(100);
          img.scaleToHeight(100);
          img.set({
            left: 29,
            top: 29,
          });
          var circle = new fabric.Circle({
            left: 29,
            top: 29,
            radius: 50,
            fill: "",
            strokeWidth: 0,
          });

          var group = new fabric.Group([img, circle], {
            clipPath: circle,
          });
          fabricRef.current.add(group);
          group.set("id", "isProfileImg");
          fabricRef.current.renderAll();
          handleSelectFontStyle("isProfileImg", true);
          saveHistory();
        },
        { crossOrigin: "anonymous" }
      );
    }
  };
  const addcoverMedia = (isCoverMedia) => {
    if (initialState.iscoverMedia && isCoverMedia) {
      removeItemFromCanvas("iscoverMedia");
    } else {
      fabric.Image.fromURL(
        logoImg,
        (img) => {
          img.scaleToWidth(100);
          img.set({
            left: 29,
            top: 140,
          });
          var circle = new fabric.Circle({
            left: 29,
            top: 140,
            radius: 50,
            fill: "",
            strokeWidth: 0,
          });

          var group = new fabric.Group([img, circle], {
            clipPath: circle,
          });
          fabricRef.current.add(group);
          group.set("id", "iscoverMedia");
          fabricRef.current.renderAll();
          handleSelectFontStyle("iscoverMedia", true);
          saveHistory();
        },
        { crossOrigin: "anonymous" }
      );
    }
  };
  const addWhatsAppDetails = (isWhatsApp) => {
    if (initialState.isWhatsApp && isWhatsApp) {
      removeItemFromCanvas("isWhatsApp");
    } else {
      const iconUrl = icons.whatsAppIcon;
      fabric.Image.fromURL(
        iconUrl,
        (img) => {
          img.set({ width: 20, height: 20 });

          const text = new fabric.Textbox(whatsAppNum, {
            left: img.width + 5,
            top: 0,
            fill: "#ffffff",
            fontSize: 16,
            width: 100,
            fontFamily: "font-poppins",
          });

          const group = new fabric.Group([img, text], {
            left: 200,
            top: fabricRef.current.height - 25,
            selectable: true,
          });
          fabricRef.current.add(group);
          group.set("id", "isWhatsApp");
          fabricRef.current.renderAll();
          handleSelectFontStyle("isWhatsApp", true);
          saveHistory();
        },
        { crossOrigin: "anonymous" }
      );
    }
  };

  const addContactNumber = (isContactNum) => {
    if (initialState.isContactNum && isContactNum) {
      removeItemFromCanvas("isContactNum");
    } else {
      fabric.Image.fromURL(
        callPng,
        (img) => {
          img.set({ width: 20, height: 20 });
          img.scaleToWidth(20);
          img.scaleToHeight(20);
          const text = new fabric.Textbox(contactNum, {
            left: img.width + 5,
            top: 0,
            fill: "#ffffff",
            fontSize: 16,
            width: 100,
            fontFamily: "font-poppins",
          });

          const group = new fabric.Group([img, text], {
            left: 200,
            top: fabricRef.current.height - 25,
            selectable: true,
          });
          fabricRef.current.add(group);
          group.set("id", "isContactNum");
          fabricRef.current.renderAll();
          handleSelectFontStyle("isContactNum", true);
          saveHistory();
        },
        { crossOrigin: "anonymous" }
      );
    }
  };
  const addEmail = (isEmail) => {
    if (initialState.isEmail && isEmail) {
      removeItemFromCanvas("isEmail");
    } else {
      fabric.Image.fromURL(
        emailPng,
        (img) => {
          img.set({ width: 20, height: 20, crossOrigin: "anonymous" });

          const text = new fabric.Textbox(userEmail, {
            left: img.width + 5,
            top: 0,
            fill: "#ffffff",
            fontSize: 16,
            width: 100,
            fontFamily: "font-poppins",
          });

          const group = new fabric.Group([img, text], {
            left: 200,
            top: fabricRef.current.height - 25,
            selectable: true,
          });
          fabricRef.current.add(group);
          group.set("id", "isEmail");
          fabricRef.current.renderAll();
          handleSelectFontStyle("isEmail", true);
          saveHistory();
        },
        { crossOrigin: "anonymous" }
      );
    }
  };
  const addWebsite = (isWebsite) => {
    if (initialState.isWebsite && isWebsite) {
      removeItemFromCanvas("isWebsite");
    } else {
      fabric.Image.fromURL(
        websitePng,
        (img) => {
          img.set({ width: 20, height: 20, crossOrigin: "anonymous" });

          const text = new fabric.Textbox(userWebsite, {
            left: img.width + 5,
            top: 0,
            fill: "#ffffff",
            fontSize: 16,
            width: 100,
            fontFamily: "font-poppins",
          });

          const group = new fabric.Group([img, text], {
            left: 200,
            top: fabricRef.current.height - 25,
            selectable: true,
          });
          fabricRef.current.add(group);
          group.set("id", "isWebsite");
          fabricRef.current.renderAll();
          handleSelectFontStyle("isWebsite", true);
          saveHistory();
        },
        { crossOrigin: "anonymous" }
      );
    }
  };
  const addInstagram = (isInstagram) => {
    if (initialState.isInstagram && isInstagram) {
      removeItemFromCanvas("isInstagram");
    } else {
      fabric.Image.fromURL(
        instagramPng,
        (img) => {
          img.set({ width: 20, height: 20, crossOrigin: "anonymous" });

          const text = new fabric.Textbox(userInstagram, {
            left: img.width + 5,
            top: 0,
            fill: "#ffffff",
            fontSize: 16,
            width: 100,
            fontFamily: "font-poppins",
          });

          const group = new fabric.Group([img, text], {
            left: 200,
            top: fabricRef.current.height - 25,
            selectable: true,
          });
          fabricRef.current.add(group);
          group.set("id", "isInstagram");
          fabricRef.current.renderAll();
          handleSelectFontStyle("isInstagram", true);
          saveHistory();
        },
        { crossOrigin: "anonymous" }
      );
    }
  };
  const addFaceBook = (isFaceBook) => {
    if (initialState.isFaceBook && isFaceBook) {
      removeItemFromCanvas("isFaceBook");
    } else {
      fabric.Image.fromURL(
        facebookPng,
        (img) => {
          img.set({ width: 20, height: 20, crossOrigin: "anonymous" });

          const text = new fabric.Textbox(userFacebook, {
            left: img.width + 5,
            top: 0,
            fill: "#ffffff",
            fontSize: 16,
            width: 100,
            fontFamily: "font-poppins",
          });

          const group = new fabric.Group([img, text], {
            left: 200,
            top: fabricRef.current.height - 25,
            selectable: true,
          });
          fabricRef.current.add(group);
          group.set("id", "isFaceBook");
          fabricRef.current.renderAll();
          handleSelectFontStyle("isFaceBook", true);
          saveHistory();
        },
        { crossOrigin: "anonymous" }
      );
    }
  };

  const removeItemFromCanvas = (key) => {
    fabricRef.current.forEachObject((obj) => {
      if (obj.get("id") === key) {
        fabricRef.current.remove(obj);
        fabricRef.current.renderAll();
        handleSelectFontStyle(key, false);
        saveHistory();
      }
    });
  };
  useEffect(() => {}, [initialState]);
  return (
    <div id="banner-img-edit">
      {!imgPath && (
        <div className="banner-bg-edit color-ffff text-28-600 fc-center">
          Select Category
        </div>
      )}
      <div className="top-toolbox">
        <div className="fb-center mb-4">
          {/* top side toolbox --- start--- */}
          <div className="bg-ffff cp-8 br-8 d-flex gap-2">
            {/* <button className="cp-5" id="increase-font-size" onClick={addText}>
              add text
            </button> */}
            <div className="w-150">
              <Dropdown
                options={isGoogleFont}
                id="fontFamily"
                onChange={changeFontFamily}
                value={initialState?.fontFamily}
                labelClass="text-16-700 mb-2"
                isSearchable={true}
                selectStyle="SEARCH-WHITE"
              />
            </div>
            <div className="b-grey-250 br-5 d-flex text-12-600">
              <button
                className="w-30 text-14-400 mb-1"
                id="decrease-font-size"
                onClick={() => changeFontSize(initialState.fontSize - 1)}
              >
                -
              </button>
              <span className="cp-5 w-30 bs-grey-250 be-grey-250 text-14-600 fc-center">
                {initialState.fontSize}
              </span>
              <button
                className="w-30 text-14-400  mb-1"
                id="increase-font-size"
                onClick={() => changeFontSize(initialState.fontSize + 1)}
              >
                +
              </button>
            </div>
            <div
              className={`text-18-500 w-35 text-center me-1 pointer color-box-1`}
            >
              <span>A</span>
              <span className="h-8 rounded-pill d-flex w-30 mx-auto overflow-hidden">
                <span className="col-4 bg-0622"></span>
                <span className="col-4 bg-grey-E2"></span>
                <span className="col-4 bg-0000"></span>
              </span>
              <div className="color-picker">
                <SketchPicker
                  color={initialState.fontColor}
                  onChangeComplete={handleColorChange}
                />
              </div>
            </div>
            <div
              className={`text-21-500 w-35 fc-center me-1 p-1 pointer br-5 ${
                initialState.bold ? "bg-grey-E2" : ""
              }`}
              onClick={() => toggleStyle("fontWeight")}
            >
              <b>B</b>
            </div>
            <div
              className={`text-21-500 w-35 fc-center me-1 p-1 pointer br-5 ${
                initialState.italic ? "bg-grey-E2" : ""
              }`}
              onClick={toggleItalic}
            >
              <img src={icons.italicIcon} width={20} height={25} alt="italic" />
            </div>
            <div
              className={`text-21-500 w-35 fc-center me-1 p-1 pointer br-5 ${
                initialState.underline ? "bg-grey-E2" : ""
              }`}
              onClick={toggleUnderline}
            >
              <img
                src={icons.underlineIcon}
                width={20}
                height={25}
                alt="underline"
              />
            </div>
            <div
              className={`text-21-500 w-35 fc-center me-1 p-1 pointer br-5 ${
                initialState.capitalize ? "bg-grey-E2" : ""
              }`}
              onClick={() => changeTextCase("sentenceCase")}
            >
              aA
            </div>
            <span className="be-grey-250"></span>
            <div
              className={`text-21-500 w-35 fc-center me-1 p-1 pointer br-5 ${
                initialState.leftAlignment ? "bg-grey-E2" : ""
              }`}
              onClick={alignTextLeft}
            >
              <img
                src={icons.leftAlignmentIcon}
                width={20}
                height={20}
                alt="left-align"
              />
            </div>
            <div
              className={`text-21-500 w-35 fc-center me-1 p-1 pointer br-5 ${
                initialState.bullets ? "bg-grey-E2" : ""
              }`}
              onClick={addBulletPoints}
            >
              <img
                src={icons.bulletsIcon}
                width={20}
                height={25}
                alt="italic"
              />
            </div>
            <div
              className={`text-21-500 w-35 fc-center me-1 p-1 pointer br-5 ${
                initialState.lineHeight ? "bg-grey-E2" : ""
              }`}
              onClick={setLineHeight}
            >
              <img
                src={icons.lineHeightIcon}
                width={20}
                height={25}
                alt="italic"
              />
            </div>
          </div>
          {/* top side toolbox --- end--- */}
          <Button
            btnText="Download"
            btnStyle="BLACK-BLUE"
            className="text-19-400 w-100 text-center cp-10 br-8"
            iconClass="me-1"
            onClick={downloadImage}
            leftIcon={icons.downloadIcon}
          />
        </div>
      </div>
      <div className="banner-editor">
        {/* left side toolbox --- start--- */}
        <div className="bg-2f33 br-8 p-2 ">
          <div className="cmb-14 tools upload">
            <input type="file" accept="image/*" onChange={addImg} />
            <img src={icons.uploadIcon} alt="upload" height={25} width={25} />
            <p className="font-poppins text-12-600 color-ffff mt-1">Uploads</p>
          </div>
          <div className="cmb-14 tools color-box">
            <span
              className="h-24 w-24 b-ffff "
              style={{ backgroundColor: initialState.fontColor }}
            ></span>
            <p className="font-poppins text-12-600 color-ffff mt-1 text-nowrap">
              Font Color
            </p>
            <div className="color-picker">
              <SketchPicker
                color={initialState.fontColor}
                onChangeComplete={handleColorChange}
              />
            </div>
          </div>
          <div
            className={`cmb-14 tools ${
              initialState.isTextAdded ? "bg-1B23" : ""
            }`}
            onClick={addText}
          >
            <img src={icons.textIcon} alt="upload" height={22} width={22} />
            <p className="font-poppins text-12-600 color-ffff mt-1">Text</p>
          </div>
          <div className="cmb-14 tools" onClick={downloadImage}>
            <img src={icons.downloadIcon} alt="upload" height={25} width={25} />
            <p className="font-poppins text-12-600 color-ffff mt-1">Download</p>
          </div>
          <button
            className="cmb-14 tools w-100"
            disabled={!activeObject}
            onClick={deleteActiveElement}
          >
            <img src={icons.deleteIcon} alt="upload" height={20} width={20} />
            <p className="font-poppins text-12-600 color-ffff mt-1">Delete</p>
          </button>
          <div className="cmb-14 tools color-box">
            <span
              className="h-24 w-24 b-ffff "
              style={{ backgroundColor: initialState.stripColor }}
            ></span>
            <p className="font-poppins text-12-600 color-ffff mt-1 text-nowrap">
              Strip Color
            </p>
            <div className="color-picker">
              <SketchPicker
                color={initialState.stripColor}
                onChangeComplete={handleStripColorChange}
              />
            </div>
          </div>
          <div className="cmb-14 tools" onClick={forwardFunction}>
            <img src={icons.forwardIcon} alt="upload" height={25} width={25} />
            <p className="font-poppins text-12-600 color-ffff mt-1">Redo</p>
          </div>
          <div className="mb-1 tools" onClick={backwardFunction}>
            <img src={icons.backwardIcon} alt="upload" height={25} width={25} />
            <p className="font-poppins text-12-600 color-ffff mt-1">Undo</p>
          </div>
        </div>
        {/* left side toolbox --- end--- */}
        <div className="flex-grow-1">
          <div className="d-flex justify-content-center">
            <canvas
              id="canvas"
              ref={canvasRef}
              height={522}
              width={522}
            ></canvas>
          </div>
          {/* bottom add details list */}
          <div className="row justify-content-center mt-5 pt-5 gx-4">
            <div className="col-auto">
              <button
                className="fc-center flex-column gap-2 position-relative"
                onClick={() =>
                  generateQRCode(initialState.isQRCode ? true : false)
                }
              >
                <span
                  className={`d-inline-block bg-4949 br-8 p-3 ${
                    initialState.isQRCode ? "bg-9EF8" : "bg-4949"
                  }`}
                >
                  <img src={icons.qrCodeIcon} alt="QR" width={25} height={25} />
                </span>
                <p className="color-ffff text-14-400">QR code</p>
                <QRCode
                  id="qrCodeEl"
                  width={100}
                  height={100}
                  className="no-border"
                  value={`https://mygrid.club/${userData?.businessCard?.identifier}`}
                />
              </button>
            </div>
            {userData?.userIntroduction?.coverMedia ? (
              <div className="col-auto">
                <button
                  className="fc-center flex-column gap-2"
                  onClick={() => {
                    addcoverMedia(initialState.iscoverMedia ? true : false);
                  }}
                >
                  <span
                    className={`d-inline-block br-8 p-3  ${
                      initialState.iscoverMedia ? "bg-9EF8" : "bg-4949"
                    }`}
                  >
                    <img
                      src={icons.greetingLogoIcon}
                      alt="logo"
                      width={25}
                      height={25}
                    />
                  </span>
                  <p className="color-ffff text-14-400">Logo</p>
                </button>
              </div>
            ) : null}

            {userData?.userIntroduction?.profileMedia ? (
              <div className="col-auto">
                <button
                  className="fc-center flex-column gap-2"
                  onClick={() =>
                    addRemoveProfileImage(
                      initialState.isProfileImg ? true : false
                    )
                  }
                >
                  <span
                    className={`d-inline-block bg-4949 br-8 p-3 ${
                      initialState.isProfileImg ? "bg-9EF8" : "bg-4949"
                    }`}
                  >
                    <img
                      src={icons.usersIcon}
                      alt="QR"
                      width={25}
                      height={25}
                    />
                  </span>
                  <p className="color-ffff text-14-400">Profile</p>
                </button>
              </div>
            ) : null}
            {contactNum ? (
              <div className="col-auto">
                <button
                  className="fc-center flex-column gap-2"
                  onClick={() => {
                    addContactNumber(initialState.isContactNum ? true : false);
                  }}
                  disabled={!contactNum}
                >
                  <span
                    className={`d-inline-block bg-4949 br-8 p-3 ${
                      initialState.isContactNum ? "bg-9EF8" : "bg-4949"
                    }`}
                  >
                    <img src={icons.callIcon} alt="QR" width={25} height={25} />
                  </span>
                  <p className="color-ffff text-14-400">Call</p>
                </button>
              </div>
            ) : null}
            {whatsAppNum ? (
              <div className="col-auto">
                <button
                  className="fc-center flex-column gap-2"
                  onClick={() => {
                    addWhatsAppDetails(initialState.isWhatsApp ? true : false);
                  }}
                  disabled={!whatsAppNum}
                >
                  <span
                    className={`d-inline-block br-8 p-3 ${
                      initialState.isWhatsApp ? "bg-9EF8" : "bg-4949"
                    }`}
                  >
                    <img
                      src={icons.whatsAppIcon}
                      alt="whatsApp"
                      width={25}
                      height={25}
                    />
                  </span>
                  <p className="color-ffff text-14-400">Whatsapp</p>
                </button>
              </div>
            ) : null}

            {userEmail ? (
              <div className="col-auto">
                <button
                  className="fc-center flex-column gap-2"
                  onClick={() => {
                    addEmail(initialState.isEmail ? true : false);
                  }}
                  disabled={!userEmail}
                >
                  <span
                    className={`d-inline-block br-8 p-3 ${
                      initialState.isEmail ? "bg-9EF8" : "bg-4949"
                    }`}
                  >
                    <img
                      src={icons.emailIcon}
                      alt="QR"
                      width={25}
                      height={25}
                    />
                  </span>
                  <p className="color-ffff text-14-400">Email</p>
                </button>
              </div>
            ) : null}
            {userWebsite ? (
              <div className="col-auto">
                <button
                  className="fc-center flex-column gap-2"
                  onClick={() => {
                    addWebsite(initialState.isWebsite ? true : false);
                  }}
                  disabled={!userWebsite}
                >
                  <span
                    className={`d-inline-block br-8 p-3 ${
                      initialState.isWebsite ? "bg-9EF8" : "bg-4949"
                    }`}
                  >
                    <img
                      src={icons.websiteIcon}
                      alt="QR"
                      width={25}
                      height={25}
                    />
                  </span>
                  <p className="color-ffff text-14-400">Website</p>
                </button>
              </div>
            ) : null}
            {userInstagram ? (
              <div className="col-auto">
                <button
                  className="fc-center flex-column gap-2"
                  onClick={() => {
                    addInstagram(initialState.isInstagram ? true : false);
                  }}
                  disabled={!userInstagram}
                >
                  <span
                    className={`d-inline-block br-8 p-3 ${
                      initialState.isInstagram ? "bg-9EF8" : "bg-4949"
                    }`}
                  >
                    <img
                      src={icons.istagramIcon}
                      alt="QR"
                      width={25}
                      height={25}
                    />
                  </span>
                  <p className="color-ffff text-14-400">Instagram</p>
                </button>
              </div>
            ) : null}
            {userFacebook ? (
              <div className="col-auto">
                <button
                  className="fc-center flex-column gap-2"
                  onClick={() => {
                    addFaceBook(initialState.isFaceBook ? true : false);
                  }}
                  disabled={!userFacebook}
                >
                  <span
                    className={`d-inline-block br-8 p-3 ${
                      initialState.isFaceBook ? "bg-9EF8" : "bg-4949"
                    }`}
                  >
                    <img
                      src={icons.facebookIcon}
                      alt="QR"
                      width={25}
                      height={25}
                    />
                  </span>
                  <p className="color-ffff text-14-400">FaceBook</p>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;
