import React, { useEffect, useState } from "react";
import "./UserContactCard.scss";
import UserFaceImg from "assets/images/common/dummyProfile.png";
import Button from "components/inputs/Button";
import { commonRoute, icons, logPrimeButtonClickEvent } from "utils/constants";
import { capitalize, cloneDeep } from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { downloadURI } from "utils/helpers";
import {
  sendConnectRequest,
  setPrimeMemberModal,
  storeDiscoverList,
} from "store/slices";
import { setRecentConnecttCounts } from "store/slices/connectSlice";
import GridCoinSent from "../GridCoinSent";
const UserContactCard = ({ data, userData }) => {
  const { discoverList } = useSelector((state) => ({
    discoverList: state.common.discoverList,
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isConnect, SetIsConnect] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isCount, setIsCount] = useState(0);

  const connectRDX = useSelector((state) => state.connect);
  const { connectList, connectCount } = connectRDX || {};
  const selctedUserChatCard = connectList?.find(
    (ele) =>
      ele?.connect?.shared_to_id === data?.user?.id ||
      ele?.connect?.shared_by_id === data?.user?.id
  );
  const userProfileImg =
    data?.user && data.user?.userIntroduction?.profileMedia
      ? data.user?.userIntroduction?.profileMedia?.cdn_url +
        "/" +
        data.user?.userIntroduction?.profileMedia?.path
      : UserFaceImg;
  const userName = data?.user?.name
    ? data?.user?.name
    : data?.visitorName
    ? `${data?.visitorName} have seen on web`
    : "User have seen your profile on web";
  const userDescription = data?.user
    ? `${data?.user?.userIntroduction?.headline} | ${capitalize(
        data?.user?.city
      )}, ${capitalize(data?.user?.country)}`
    : data?.visitorContact
    ? data?.visitorContact
    : `${moment(data?.createdAt).format("DD-MM-YYYY")}`;
  const handleNaviget = (ele) => {
    navigate(commonRoute.myConnects, { state: { selectedUserId: ele } });
  };
  const sendRequest = async () => {
    if (!userData?.prime && connectCount > 4) {
      logPrimeButtonClickEvent("analytics");
      return dispatch(setPrimeMemberModal(true));
    }
    if (data.connect_status === 3) return;
    const res = await dispatch(sendConnectRequest({ userId: data?.user?.id }));

    if (res?.data?.message) {
      SetIsConnect(true);
      dispatch(setRecentConnecttCounts(connectCount + 1));

      let modalOpen = setTimeout(() => setIsModal(true));

      if (data?.connect_status === 3 || data?.connect_status === 4) {
      }
      let temp = cloneDeep(discoverList)?.map((ele, i) => {
        if (ele?.id === data?.id) {
          ele.connect_status = 3;
        }
        return ele;
      });
      setIsCount(res?.data?.coins);
      dispatch(storeDiscoverList(temp));
    }
  };
  useEffect(() => {
    let timer = setTimeout(() => SetIsConnect(false), 2500);

    let modalClose = setTimeout(() => setIsModal(false), 10000);
    return () => {
      clearTimeout(timer);
      clearTimeout(modalClose);
    };
  }, [isConnect]);

  return (
    <div id="contact-user-container">
      {isModal && (
        <GridCoinSent
          onHide={() => {
            setIsModal(true);
          }}
          isModal={isModal}
          onClick={() => {
            setIsModal(false);
          }}
          coins={isCount}
          isConnect={isConnect}
        />
      )}
      <div className="Card-8">
        <div className="fc-center gap-2">
          <div className="connect-user-img">
            <img src={userProfileImg} alt="user-profile" />
            <span className="connect-time">{data?.spentedTime} m</span>
          </div>
          <div className="flex-grow-1">
            <div className="fe-center w-100 h-32">
              {data?.visitorContact ? (
                <>
                  <div className="wth-20 p-1">
                    <Button
                      btnText="Connect on Whatsapp"
                      className="text-9-400 px-0 py-1 w-100 text-center"
                      btnStyle="BLUE-5"
                      onClick={() =>
                        userData?.prime
                          ? downloadURI(data?.visitorContact)
                          : dispatch(setPrimeMemberModal(true))
                      }
                    />
                  </div>
                  {/* <div className="wth-20 p-1">
                    <Button
                      btnText="Whatsapp"
                      className="text-9-400 px-0 py-1 w-100 text-center"
                      btnStyle="BLUE-5"
                    />
                  </div> */}
                </>
              ) : null}
              {/* {selctedUserChatCard?.connect?.status === 4 ? (
                <div className="wth-20 p-1">
                  <Button
                    btnText="Chat"
                    className="text-9-400 px-0 py-1 w-100 text-center"
                    btnStyle="BLUE-5"
                    onClick={() => {
                      userData?.prime
                        ? handleNaviget(selctedUserChatCard?.id)
                        : dispatch(setPrimeMemberModal(true));
                    }}
                  />
                </div>
              ) : (
                ""
              )} */}
              {selctedUserChatCard?.connect?.status === 3 && (
                <div className="wth-20 p-1">
                  <Button
                    btnText="Sent"
                    btnStyle="BLUE-5"
                    className="text-9-400 w-100 text-center px-0 py-1"
                  />
                </div>
              )}
              {selctedUserChatCard?.connect?.status === 4 && (
                <div className="wth-20 p-1">
                  <Button
                    btnText="Chat"
                    className="text-9-400 px-0 py-1 w-100 text-center"
                    btnStyle="BLUE-5"
                    onClick={() => {
                      userData?.prime
                        ? handleNaviget(selctedUserChatCard?.id)
                        : dispatch(setPrimeMemberModal(true));
                    }}
                  />
                </div>
              )}
              {!(
                selctedUserChatCard?.connect?.status === 4 ||
                selctedUserChatCard?.connect?.status === 3
              ) &&
                data?.user?.id && (
                  <div className="wth-20 p-1">
                    <Button
                      btnText="Connect"
                      className="text-9-400 px-0 py-1 w-100 text-center"
                      btnStyle="BLUE-5"
                      onClick={() => {
                        sendRequest();
                      }}
                    />
                  </div>
                )}
            </div>
            <div className="fc-center">
              <div className="contact-name">
                <p className="text-12-400">{capitalize(userName)}</p>
                <p className="text-9-400 mb-0">{userDescription}</p>
              </div>
              <div className="social-icon-group">
                {data?.socialLinks?.length ? (
                  <>
                    {data?.socialLinks?.includes("whatsapp") ? (
                      <span className="social-icon">
                        <img src={icons.whatsAppIcon} alt="whatsapp" />
                      </span>
                    ) : null}
                    {data?.socialLinks?.includes("location") ? (
                      <span className="social-icon">
                        <img src={icons.locationIcon} alt="call" />
                      </span>
                    ) : null}
                    {data?.socialLinks?.includes("linkedin") ? (
                      <span className="social-icon">
                        <img src={icons.linkedInWhiteIcon} alt="linkedin" />
                      </span>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserContactCard;
