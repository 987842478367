import Layout from "pages/Layout";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MyConnects.scss";
import TextInput from "components/inputs/TextInput";
import { icons, analytics } from "utils/constants";
import Button from "components/inputs/Button";
import ConnectUnreadUserCard from "components/layouts/ConnectUnreadUserCard";
import _ from "lodash";
import {
  storeUpdateConnectsList,
  storeConnectsListByCategory,
  storeChatMessages,
  viewUserDetails,
  handleSocketLister,
  storeRequestedList,
  storeRequestedPendingList,
} from "store/slices/connectSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  exportCSVFile,
  getDataFromLocalStorage,
  storeLocalStorageData,
} from "utils/helpers";
import ChatWithConnect from "components/layouts/ChatWithConnect";
import useDebounce from "hooks/useDebounce";
import ViewUserProfile from "components/layouts/ViewUserProfile";
import { useSocket } from "context/socket";
import { Spinner } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import { useLocation } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import moment from "moment";

const userId = getDataFromLocalStorage("userId");

const MyConnects = () => {
  const socketContext = useSocket();
  const { socket } = socketContext.state;
  const [tab, setTab] = useState(1);
  const { state, location } = useLocation();
  const selectedCardRef = useRef(null);
  const testRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [loadingList, setLoadingList] = useState(false);
  const [userCategories, setUserCategories] = useState([]);
  const [isButtonShow, setIsButtonShow] = useState(false);

  const [selectedCard, setSelectedCard] = useState({
    index: 0,
    category: "All",
    count: 0,
  });
  const [selectedConnectUser, setSelectedConnectUser] = useState({});
  const [finalRequestedListUser, setFinalRequestedUser] = useState([]);
  const [requestedUserSelect, setRequestedUserSelect] = useState({});
  const [connectsList, setConnectsList] = useState([]);
  const [finalRequestedList, setFinalRequestedList] = useState([]);
  const [finalPendingList, setFinalPendingList] = useState([]);
  const userListRef = useRef(null);
  const [search, setSearch] = useState("");
  const [isProfileView, setIsProfileView] = useState(false);
  const searchDebouncedValue = useDebounce(search, 300);
  const dispatch = useDispatch();
  const profileRDX = useSelector((state) => state.profile);
  const { userData } = profileRDX || {};
  const connectRDX = useSelector((state) => state.connect);
  const {
    connectList,
    updateConnectList,
    connectsListByCategory,
    chatRoomsList,
    requestedList,
    isChatRoomUpdate,
    requestedPendingList,
  } = connectRDX || {};

  // ... rest of your code

  useEffect(() => {
    let animationFrameId;

    const scrollIntoView = () => {
      if (selectedCardRef.current) {
        selectedCardRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    const handleAnimationFrame = () => {
      animationFrameId = requestAnimationFrame(scrollIntoView);
    };

    const timerId = setTimeout(handleAnimationFrame, 2000);

    return () => {
      clearTimeout(timerId);
      cancelAnimationFrame(animationFrameId);
    };
  }, [selectedCard]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (selectedCardRef.current) {
  //       selectedCardRef.current.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }, 2000);
  //   return () => clearTimeout(timer);
  // }, [selectedCard]);
  useEffect(() => {
    logEvent(analytics, `page_view`, { page_title: "myconnect page" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    //  const list = _.filter(updateConnectList, (ele) => {
    //   if (_.isEqual(category?.category, "Others")) {
    //     return (
    //       (_.isEqual(ele?.connect?.category, "") ||
    //         _.isEqual(ele?.connect?.category, "Others")) &&
    //       _.isEqual(category?.category, "Others")
    //     );
    //   } else {
    //     return _.isEqual(ele?.connect?.category, category?.category);
    //   }
    // });

    if (userData && updateConnectList?.length) {
      const ctgList = Object?.entries(userData?.categories).map(
        ([name, code]) => {
          const list = _.filter(updateConnectList, (ele) => {
            if (_.isEqual(name, "Others")) {
              return (
                (_.isEqual(ele?.connect?.category, "") ||
                  _.isEqual(ele?.connect?.category, "Others")) &&
                _.isEqual(name, "Others")
              );
            } else {
              return _.isEqual(ele?.connect?.category, name);
            }
          });
          return {
            category: name,
            count: list.length,
          };
        }
      );

      setUserCategories([
        { category: "All", count: updateConnectList?.length },
        ...ctgList,
      ]);
    }
  }, [userData, updateConnectList]);
  useEffect(() => {
    if (socket) {
      socket?.emit("connects", {});
      socket?.emit("chat-rooms", {});
    }
  }, [socket]);
  useEffect(() => {
    handleUpdateConnectList(connectList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomsList, connectList]);
  //for socket
  const handleUpdateConnectList = (connectData) => {
    const requestListTemp = [];
    const requestPendingTemp = [];
    const matchedRooms = connectData?.map((ele) => {
      const room = chatRoomsList.find((room) =>
        room.members.some((member) => member.userId === ele.id)
      );
      const updatedElement = {
        ...ele,
        lastMessage: room?.lastMessage,
        unreadCounts: _.get(room, ["unreadCount", userId], 0),
        roomId: room?.id,
      };
      if (ele?.connect?.status === 3 && ele?.connect?.shared_by_id !== userId) {
        requestListTemp.push(updatedElement);
        return null; // Return null in this case
      } else if (
        ele?.connect?.status === 3 &&
        ele?.connect?.shared_by_id === userId
      ) {
        requestPendingTemp.push(updatedElement);
        return null; // Return null in this case
      }
      return updatedElement;
    });

    // Filter out the null elements
    const sortedRooms = _.orderBy(
      matchedRooms.filter(Boolean),
      "unreadCounts",
      "desc"
    );
    setFinalRequestedList(requestListTemp);
    dispatch(storeRequestedList(requestListTemp));
    dispatch(storeRequestedPendingList(requestPendingTemp));
    setFinalPendingList(requestPendingTemp);
    dispatch(storeUpdateConnectsList(sortedRooms));
  };

  useEffect(() => {
    if (socket) {
      dispatch(handleSocketLister(socket)); // socket listener events
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (_.has(selectedCard, "index")) {
      filterByCategory(
        selectedCard?.index,
        userCategories[selectedCard?.index]
      );
    }
    // else {
    //   filterByCategory(0, userCategories?.[0]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateConnectList, userCategories]);
  //for client event
  const filterByCategory = (i, category) => {
    setLoadingList(true);
    setSelectedCard({ index: i, ...category });
    if (!isChatRoomUpdate) {
      setSearch("");
      dispatch(storeChatMessages([]));
      setSelectedConnectUser({});
    }
    // if (category?.category !== selectedConnectUser?.connect?.category || category?.category ) {
    //   dispatch(storeChatMessages([]));
    //   setSelectedConnectUser({})
    // }
    // dispatch(setIsChatRoomUpdate(false));
    let list = [];
    if (category?.category === "All") {
      list = updateConnectList;
    } else {
      list = _.filter(updateConnectList, (ele) => {
        if (_.isEqual(category?.category, "Others")) {
          return (
            (_.isEqual(ele?.connect?.category, "") ||
              _.isEqual(ele?.connect?.category, "Others")) &&
            _.isEqual(category?.category, "Others")
          );
        } else {
          return _.isEqual(ele?.connect?.category, category?.category);
        }
      });
    }

    const UserIdsList = list.map((ele) => ele.id);
    socket?.emit("online-status", JSON.stringify({ users: UserIdsList }));
    reorderBasedOnSelectedUser(list);
    dispatch(storeConnectsListByCategory(list));
  };
  const reorderBasedOnSelectedUser = (list) => {
    const newList = search.length
      ? _.filter(connectsListByCategory, (item) => {
          return item.name.toLowerCase().includes(search.toLowerCase());
        })
      : _.cloneDeep(list);
    const index = newList.findIndex(
      (item) => item.id === selectedConnectUser?.id
    );

    if (index !== -1) {
      const selectedElement = newList[index];
      newList.splice(index, 1);
      newList.unshift(selectedElement); // Add element to the beginning
    }
    if (newList?.length > 20) {
      storeLocalStorageData({
        connectsListData: { list: newList, currentPage: 1, offset: 20 },
      });
    } else {
      storeLocalStorageData({ connectsListData: {} });
    }
    setConnectsList(newList.slice(0, 20));
    setLoadingList(false);
  };
  const showUserChat = useCallback((ele) => {
    setSelectedConnectUser(ele);

    if (ele?.unreadCounts > 0) {
      const payload = {
        chatRoomId: ele?.roomId,
      };
      const mapString = JSON.stringify(payload);
      socket?.emit("clear-unread-count", mapString);
    }
    if (isProfileView) {
      handleViewProfile(ele.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSearchByName = () => {
    if (tab === 1) {
      reorderBasedOnSelectedUser(connectsListByCategory);
    } else if (tab === 2) {
      const newList = _.filter(requestedList, (item) => {
        return item.name
          .toLowerCase()
          .includes(searchDebouncedValue.toLowerCase());
      });
      setFinalRequestedList(newList);
    } else if (tab === 3) {
      const newList = _.filter(requestedPendingList, (item) => {
        return item.name
          .toLowerCase()
          .includes(searchDebouncedValue.toLowerCase());
      });
      setFinalPendingList(newList);
    }
  };
  useEffect(() => {
    handleSearchByName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebouncedValue]);
  useEffect(() => {
    if (connectsListByCategory && tab === 1) {
      reorderBasedOnSelectedUser(connectsListByCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (state?.selectedUser?.id) {
        showUserChat(state?.selectedUser);
      }
    }, 1500);
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedUser]);

  useEffect(() => {
    const selctedUserChatCard = updateConnectList?.find(
      (ele) =>
        ele?.connect?.shared_to_id === state?.selectedUserId ||
        ele?.connect?.shared_by_id === state?.selectedUserId
    );
    const timer = setTimeout(() => {
      if (selctedUserChatCard?.id) {
        showUserChat(selctedUserChatCard);
      }
    }, 2000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedUserId]);

  const handleViewProfile = useCallback((data) => {
    if (data?.connect?.status === 4) {
      setIsButtonShow(true);
    } else {
      setIsButtonShow(false);
    }
    dispatch(viewUserDetails(data?.id));
    setIsProfileView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRequestedUserSelect = useCallback((ele) => {
    setRequestedUserSelect(ele);
  }, []);
  const handlefinalRequestedList = useCallback((ele) => {
    setFinalRequestedUser(ele);
  }, []);
  //  useEffect(() => {
  //   handleRequestedUserSelect()
  //    }
  //  , [])

  const handleScrollMoreUser = async () => {
    const element = userListRef.current;
    const listData = getDataFromLocalStorage("connectsListData");
    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      connectsList?.length <= listData?.list?.length
    ) {
      const newList = [
        ...connectsList,
        ...listData?.list?.slice(listData.offset, listData.offset + 20),
      ];
      setConnectsList(newList);
      storeLocalStorageData({
        connectsListData: {
          list: listData?.list,
          currentPage: listData.currentPage + 1,
          offset: listData.offset + 20,
        },
      });
    }
  };
  // let swiperSliderElement = document.getElementsByClassName('card-group-1024')[0];
  // let swiperSliderWidth = swiperSliderElement?.getBoundingClientRect().width;
  // console.log("swiperSliderWidth",swiperSliderWidth)
  // useEffect(() => {

  // }, [swiperSliderWidth])
  const header = ["Name", "Contact", "State", "City", "Link"];

  const downloadCsvFile = () => {
    const csvBody = [];
    updateConnectList?.forEach((o) => {
      const b_card = o?.business_card?.identifier;
      const linkData = `https://mygrid.club/${b_card}`;
      const { name, contact, state, city } = o;
      csvBody.push([name, contact, state, city, linkData]);
    });
    exportCSVFile(header, csvBody, "Connectlist.csv");
  };
  return (
    <Layout id="my-connect-container" isNotification={false}>
      <div className="row mt-3 flex-grow-1 h-1">
        {/* left block */}
        <div className="col-5 my-connect-content h-100">
          <div className="my-3 d-flex gap-tab tab-group">
            <p
              className={`text-font-15-400 mb-0 pointer ${
                tab === 1 ? "active-tab" : "color-grey-7f"
              }`}
              onClick={() => {
                setTab(1);
                setIsProfileView(false);
              }}
            >
              Connected{" "}
              {updateConnectList?.length ? (
                <>({updateConnectList?.length})</>
              ) : null}
            </p>
            <p
              className={`text-font-15-400 mb-0 pointer ${
                tab === 2 ? "active-tab" : "color-grey-7f"
              }`}
              onClick={() => {
                setTab(2);
                setIsProfileView(false);
              }}
            >
              Requests
              {requestedList?.length ? <>({requestedList?.length})</> : null}
            </p>
            <p
              className={`text-font-15-400 mb-0 pointer ${
                tab === 3 ? "active-tab" : "color-grey-7f"
              }`}
              onClick={() => {
                setTab(3);
                setIsProfileView(false);
              }}
            >
              Pending
              {requestedPendingList?.length ? (
                <>({requestedPendingList?.length})</>
              ) : null}
            </p>
          </div>
          {userCategories.length ? (
            <>
              <div
                className={`card-type-group  ${
                  tab === 2 || tab === 3 ? "d-none" : "visible"
                }`}
              >
                <div className="card-group-1024">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={4}
                    modules={[Navigation]}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                  >
                    {userCategories?.map((card, i) => {
                      return (
                        <SwiperSlide key={card?.id}>
                          <div
                            key={i}
                            className={`Card-8 font-Avenir pointer ${
                              i === selectedCard.index ? "selected" : ""
                            }`}
                            onClick={() => filterByCategory(i, card)}
                          >
                            <span>
                              {card?.category} ({card?.count})
                            </span>
                            {/* <span>{card?.category}</span> */}
                          </div>
                        </SwiperSlide>
                      );
                    })}
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                  </Swiper>
                </div>
              </div>
              <div className="fc-center gap-2 my-3">
                <TextInput
                  disabled={
                    tab === 1
                      ? !connectsListByCategory?.length
                      : tab === 2
                      ? !requestedList?.length
                      : tab === 3
                      ? !requestedPendingList?.length
                      : null
                  }
                  placeholder="Find person"
                  className="bg-0000 br-8 font-Avenir text-15-400"
                  rightIcon={icons.searchIcon}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {userData?.prime ? (
                  <Button
                    onClick={downloadCsvFile}
                    btnText="Export"
                    btnStyle="Blue-export"
                    className={`br-8 text-15-400 ${
                      tab === 2 || tab === 3 ? "d-none" : ""
                    }`}
                  />
                ) : null}
              </div>
              <div
                className="user-card-list"
                ref={userListRef}
                onScroll={handleScrollMoreUser}
              >
                {loadingList ? (
                  <div className="loading fc-center">
                    <Spinner animation="border" variant="secondary" />
                  </div>
                ) : (
                  <>
                    {tab === 1 && (
                      <>
                        {connectsList?.length ? (
                          [...connectsList]
                            ?.sort((a, b) => {
                              const dateA = moment(a?.lastMessage?.createdAt);
                              const dateB = moment(b?.lastMessage?.createdAt);
                              return dateB - dateA;
                            })
                            .map((ele, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => {
                                    showUserChat(ele);
                                    setIsProfileView(false);
                                  }}
                                  ref={
                                    selectedConnectUser?.id === ele?.id
                                      ? selectedCardRef
                                      : testRef
                                  }
                                >
                                  <ConnectUnreadUserCard
                                    className="mt-3"
                                    data={ele}
                                    isSelected={
                                      selectedConnectUser?.id === ele?.id
                                    }
                                  />
                                </div>
                              );
                            })
                        ) : (
                          <div className="fc-center h-100">No connections</div>
                        )}
                      </>
                    )}
                    {tab === 2 && (
                      <>
                        {finalRequestedList?.length ? (
                          [...finalRequestedList]
                            ?.sort((a, b) => {
                              const dateA = moment(a.lastMessage.createdAt);
                              const dateB = moment(b.lastMessage.createdAt);
                              return dateB - dateA;
                            })
                            .map((ele, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => {
                                    handleRequestedUserSelect(ele);
                                  }}
                                >
                                  <ConnectUnreadUserCard
                                    className="mt-3"
                                    data={ele}
                                    isSelected={
                                      requestedUserSelect?.id === ele?.id
                                    }
                                  />
                                </div>
                              );
                            })
                        ) : (
                          <div className="fc-center h-100">
                            No request for connects.
                          </div>
                        )}
                      </>
                    )}
                    {tab === 3 && (
                      <>
                        {finalPendingList?.length ? (
                          [...finalPendingList]
                            ?.sort((a, b) => {
                              const dateA = moment(a.lastMessage.createdAt);
                              const dateB = moment(b.lastMessage.createdAt);
                              return dateB - dateA;
                            })
                            .map((ele, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => {
                                    handlefinalRequestedList(ele);
                                  }}
                                >
                                  <ConnectUnreadUserCard
                                    className="mt-3"
                                    data={ele}
                                    isSelected={
                                      finalRequestedListUser?.id === ele?.id
                                    }
                                  />
                                </div>
                              );
                            })
                        ) : (
                          <div className="fc-center h-100">
                            No request for connects.
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="loading fc-center">
              <Spinner animation="border" variant="secondary" />
            </div>
          )}{" "}
        </div>
        {/* right block */}

        {isProfileView ? (
          <>
            <div
              className={`myconnects-slider ${isProfileView ? "active " : ""}`}
              onClick={() => {
                setIsProfileView(false);
              }}
            >
              <div
                className={`myconnects-card-bg ${
                  isProfileView ? "active" : ""
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                <ViewUserProfile
                  setIsProfileView={setIsProfileView}
                  isButtonShow={isButtonShow}
                />
              </div>
            </div>
          </>
        ) : null}
        <ChatWithConnect
          socket={socket}
          isChatView={tab}
          setConnectedUser={setSelectedConnectUser}
          connectedUser={selectedConnectUser}
          handleViewProfile={handleViewProfile}
          requestedUserSelect={requestedUserSelect}
          finalRequestedListUser={finalRequestedListUser}
          handleRequestedUserSelect={handleRequestedUserSelect}
          setTab={setTab}
        />
      </div>
    </Layout>
  );
};

export default MyConnects;
