import React from "react";

const RatingStar = ({ color, height, width }) => {
  return (
    <svg
      width={width || "17"}
      height={height || "16"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.12672C0.0795583 5.96208 0.172632 5.8042 0.278282 5.65466C0.410669 5.49315 0.618705 5.46281 0.818636 5.44407L4.48584 5.10319C4.78393 5.07552 5.08113 5.04697 5.37742 5.01395C5.54112 5.00185 5.6976 4.94225 5.82732 4.84259C5.95705 4.74293 6.05429 4.60762 6.1069 4.45355C6.63645 3.16082 7.1696 1.86868 7.70635 0.577142C7.89547 0.119362 8.2395 -0.0742794 8.68439 0.0256648C8.80658 0.0520475 8.92011 0.108455 9.01453 0.189698C9.10896 0.270941 9.18124 0.37441 9.22474 0.490584C9.54805 1.2589 9.86686 2.02812 10.1875 2.79733C10.409 3.33275 10.6378 3.85745 10.8485 4.39287C11.0016 4.78015 11.2664 4.99164 11.6888 5.02823C12.6794 5.11746 13.6601 5.2067 14.6463 5.30218C15.1866 5.35215 15.727 5.40391 16.2674 5.45745C16.4264 5.46864 16.578 5.52859 16.7012 5.62901C16.8243 5.72943 16.9128 5.86533 16.9545 6.01785C17.0653 6.35606 16.9716 6.65321 16.6969 6.89147C15.5892 7.85254 14.4905 8.82343 13.3747 9.77379C13.0027 10.0897 12.8838 10.4315 13.0054 10.9062C13.3485 12.2447 13.6619 13.5993 13.9907 14.945C14.0807 15.3073 13.9988 15.6143 13.698 15.8374C13.3972 16.0605 13.0748 16.0426 12.7524 15.8534C11.514 15.1297 10.2712 14.4141 9.03652 13.685C8.87822 13.5792 8.69162 13.5227 8.50067 13.5227C8.30972 13.5227 8.12312 13.5792 7.96482 13.685C6.73281 14.4167 5.4909 15.1395 4.25078 15.8534C3.85092 16.0854 3.47898 16.0408 3.18449 15.7249C3.08921 15.6242 3.02239 15.5005 2.99077 15.366C2.95915 15.2316 2.96384 15.0913 3.00437 14.9593C3.34479 13.5761 3.67981 12.193 4.02474 10.8107C4.07783 10.6399 4.07742 10.4571 4.02356 10.2865C3.9697 10.1158 3.86492 9.96541 3.72304 9.85499C2.61982 8.90106 1.5238 7.93821 0.418774 6.98249C0.227849 6.81651 0.0630413 6.63983 0 6.38907V6.12672Z"
        fill={color}
      />
    </svg>
  );
};

export default RatingStar;
