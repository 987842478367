import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import globalSlice from "./slices/globalSlice";
import commonSlice from "./slices/commonSlice";
import connectSlice from "./slices/connectSlice";
import greetingSlice from "./slices/greetingSlice";
import profileSlice from "./slices/profileSlice";
import analyticsSlice from "./slices/analyticsSlice";

const store = configureStore({
    reducer: {
        global: globalSlice,
        auth: authSlice,
        common: commonSlice,
        connect: connectSlice,
        greeting: greetingSlice,
        profile: profileSlice,
        analytics: analyticsSlice,
    }
});

export default store;