import React, { useEffect, useState } from "react";
import "./Profile.scss";
import Layout from "pages/Layout";
//components
import ManageImgCard from "components/layouts/ManageImgCard";
import EditUserDetails from "components/layouts/EditUserDetails";
import EditSocialLinks from "components/layouts/EditSocialLinks";
import ManageProductAndService from "components/layouts/ManageProductAndService";
import RatingCard from "components/layouts/RatingCard";
import RightProfileSection from "./RightProfileSection";
import { icons } from "utils/constants";
//images
import dummyImg from "assets/images/common/dummyProfile.png";
import dummyBannerImg from "assets/images/common/dummyBanner.jpg";
//state-management
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMedia,
  deleteRatingData,
  getRatingList,
  getStateCityData,
  getUserData,
  uploadBannerMedia,
  uploadCoverMedia,
  uploadProfileMedia,
} from "store/slices";
import { objectToFormData } from "utils/helpers";
import DeleteModal from "components/layouts/DeleteModal";
import ImageCropper from "components/layouts/ImageCropper";
import imageCompression from "browser-image-compression";

const Profile = () => {
  const dispatch = useDispatch();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedRatingData, setSelectedRatingData] = useState({});
  const [imageCopper, setimageCopper] = useState(null);
  const [imageValue, setImageValue] = useState(null);
  const { userData, countryId, ratingList } = useSelector((state) => ({
    userData: state.profile.userData,
    countryId: state.profile.countryId,
    ratingList: state.profile.ratingList,
  }));
  const profileImg =
    userData?.userIntroduction?.profileMedia?.cdn_url +
    "/" +
    userData?.userIntroduction?.profileMedia?.path;

  const profileHash = userData?.userIntroduction?.profileMedia?.hashed;
  const logoImg =
    userData?.userIntroduction?.coverMedia?.cdn_url +
    "/" +
    userData?.userIntroduction?.coverMedia?.thumbnail;

  const logoHash = userData?.userIntroduction?.profileMedia?.hashed;

  const bannerImg =
    userData?.bannerMedia?.cdn_url + "/" + userData?.bannerMedia?.thumbnail;
  const bannerHash = userData?.bannerMedia?.hashed;
  const bannerImgReal =
    userData?.bannerMedia?.cdn_url + "/" + userData?.bannerMedia?.path;
  const fetchData = async () => {
    await dispatch(getRatingList());
    await dispatch(getUserData());
    if (countryId) {
      await dispatch(getStateCityData(1));
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateUserProfileImage = async (file) => {
    const fileImage = file?.target?.value;
    const formData = objectToFormData({ media: fileImage });
    const res = await dispatch(uploadProfileMedia(formData));
    if (res?.status === 200) {
      setimageCopper(null);
      setImageValue();
    }
  };
  const updateUserLogo = async (file) => {
    const fileImage = file?.target?.value;
    const formData = objectToFormData({ media: fileImage });
    const res = await dispatch(uploadCoverMedia(formData));
    if (res?.status === 200) {
      setimageCopper(null);
      setImageValue();
    }
  };
  const updateUserBanner = async (e) => {
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 800,
    };
    const compressedFile = await imageCompression(e, options);
    const formData = objectToFormData({ media: compressedFile });
    await dispatch(uploadBannerMedia(formData));
  };
  const deleteUserMedia = async (endPoint) => {
    await dispatch(deleteMedia(endPoint));
  };

  const showDeleteModal = (data) => {
    setSelectedRatingData(data);
    setIsDeleteModal(true);
  };
  const deleteRating = async () => {
    const res = await dispatch(deleteRatingData(selectedRatingData?.id));
    if (res.status === 200) {
      fetchData();
    }
    setIsDeleteModal(false);
  };
  const onClose = () => {
    setIsDeleteModal(false);
  };
  return (
    <Layout
      id="profile-container"
      isNotification={false}
      text="View/edit Profile"
    >
      <div className="row mt-3">
        <div className="col-8 user-profile-manage">
          <ManageImgCard
            title="Profile Image"
            imgUrl={
              userData?.userIntroduction?.profileMedia ? profileImg : dummyImg
            }
            hash={profileHash}
            Image={userData?.userIntroduction?.profileMedia}
            setimageCopper={(file) => {
              setimageCopper(file);
              setImageValue("profile-media");
            }}
            handleDelete={() => deleteUserMedia("profile-media")}
          />
          <ManageImgCard
            title="Logo"
            imgUrl={userData?.userIntroduction?.coverMedia ? logoImg : dummyImg}
            Image={userData?.userIntroduction?.coverMedia}
            hash={logoHash}
            setimageCopper={(file) => {
              setimageCopper(file);
              setImageValue("cover-media");
            }}
            handleDelete={() => deleteUserMedia("cover-media")}
          />
          <EditUserDetails />
          <ManageImgCard
            title="Banner Image"
            imgUrl={userData?.bannerMedia ? bannerImg : dummyBannerImg}
            Image={userData?.bannerMedia}
            hash={bannerHash}
            handleUpload={updateUserBanner}
            handleDelete={() => deleteUserMedia("banner-media")}
          />
          <EditSocialLinks />
          <ManageProductAndService DataPrime={userData?.prime} />
          {/* rating list */}
          <div className="Card-8 my-3">
            <p className="text-16-700">Ratings</p>
            {ratingList?.slice(0, 3).map((details, i) => {
              return (
                <div className="row mt-3 justify-content-between" key={i}>
                  <div className="col-8">
                    <RatingCard ratingData={details} />
                  </div>
                  <button
                    className="col-1"
                    onClick={() => showDeleteModal(details)}
                  >
                    <img src={icons.deleteIcon} alt="delete" />
                  </button>
                </div>
              );
            })}
          </div>
          {isDeleteModal && (
            <DeleteModal
              onClose={onClose}
              onDelete={deleteRating}
              question="Do you want to delete this image?"
            />
          )}
        </div>
        {/* Right side */}
        <RightProfileSection
          logoImg={logoImg}
          profileImg={profileImg}
          logoHash={logoHash}
          imageUrl={bannerImgReal}
          bannerHash={bannerHash}
        />
      </div>
      {imageCopper && (
        <ImageCropper
          onHide={() => {
            setimageCopper(null);
          }}
          fileURL={imageCopper}
          handelCropImage={async (e) => {
            const file = e?.target?.value;
            const options = {
              maxSizeMB: 3,
              maxWidthOrHeight: 500,
              useWebWorker: true,
            };
            const compressedFile = await imageCompression(file, options);
            if (imageValue === "profile-media") {
              updateUserProfileImage({
                target: {
                  value: compressedFile,
                },
              });
            } else if (imageValue === "cover-media") {
              updateUserLogo({
                target: {
                  value: compressedFile,
                },
              });
            }
          }}
        />
      )}
    </Layout>
  );
};

export default Profile;
