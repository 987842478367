import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { downloadURI } from "utils/helpers";
import { Blurhash } from "react-blurhash";
const TrendingSlider = ({ data }) => {
  return (
    <>
      <div className="right-slider my-2">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {data?.slice(0, 6).map((ele, i) => {
            return (
              <SwiperSlide key={i}>
                <div
                  className="slider-img pointer"
                  onClick={() => {
                    downloadURI(ele?.targetUrl);
                  }}
                >
                  {ele?.media ? (
                    <img
                      src={
                        ele?.media
                          ? ele?.media?.cdn_url + "/" + ele?.media?.path
                          : null
                      }
                      alt="img-1"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "5px",
                        overflow: "hidden",
                        display: "inline-block",
                        height: "277px",
                        width: "100%",
                      }}
                    >
                      <Blurhash
                        hash={ele?.media?.hashed}
                        height={277}
                        punch={1}
                        style={{ borderRadius: "5px", width: "100%" }}
                      />
                    </div>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default TrendingSlider;
