import React from "react";
import "./SideBar.scss";
import advertiseImg from "assets/images/common/Artboard-1.svg";
import UserFaceImg from "assets/images/common/dummyProfile.png";
import Button from "components/inputs/Button";
import { commonRoute, icons, logPageClickEvent } from "utils/constants";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { capitalize } from "lodash";
import { downloadURI } from "utils/helpers";
const SideBar = () => {
  const navigate = useNavigate();
  const profileRDX = useSelector((state) => state.profile);
  const { userData } = profileRDX || {};
  return (
    <div id="sidebar-container">
      <div className="Card-5 mb-3">
        <div className="fc-center flex-column">
          <NavLink
            to={commonRoute.dashboard}
            className="un"
            onClick={() => {
              logPageClickEvent("dashboard page");
            }}
          >
            <div className="rounded-circle">
              <div className="position-relative-bg">
                {userData?.prime ? (
                  <img
                    src={icons.primeumRing}
                    alt="ring"
                    width={105}
                    height={105}
                    className=""
                  />
                ) : null}

                <img
                  src={
                    userData?.userIntroduction?.profileMedia
                      ? `${userData?.userIntroduction?.profileMedia?.cdn_url}/${userData?.userIntroduction?.profileMedia?.path}`
                      : UserFaceImg
                  }
                  alt="user"
                  className={`mb-1 ${
                    userData?.prime ? "right-profile-img" : "with-image"
                  }`}
                  width={90}
                />
              </div>
            </div>
          </NavLink>
          {userData.prime ? (
            <button className="prime-btn mt-1 un">Prime</button>
          ) : (
            <Button
              btnText="Free user"
              btnStyle=" color-ffff text-11-400 mt-1 mb-1 un btn-free"
            />
          )}

          <p className="text-12-400 mb-1 un ">{userData?.name}</p>
          <p
            className="text-center text-10-400 color-grey-550 mx-auto text-wrap un"
            style={{ maxWidth: "250px" }}
          >
            {capitalize(userData?.userIntroduction?.headline)}
          </p>
          <Button
            className="text-12-400 mt-3 un"
            btnStyle={`${userData.prime ? "gradient-btn" : "BLUE-5"}`}
            btnText="View/edit Profile"
            onClick={() => {
              navigate(commonRoute.profile);
              logPageClickEvent("Profile page");
            }}
          />
        </div>
        <div className="m-4 mt-5">
          <NavLink
            className="fa-center gap-4 pt-2 pb-3 un"
            to={commonRoute.dashboard}
            onClick={() => {
              logPageClickEvent("dashboard page");
            }}
          >
            <img src={icons.Dashboard} alt="icon" width={18} height={18} />
            <span className="text-12-400 color-ffff">Dashboard</span>
          </NavLink>
          <NavLink
            className="fa-center gap-4 pt-2 pb-3 un"
            to={commonRoute.myConnects}
          >
            <img src={icons.caseIcon} alt="icon" />
            <span className="text-12-400 color-ffff">My connects</span>
          </NavLink>
          {/* <NavLink className="fa-center gap-4 pt-2 pb-3">
            <img src={icons.checkoutIcon} alt="icon" />
            <span className="text-12-400 color-ffff">Events</span>
          </NavLink>
          <NavLink className="fa-center gap-4 pt-2 pb-3">
            <img src={icons.usersIcon} alt="icon" />
            <span className="text-12-400 color-ffff">Email signature</span>
          </NavLink> */}
          <NavLink
            className="fa-center gap-4 pt-2 pb-3 un"
            to={commonRoute.analytics}
            onClick={() => {
              logPageClickEvent("analytics page");
            }}
          >
            <img src={icons.chartIcon} alt="icon" />
            <span className="text-12-400 color-ffff">Analytics</span>
          </NavLink>
          <NavLink
            className="fa-center gap-4 pt-2 pb-3 un"
            to={commonRoute.greetings}
            onClick={() => {
              logPageClickEvent("social greetings page");
            }}
          >
            <img src={icons.usersIcon} alt="icon" />
            <span className="text-12-400 color-ffff">Social Greetings</span>
          </NavLink>
          <NavLink
            className="fa-center gap-4 pt-2 pb-3 un"
            to={commonRoute.discoverAll}
            onClick={() => {
              logPageClickEvent("discover page");
            }}
          >
            <img src={icons.usersIcon} alt="icon" />
            <span className="text-12-400 color-ffff">Discover</span>
          </NavLink>
        </div>
      </div>
      <div
        className="advertise-img un pointer"
        onClick={() => {
          downloadURI("https://mygrid.club/nfc");
        }}
      >
        <img src={advertiseImg} alt="advertise" />
      </div>
    </div>
  );
};

export default SideBar;
