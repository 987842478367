import Analytics from "pages/Auth/Analytics";
import Dashboard from "pages/Auth/Dashboard";
import Greetings from "pages/Auth/Greetings";
import MyConnects from "pages/Auth/MyConnects";
import Profile from "pages/Auth/Profile";
import DiscoverAll from "pages/Auth/DiscoverAll";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { commonRoute } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDataFromLocalStorage } from "utils/helpers";
import { getUserData } from "store/slices";
import PaymentSuccessModal from "components/layouts/PaymentSuccessModal";
import PrimeMemberShip from "components/layouts/PrimeMemberShip";
const AppRoute = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => ({
    userData: state.profile.userData,
  }));
  const globalRDX = useSelector((state) => state.global);
  const { primeMemberModal, primeMemberJoin } = globalRDX || {};
  const fetchData = async () => {

    if (!userData?.id && getDataFromLocalStorage("userId")) {
      dispatch(getUserData())
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])
  return (
    // <SocketProvider>
    <BrowserRouter>
      <Routes>
        <Route exact path={commonRoute.dashboard} element={<Dashboard />} />
        <Route exact path={commonRoute.myConnects} element={<MyConnects />} />
        <Route exact path={commonRoute.analytics} element={<Analytics />} />
        <Route exact path={commonRoute.profile} element={<Profile />} />
        <Route exact path={commonRoute.discoverAll} element={<DiscoverAll />} />
        <Route exact path={commonRoute.greetings} element={<Greetings />} />
        <Route path="*" element={<Navigate replace to="/dashboard" />} />
      </Routes>
      {primeMemberJoin && (
        <PaymentSuccessModal />
      )}
      {primeMemberModal && (
        <PrimeMemberShip />
      )}
    </BrowserRouter>
    // </SocketProvider>
  );
};

export default AppRoute;
