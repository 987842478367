import React, { useEffect } from "react";
import Modal from "../Modal";
import goldCoinImg from "assets/images/common/gold-coin.png";
import Confiette from "assets/images/icons/confiette.json";
import { Player } from "@lottiefiles/react-lottie-player";
import "./GridCoinSent.scss";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { storeUserData } from "store/slices";
import CoinAnimation from "assets/images/icons/Gridcoin.json";
const GridCoinSent = ({ onHide, coins, isModal, onClick, isConnect }) => {
  const dispatch = useDispatch();
  const profileRDX = useSelector((state) => state.profile);
  const { userData } = profileRDX || {};
  useEffect(() => {
    dispatch(
      storeUserData({
        ...userData,
        availableCoins: parseInt(userData.availableCoins) + coins,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal fullscreen={true} onHide={onHide}>
      {isConnect ? (
          <Player
            autoplay
            loop
            src={CoinAnimation}
            style={{ height: "800px", width: "500px" }}
            className="coin-image"
          />):(
        <div id="gridcoinsent-id">
          <div className="fc-center mt-5">
            <div className="color-ffff text-53-750">Succsessfully sent</div>
          </div>
          <div className="fc-center mt-3">
            <div className="color-grey-350 text-25-600">
              Your networking skills are improving
            </div>
          </div>
          <div className="fc-center mt-3">
            <div className="color-ffff text-33-700">Your have earned!</div>
          </div>
          <div className="fc-center mt-3 image-animation-relative">
            <div className="color-ffff text-100-900 ">
              <CountUp start={0} end={coins} duration={5} />
            </div>
            {isModal && (
              <Player
                autoplay
                loop
                src={Confiette}
                style={{ height: "500px", width: "500px" }}
                className="image-animation-absolute"
              />
            )}
          </div>
          <div className="fc-center mt-3">
            <div className="color-ffff text-33-700">GRID COINS</div>
          </div>
          <div className="fc-center mt-3">
            <div className="color-grey-350 text-21-600">
              Your total coin are
            </div>
          </div>
          <div className="fc-center mt-4">
            <span className="gold-coin-block m-0 px-4 py-3">
              <img src={goldCoinImg} alt="coin" height={28} width={28} />
              <span className="text-22-700 color-ffff">
                {userData?.availableCoins}
              </span>
            </span>
          </div>
          <div className="fc-center mt-5">
            <span className="primary-bg-block pointer" onClick={onClick}>
              <span className="text-20-400 color-ffff px-5">GOT IT!</span>
            </span>
          </div>
        </div>
      )
    }
      
    </Modal>
  );
};

export default GridCoinSent;
