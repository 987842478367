import React, { useState } from "react";
import "./SetPin.scss";
import adobeImg from "assets/images/common/adobe.png";
import goDaddyImg from "assets/images/common/godaddy.png";
import shopifyImg from "assets/images/common/shopify.png";
import InputOTP from "components/inputs/InputOTP";
import Button from "components/inputs/Button";
import { useNavigate } from "react-router-dom";
import { commonRoute } from "utils/constants";
import { getDataFromLocalStorage } from "utils/helpers";
import { useDispatch } from "react-redux";
import { handleRegisterUser, handleResetPin } from "store/slices";
import toast from "react-hot-toast";
const SetPin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ResetPin, setResetPin] = useState(true);
  const [ResetConfirmPin, setResetConfirmPin] = useState(true);
  const [value, setValue] = useState({
    pin: "",
    confirmPin: "",
  });
  const [pinErr, setPinErr] = useState(false);
  const [confirmPinErr, setConfirmPinErr] = useState(false);
  const handleUserRegister = async (e) => {
    e.preventDefault();
    if (value.pin.length !== 4 && value.confirmPin.length !== 4) {
      setPinErr(true);
      setConfirmPinErr(true);
      return;
    } else if (value.pin.length !== 4) {
      setPinErr(true);
      setConfirmPinErr(false);
      return;
    } else if (value.confirmPin.length !== 4) {
      setPinErr(false);
      setConfirmPinErr(true);
      return;
    } else if (value.pin !== value.confirmPin) {
      setPinErr(false);
      setConfirmPinErr(true);
      return;
    }
    setPinErr(false);
    setConfirmPinErr(false);
    if (!parseInt(getDataFromLocalStorage("IsMobileRegister"))) {
      await registerUser();
    } else {
      await resetUserPin();
    }
  };
  const registerUser = async () => {
    const payload = {
      verifyToken: getDataFromLocalStorage("verifyToken"),
      contact: getDataFromLocalStorage("contact"),
      countryCode: getDataFromLocalStorage("countryCode"),
      pin: value.pin,
      ...getDataFromLocalStorage("registerData"),
    };
    const res = await dispatch(handleRegisterUser(payload));
    if (res.status === 200) {
      navigate(commonRoute.chooseUserName);
    } else {
      toast.dismiss();
      toast.error(res?.data?.[0]?.msg, {
        className: "bg-danger color-ffff",
        icon: "",
      });
      if (res?.data?.[0]?.path === "email")
        return navigate(commonRoute.addDetails);
      if (res?.data?.[0]?.path === "contact") return navigate(commonRoute.home);
    }
  };
  const resetUserPin = async () => {
    const payload = {
      verifyToken: getDataFromLocalStorage("verifyToken"),
      pin: value.pin,
    };
    const res = await dispatch(handleResetPin(payload));
    if (res.status === 200) {
      return navigate(commonRoute.home);
    }
  };
  return (
    <div id="setPin-container">
      <div className="big-round-shadow"></div>
      <div className="medium-round-shadow"></div>
      <div className="small-round-shadow"></div>
      <div className=" row">
        {/* left side */}
        <div className="col-xxl-6 col-7">
          <div className="coupon-group">
            <span className="pink-dot">
              <div className="w-23 h-23 rounded-circle bg-1B3F"></div>
            </span>
            <span className="yellow-dot">
              <div className="w-8 h-8 rounded-circle bg-BC00"></div>
            </span>
            <div className="row">
              <div className="col-6">
                <div className="coupon-1">
                  <img src={adobeImg} alt="coupon-1" />
                </div>
                <div className="coupon-2">
                  <img src={goDaddyImg} alt="coupon-2" />
                </div>
              </div>
              <div className="col-6">
                <div className="coupon-3">
                  <img src={shopifyImg} alt="coupon-3" />
                </div>
              </div>
            </div>
          </div>
          <div className="efficient-tools">
            <h2 className="text-35-500 color-ffff">Super efficient tools</h2>
            <p className="text-35-300 color-C700">
              which makes your productivity super
              <br /> efficient
            </p>
          </div>
        </div>
        <div className="col-xxl-6 col-5">
          <form className="set-pin-block color-ffff">
            <span className="green-dot">
              <div className="w-14 h-14 rounded-circle bg-AE0C"></div>
            </span>
            <span className="yellow-dot2">
              <div className="w-22 h-22 rounded-circle bg-BC00"></div>
            </span>
            <h3 className="text-26-750">Set pin</h3>
            <p className="text-20-400 opacity-75">
              Please set a 4 digit <br />
              security pin to login back
            </p>
            <div className="mt-4">
              <InputOTP
                onSubmit={(val) => {
                  setValue((prev) => {
                    return { ...prev, pin: val };
                  });
                }}
                blockSize={4}
                resetOTP={ResetPin}
                setResetOTP={setResetPin}
                error={pinErr}
                setError={setPinErr}
                uid="pin"
              />
            </div>
            <h3 className="text-26-750 mt-5">Confirm pin</h3>
            <p className="text-20-400 opacity-75">
              Re-enter the pin <br />
              to confirm for security
            </p>
            <div className="my-4">
              <InputOTP
                onSubmit={(val) => {
                  setValue((prev) => {
                    return { ...prev, confirmPin: val };
                  });
                }}
                blockSize={4}
                resetOTP={ResetConfirmPin}
                setResetOTP={setResetConfirmPin}
                error={confirmPinErr}
                setError={setConfirmPinErr}
                uid="cPin"
                autoFocus={false}
              />
            </div>
            <Button
              btnText="Submit"
              btnType="submit"
              btnStyle="NEXT Rounded w-100"
              onClick={handleUserRegister}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPin;
