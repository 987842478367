import { createSlice } from '@reduxjs/toolkit'
import { handelCatch, handelResponse } from './globalSlice';
import { api } from 'services/api';

const initialState = {
    analyticsUserList: [],
    analyticsGraphData: [],
    userListTotalPage: 0
}

const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        resetAnalyticsState: (state) => initialState,
        storeAnalyticsUserList(state, action) {
            state.analyticsUserList = action.payload;
        },
        storeGraphData(state, action) {
            state.analyticsGraphData = action.payload;
        },
        storeTotalPages(state, action) {
            state.userListTotalPage = action.payload;
        }
    }
});

export const { resetAnalyticsState, storeAnalyticsUserList, storeGraphData, storeTotalPages } = analyticsSlice.actions;

export const getAnalyticsUsers = (page) => async (dispatch, getState) => {
    try {
        const res = await api.get(`/analytics?page=${page}`);
        const responseData = await dispatch(handelResponse(res));
        if (responseData?.status === 200) {
            if (page === 1) {
                dispatch(storeAnalyticsUserList(responseData?.data?.data))
                dispatch(storeTotalPages(responseData?.data?.totalPages))
            }
            else {
                dispatch(storeAnalyticsUserList([...getState().analytics.analyticsUserList, ...responseData?.data?.data]))
            };;
        }
        return responseData
    } catch (error) {
        return dispatch(handelCatch(error));
    }
}; export const getGraphData = (query) => async (dispatch, getState) => {
    try {
        const res = await api.get(`/data/analytics?${query}`);

        const responseData = await dispatch(handelResponse(res));
        dispatch(storeGraphData(responseData?.data?.data || []));
        return responseData
    } catch (error) {
        return dispatch(handelCatch(error));
    }
};
export const getTrackLink = (id, linkId) => async (dispatch) => {
    try {
        const res = await api.get(`/users/${id}/track-links/${linkId}`);
        const responseData = await dispatch(handelResponse(res));
        return responseData
    } catch (error) {
        return dispatch(handelCatch(error));
    }
};

export default analyticsSlice.reducer