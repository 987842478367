import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserData,
  paymentsSent,
  setPrimeCoin,
  setPrimeMemberJoin,
  setPrimeMemberModal,
  storePrimeMebmberShipCoinCount,
  storeUserData,
} from "store/slices";
import { storeLocalStorageData } from "utils/helpers";

const PaymentGetAway = () => {
  const profileRDX = useSelector((state) => state.profile);
  const { userData } = profileRDX || {};
  const { paymentPlanData } = useSelector((state) => ({
    paymentPlanData: state.global.paymentPlanData,
  }));
  const planSeq = ["3", "2", "5"];
  const plan = _.filter(paymentPlanData, (ele) => ele.id !== 1);
  const planSorted = plan.sort((a, b) => {
    return planSeq.indexOf(String(a.id)) - planSeq.indexOf(String(b.id));
  });
  const dispatch = useDispatch();
  const options = {
    key: process.env.REACT_APP_RAZORPAY_KEY,
    amount: "",
    name: "GridWeb Shop",
    description: "some description",
    image: "https://mygrid.club/images/favicons/apple-touch-icon.png",
    handler: (response) => {
      if (response?.razorpay_payment_id) {
        paymentVerify(response?.razorpay_payment_id);
      }
    },
    prefill: {
      name: userData?.name,
      email: userData?.email,
      contact: userData?.contact,
    },
    notes: {
      package_id: "",
      user_id: userData?.id,
    },
    // theme: {
    //   color: "#F37254",
    //   hide_topbar: false,
    // },
  };

  const paymentVerify = async (paymentid) => {
    const res = await dispatch(paymentsSent({ paymentId: paymentid }));
    if (res?.status === 200) {
      dispatch(setPrimeMemberModal(false));
      dispatch(setPrimeMemberJoin(true));
      dispatch(setPrimeCoin(true));
      storeLocalStorageData({ primeDataStore: res?.data?.package });
      dispatch(
        storeUserData({
          ...userData,
          availableCoins: parseInt(userData.availableCoins) + res?.data?.coin,
        })
      );
      dispatch(storePrimeMebmberShipCoinCount(res?.data?.coin));
      await dispatch(getUserData());
    }
  };
  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  const handleButtonClick = (ele) => {
    options.amount = ele?.offerPrice * 100;
    options.notes.package_id = ele?.id;

    openPayModal(options);
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <div className="Payment-getway-id">
      <div className="cmt-50 f-center gap-5">
        {planSorted?.map((ele, index) => {
          const reName = ele.name.replace(/\s*\(Single User\)\s*/, "");
          return (
            <div
              key={index}
              className={`br-23 pointer ${
                ele?.id === 2
                  ? "bg-FF99 cps-50 cpe-50 cpt-36 cpb-36 color-ffff font-lt-pro border-box12"
                  : "bg-grey-900 cps-5 cpe-5 pt-4 pb-4 color-ffff font-lt-pro border-box"
              } `}
              onClick={() => handleButtonClick(ele)}
            >
              <p className="text-20-750 f-center p-1"> {reName}</p>
              <p
                className={`f-center color-ffff line-r${
                  ele?.id === 2 ? "text-26-400" : "text-18-400 "
                }`}
              >
                Rs. {ele?.amount}
                <hr className="line-a f-center" />
              </p>
              <span className="text-20-750 f-center p-1">
                Rs. {ele?.offerPrice}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentGetAway;
