import React, { useEffect, useState } from "react";
import "./EditSocialLinks.scss";
import TextInput from "components/inputs/TextInput";
import { icons } from "utils/constants";
import Modal from "../Modal";
import Button from "components/inputs/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  // deleteLinkData,
  storeUserData,
  updateBusinessCards,
} from "store/slices";
import { capitalize } from "lodash";
import { storeLocalStorageData } from "utils/helpers";
const EditSocialLinks = () => {
  const dispatch = useDispatch();
  const profileRDX = useSelector((state) => state.profile);
  const { userData } = profileRDX || {};
  //list option for add model

  const linkOptions = [
    {
      name: "Add Links",
      iconName: icons.addIcon,
      isSelected: false,
      id: "new",
    },
    {
      name: "Call",
      iconName: icons.callIcon,
      isSelected: false,
      id: "phone",
      defaultValue: "add a link",
      followMe: " Follow me on connect",
    },
    {
      name: "Website",
      iconName: icons.websiteIcon,
      isSelected: false,
      id: "website",
      defaultValue: "www.",
      followMe: " Follow me on Website",
    },
    {
      name: "Email",
      iconName: icons.emailIcon,
      isSelected: false,
      id: "mail",
      defaultValue: "mailto:",
      followMe: " Follow me on mail",
    },
    {
      name: "Whatsapp",
      iconName: icons.whatsAppIcon,
      isSelected: false,
      id: "whatsapp",
      defaultValue: "wa.me/+91",
      followMe: " Follow me on whatsapp",
    },
    {
      name: "Location",
      iconName: icons.locationIcon,
      isSelected: false,
      id: "location",
      defaultValue: "add a link",
      followMe: " Follow me on location",
    },
    {
      name: "Linkedin",
      iconName: icons.linkedInWhiteIcon,
      isSelected: false,
      id: "linkedin",
      defaultValue: "linkedin.com/in/",
      followMe: " Follow me on linkedin",
    },
    {
      name: "Art Station",
      iconName: icons.artStationIcon,
      isSelected: false,
      id: "artstation",
      defaultValue: "add a link",
      followMe: " Follow me on art-station",
    },
    {
      name: "Youtube",
      iconName: icons.youtubeIcon,
      isSelected: false,
      id: "youtube",
      defaultValue: "youtube.com/",
      followMe: " Follow me on youtube",
    },
    {
      name: "Vimeo",
      iconName: icons.vimeoIcon,
      isSelected: false,
      id: "vimeo",
      defaultValue: "vimeo.com/",
      followMe: " Follow me on vimeo",
    },
    {
      name: "Facebook",
      iconName: icons.facebookIcon,
      isSelected: false,
      id: "facebook",
      defaultValue: "facebook.com/",
      followMe: " Follow me on facebook",
    },
    {
      name: "Yelp",
      iconName: icons.yelpIcon,
      isSelected: false,
      id: "yelp",
      defaultValue: "yelp.com/",
      followMe: " Follow me on yelp",
    },
    {
      name: "Twitter",
      iconName: icons.twitterIcon,
      isSelected: false,
      id: "twitter",
      defaultValue: "twitter.com/",
      followMe: " Follow me on twitter",
    },
    {
      name: "instagram",
      iconName: icons.istagramIcon,
      isSelected: false,
      id: "instagram",
      defaultValue: "instagram.com/",
      followMe: " Follow me on instagram",
    },
    {
      name: "Messenger",
      iconName: icons.messangerIcon,
      isSelected: false,
      id: "messenger",
      defaultValue: "add a link",
      followMe: " Follow me on messenger",
    },
    {
      name: "Viber",
      iconName: icons.viberIcon,
      isSelected: false,
      id: "viber",
      defaultValue: "add a link",
      followMe: " Follow me on viber",
    },
    {
      name: "Upi",
      iconName: icons.upiLink,
      isSelected: false,
      id: "upi",
      defaultValue: "add a link",
      followMe: " Follow me on upi",
    },
    {
      name: "PhonPe",
      iconName: icons.PhonePeLink,
      isSelected: false,
      id: "phonpe",
      defaultValue: "add a link",
      followMe: " Follow me on phonpe",
    },
    {
      name: "GPay",
      iconName: icons.GpayLink,
      isSelected: false,
      id: "gpay",
      defaultValue: "add a link",
      followMe: " Follow me on gpay",
    },
    {
      name: "Paytm",
      iconName: icons.PaytmLink,
      isSelected: false,
      id: "paytm",
      defaultValue: "add a link",
      followMe: " Follow me on paytm",
    },
    {
      name: "telegram",
      iconName: icons.TelegramIcon,
      isSelected: false,
      id: "telegram",
      defaultValue: "telegram.me/",
      followMe: " Follow me on telegram",
    },
    {
      name: "skype",
      iconName: icons.Skypeicon,
      isSelected: false,
      id: "skype",
      defaultValue: "add a link",
      followMe: " Follow me on skype",
    },
    {
      name: "behance",
      iconName: icons.Behanceicon,
      isSelected: false,
      id: "behance",
      defaultValue: "behance.net/",
      followMe: " Follow me on behance",
    },
    {
      name: "github",
      iconName: icons.GithubIcon,
      isSelected: false,
      id: "github",
      defaultValue: "add a link",
      followMe: " Follow me on github",
    },
    {
      name: "devianart",
      iconName: icons.DeviantartIcon,
      isSelected: false,
      id: "deviantart",
      defaultValue: "add a link",
      followMe: " Follow me on devianart",
    },
    {
      name: "dribble",
      iconName: icons.DribbbleIcon,
      isSelected: false,
      id: "dribble",
      defaultValue: "add a link",
      followMe: " Follow me on dribble",
    },
    {
      name: "slack",
      iconName: icons.SlackIcon,
      isSelected: false,
      id: "slack",
      defaultValue: "add a link",
      followMe: " Follow me on slack",
    },
    {
      name: "wechat",
      iconName: icons.WeixinIcon,
      isSelected: false,
      id: "wechat",
      defaultValue: "add a link",
      followMe: " Follow me on wechat",
    },
    {
      name: "qq",
      iconName: icons.QqIcon,
      isSelected: false,
      id: "qq",
      defaultValue: "add a link",
      followMe: " Follow me on qq",
    },
    {
      name: "tumblr",
      iconName: icons.TumblrIcon,
      isSelected: false,
      id: "tumblr",
      defaultValue: "tumblr.com/",
      followMe: " Follow me on tumblr",
    },
    {
      name: "qzone",
      iconName: icons.QzoneIcon,
      isSelected: false,
      id: "qzone",
      defaultValue: "add a link",
      followMe: " Follow me on qzone",
    },
    {
      name: "reddit",
      iconName: icons.RedditIcon,
      isSelected: false,
      id: "reddit",
      defaultValue: "add a link",
      followMe: " Follow me on reddit",
    },
    {
      name: "tiktok",
      iconName: icons.TiktokIcon,
      isSelected: false,
      id: "tiktok",
      defaultValue: "tiktok.com/",
      followMe: " Follow me on tiktok",
    },
    {
      name: "baidutieda",
      iconName: icons.BaiduoIcon,
      isSelected: false,
      id: "baidutieda",
      defaultValue: "add a link",
      followMe: " Follow me on baidutieda",
    },
    {
      name: "snapchat",
      iconName: icons.SnapchatIcon,
      isSelected: false,
      id: "snapchat",
      defaultValue: "snapchat.com/add/",
      followMe: " Follow me on snapchat",
    },
    {
      name: "line",
      iconName: icons.LineIcon,
      isSelected: false,
      id: "line",
      defaultValue: "add a link",
      followMe: " Follow me on line",
    },
    {
      name: "medium",
      iconName: icons.MediumIcon,
      isSelected: false,
      id: "medium",
      defaultValue: "add a link",
      followMe: " Follow me on medium",
    },
    {
      name: "signal",
      iconName: icons.SignalMessengerIcon,
      isSelected: false,
      id: "signal",
      defaultValue: "Add a link",
      followMe: " Follow me on signal",
    },
    {
      name: "pinterest",
      iconName: icons.PinterestIcon,
      isSelected: false,
      id: "pinterest",
      defaultValue: "pinterest.com/",
      followMe: " Follow me on pinterest",
    },
    {
      name: "venom",
      iconName: icons.Venom,
      isSelected: false,
      id: "venom",
      defaultValue: "Add a link",
      followMe: " Follow me on venom",
    },
    {
      name: "paypal",
      iconName: icons.PaypalIcon,
      isSelected: false,
      id: "paypal",
      defaultValue: "PayPal.me/",
      followMe: " Follow me on paypal",
    },
    {
      name: "cashapp",
      iconName: icons.CashAppIcon,
      isSelected: false,
      id: "cashapp",
      defaultValue: "cash.app/",
      followMe: " Follow me on cashapp",
    },
    {
      name: "dayflash",
      iconName: icons.DayFlash,
      isSelected: false,
      id: "dayflash",
      defaultValue: "Add a link",
      followMe: " Follow me on dayflash",
    },
    {
      name: "thedots",
      iconName: icons.thedots,
      isSelected: false,
      id: "thedots",
      defaultValue: "Add a link",
      followMe: " Follow me on thedots",
    },
    {
      name: "quora",
      iconName: icons.QuoraIcon,
      isSelected: false,
      id: "quora",
      defaultValue: "quora.com/profile/",
      followMe: " Follow me on quora",
    },
    {
      name: "xbox",
      iconName: icons.XboxIcon,
      isSelected: false,
      id: "xbox",
      defaultValue: "Add a link",
      followMe: " Follow me on xbox",
    },
    {
      name: "soundcloud",
      iconName: icons.SoundCloudIcon,
      isSelected: false,
      id: "soundcloud",
      defaultValue: "soundcloud.com/",
      followMe: " Follow me on soundcloud",
    },
    {
      name: "wikipedia",
      iconName: icons.WikipediaIcon,
      isSelected: false,
      id: "wikipedia",
      defaultValue: "Add a link",
      followMe: " Follow me on wikipedia",
    },
    {
      name: "link",
      iconName: icons.LinkSolidIcon,
      isSelected: false,
      id: "link",
      defaultValue: "Add a link",
      followMe: " Follow me on link",
    },
  ];
  const [linksList, setLinksList] = useState([]); // users saved social links list
  const [selectedLink, setSelectedLink] = useState({});
  const [isAddModal, setIsAddModal] = useState(false);
  const [linksDataSet, setlinksDataSet] = useState([]);
  //for add & edit link details
  const [isAddDetailModal, setIsAddDetailModal] = useState(false);
  const [isNameEditable, setIsNameEditable] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialValue, setInitialValue] = useState({
    name: "",
    url: "",
    notes: "",
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
    url: Yup.array()
      .of(Yup.string().trim().required("URL is required"))
      .min(1, "At least one URL is required"),
    notes: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
  });
  //formate and render linksList
  const refactorLinksList = async () => {
    const { socialLinks } = userData?.businessCard?.contactDetail;
    if (socialLinks) {
      const tempList = [];
      for (const key in socialLinks) {
        if (Object.hasOwnProperty.call(socialLinks, key)) {
          const element = socialLinks[key];
          if (element) {
            const matchIconData = linkOptions.find((elem) => elem.id === key);
            const linkObj = {
              name: key,
              url: element?.data,
              iconName: matchIconData?.iconName,
              notes: element?.heading,
              isDisable: true,
            };
            tempList.push(linkObj);
          }
        }
      }
      setLinksList(tempList);
    }
  };
  useEffect(() => {
    if (userData?.businessCard?.contactDetail?.hasOwnProperty("socialLinks"))
      refactorLinksList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  //for add link modal
  const handleLinkDivClick = (data, index) => {
    setlinksDataSet(data?.id);
    if (index === undefined) setIsNameEditable(true);
    const exitingLinks = linksList.find((val) => val.name === data.id);
    setSelectedLink({ ...data, index });
    setInitialValue({
      name: index !== undefined ? data.id : "",
      url: [data.defaultValue ? data.defaultValue : ""],
      notes: data?.followMe ? data?.followMe : "",
    });
    const datalist = { ...data, isSelected: true };
    linkOptions.splice(index, 1);
    linkOptions.splice(index, 0, datalist);
    setIsAddDetailModal(true);
  };

  const handleModalOpen = () => {
    setIsAddModal(true);
  };
  const handleDetailModalOpen = (i) => {
    setIsEditMode(true);
    setLinksList((prevFieldList) => {
      const updatedFieldList = prevFieldList.map((item, index) => {
        if (index === i) {
          const info = { ...item, isDisable: false };
          setInitialValue({ ...info, index: i });
          return info;
        }
        return item;
      });
      return updatedFieldList;
    });
    setIsAddDetailModal(true);
  };

  const handleDetailModalClose = () => {
    const datalist = { ...selectedLink, isSelected: false };
    linkOptions.splice(selectedLink.index, 1);
    linkOptions.splice(selectedLink.index, 0, datalist);

    setIsAddDetailModal(false);
    setIsAddModal(false);
    if (initialValue.hasOwnProperty("isDisable"))
      setLinksList((prev) => {
        const updatedFieldList = prev.map((item, index) => {
          if (index === initialValue?.index) {
            const updateValues = { ...item, isDisable: true };
            return updateValues;
          }
          return item;
        });
        return updatedFieldList;
      });
    setSelectedLink({});
  };
  const addLinkDetails = (values) => {
    const newLinkData = { ...values, isDisable: true };
    let list = [];
    if (!isEditMode) {
      list = [...linksList, newLinkData];
      setLinksList(list);
    } else {
      const updatedFieldList = linksList.map((item, index) => {
        if (index === values.index) {
          delete newLinkData.index;
          return newLinkData;
        }
        return item;
      });
      list = updatedFieldList;
      setIsEditMode(false);
    }
    submitLinksValue(list);
    handleDetailModalClose();
  };
  const submitLinksValue = async (list) => {
    const socialLinks = {};
    list.forEach((link) => {
      if (socialLinks[link?.name?.toLocaleLowerCase()]) {
        socialLinks[link?.name?.toLocaleLowerCase()].data = [
          ...socialLinks[link?.name?.toLocaleLowerCase()].data,
          ...link.url,
        ];
        socialLinks[link?.name?.toLocaleLowerCase()].heading = link.notes;
      } else {
        socialLinks[link?.name?.toLocaleLowerCase()] = {
          data: link.url,
          heading: link.notes,
        };
      }
    });
    const res = await dispatch(
      updateBusinessCards({ socialLinks: socialLinks })
    );
    if (res.status === 200) storeLocalStorageData({ socialLinks: socialLinks });

    dispatch(storeUserData({ ...userData, businessCard: res }));
  };
  const handleRemoveValues = (i) => {
    const newList = linksList?.filter((_, index) => index !== i);
    submitLinksValue(newList);
  };
  return (
    <div id="edit-social-links">
      <div className="Card-8 my-3">
        <p className="text-16-700 mb-3">Links</p>
        {linksList?.map((ele, i) => {
          return (
            <form className="row my-2" key={i}>
              <div className="col-2 text-16-400 pt-2">
                {capitalize(ele?.name)}
              </div>
              <div className="col-10 fa-center">
                <div className="row w-100 me-2">
                  <div className="col-10 fa-center gap-2">
                    <TextInput value={ele?.notes} disabled={ele?.isDisable} />
                    {ele?.url?.map((val, i) => {
                      return (
                        <React.Fragment key={i}>
                          <TextInput value={val} disabled={ele?.isDisable} />
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="col-1 pt-2">
                    <div className="d-flex gap-4">
                      <div onClick={() => handleDetailModalOpen(i)}>
                        {ele?.isDisable ? (
                          <img
                            src={icons.pencilIcon}
                            alt="edit"
                            className="pointer"
                          />
                        ) : (
                          <img
                            src={icons.blueCheckIcon}
                            alt="edit"
                            className="pointer"
                          />
                        )}
                      </div>
                      <div onClick={() => handleRemoveValues(i)}>
                        <img
                          src={icons.deleteIcon}
                          alt="delete"
                          className="pointer"
                          height={15}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        })}
        <div className="fe-center p-3 pt-4">
          <Button
            btnText="+ Add a Link"
            btnStyle="BLACK-BLUE"
            className="rounded-pill text-17-400 w-184"
            onClick={handleModalOpen}
          />
        </div>
      </div>
      {isAddModal && (
        <Modal
          closeBtn
          title="Add Link"
          onHide={() => setIsAddModal(false)}
          className={`${isAddDetailModal ? "opecity-none" : ""}`}
        >
          <div className="wth-60 mx-auto">
            <div className="link-modal">
              <div className="py-3 row">
                {linkOptions?.map((ele, i) => {
                  return (
                    <div className="col-3">
                      <div
                        onClick={() => {
                          handleLinkDivClick(ele, i);
                        }}
                        className={`link-div ${
                          ele.isSelected ? "link-active" : "bg-grey-700"
                        }`}
                      >
                        <img src={ele.iconName} alt="icon" />
                        <span className="text-12-400 color-ffff">
                          {ele.name}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isAddDetailModal ? (
        <Modal
          closeBtn
          title={`Add Your ${linksDataSet} link`}
          onHide={handleDetailModalClose}
          size="md"
        >
          <div className="w- mx-auto">
            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              onSubmit={addLinkDetails}
              enableReinitialize
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldError,
                  setFieldValue,
                } = props;
                const { name, notes, url } = values;
                const handleInputChange = (e) => {
                  const { value, id } = e.target;
                  if (!touched[id]) {
                    touched[id] = true;
                  }
                  if (value === "") {
                    setFieldError(id, "");
                  }
                  handleChange(e);
                };
                const updateUrlAtIndex = (index, id, event) => {
                  const newURLs = [...url];
                  newURLs[index] = event.target.value;
                  setFieldValue(id, newURLs);
                };
                return (
                  <form className={`value-inputs row`}>
                    <div className={`${isEditMode ? "col-10" : "col-12"}`}>
                      {/* <TextInput
                        placeholder="Link name"
                        id="name"
                        className={`mb-3 br-8 ${
                          touched.name && errors.name ? "b-3030" : ""
                        }`}
                        disabled={!isNameEditable}
                        value={name}
                        onChange={handleInputChange}
                      /> */}
                    </div>
                    <div className="d-flex mb-3">
                      <p className="w-25 color-ffff f-center">
                        {isEditMode ? "Edit Note" : "Add Note "}
                      </p>
                      <TextInput
                        placeholder="Note"
                        id="notes"
                        className={`br-8 ${
                          touched.notes && errors.notes ? "b-3030" : ""
                        }`}
                        value={notes}
                        onChange={handleInputChange}
                      />
                    </div>
                    {url?.map((ele, i) => (
                      <>
                        <div className="mb-3 d-flex">
                          <p className="w-25 color-ffff f-center">
                            {isEditMode ? "Edit Url" : "Add Url"}
                          </p>
                          <TextInput
                            placeholder="URL"
                            id="url"
                            className={`br-8 ${
                              errors?.url?.length >= i && errors?.url[i]
                                ? "b-3030"
                                : ""
                            }`}
                            value={ele}
                            onChange={(e) => updateUrlAtIndex(i, "url", e)}
                          />
                        </div>
                        {url?.length > 1 && (
                          <button
                            className="col-2 mb-3"
                            onClick={(e) => {
                              e.preventDefault();
                              const newURL = [...url];
                              newURL?.splice(i, 1);
                              setInitialValue({ ...values, url: newURL });
                            }}
                          >
                            <img
                              src={icons.deleteIcon}
                              alt="delete"
                              className="pointer"
                            />
                          </button>
                        )}
                      </>
                    ))}

                    <div className="fe-center mt-5 values-listing">
                      <Button
                        btnText="+ Add Detail"
                        btnStyle="BLACK-BLUE"
                        className="rounded-pill text-17-400 w-150"
                        onClick={handleSubmit}
                      />
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default EditSocialLinks;
