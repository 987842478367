import React from "react";
import "./RatingCard.scss";
import StarRatings from "../StarRatings";
import moment from "moment";
import dummyImg from "assets/images/common/dummyProfile.png";
import { capitalize } from "lodash";
import { Blurhash } from "react-blurhash";
const RatingCard = ({ ratingData }) => {
  const profileImg = ratingData?.user?.userIntroduction?.profileMedia
    ? ratingData?.user?.userIntroduction?.profileMedia?.cdn_url +
      "/" +
      ratingData?.user?.userIntroduction?.profileMedia?.thumbnail
    : dummyImg;
  const profileHash = ratingData?.user?.userIntroduction?.profileMedia?.hashed;
  const defaultBlurhash = "LEHV6nWB2yk8pyo0adR*.7kCMdnj";

  const blurhashToUse =
    profileHash && profileHash === 86 ? profileHash : defaultBlurhash;
  return (
    <div id="rating-card-container">
      <div className="fb-center">
        <div className="fa-center gap-2">
          {profileImg ? (
            <img src={profileImg} className="rating-user-img" alt="userImg" />
          ) : (
            <div
              style={{
                borderRadius: "50%",
                overflow: "hidden",
                display: "inline-block",
                height: "50px",
              }}
            >
              <Blurhash
                hash={blurhashToUse}
                width={50}
                height={50}
                punch={1}
                style={{ borderRadius: "50%" }}
              />
            </div>
          )}

          <div>
            <p className="text-14-400 mb-0">
              {capitalize(ratingData?.webName) ||
                capitalize(ratingData?.user?.name)}
            </p>
            <p className="text-8-400 color-A400 mb-0">
              {moment(ratingData?.createdAt).format("DD-MM-YYYY")}
            </p>
            {ratingData?.user ? (
              <p className="text-8-400 color-grey-A7 mb-0">
                {ratingData?.user?.city}, {ratingData?.user?.state},{" "}
                {ratingData?.user?.country}
              </p>
            ) : null}
          </div>
        </div>
        <div className="text-end">
          <StarRatings rating={ratingData?.rating} />
          <p className="text-12-400 color-grey-450">
            {ratingData?.rating} Stars
          </p>
        </div>
      </div>
      <div className="text-13-400 mt-4">{ratingData?.feedback}</div>
    </div>
  );
};

export default RatingCard;
