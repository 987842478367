import React, { useEffect, useState } from "react";
import "./Security.scss";
import { commonRoute, icons } from "utils/constants";
import userImg1 from "assets/images/common/user4.png";
import viewer1 from "assets/images/common/user7.jpg";
import userImg2 from "assets/images/common/user5.png";
import viewer2 from "assets/images/common/user3.png";
import userImg3 from "assets/images/common/user6.png";
import viewer3 from "assets/images/common/user1.png";
import Button from "components/inputs/Button";
import InputOTP from "components/inputs/InputOTP";
import UserViewerCard from "components/layouts/UserViewerCard";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleLogin, sendOtpEvent, sendOtpForVerify } from "store/slices";
import { getDataFromLocalStorage, storeLocalStorageData } from "utils/helpers";
import toast from "react-hot-toast";
const Security = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [IsMobileRegister, setIsMobileRegister] = useState(
    parseInt(getDataFromLocalStorage("IsMobileRegister"))
  );
  const [resetOTP, setResetOTP] = useState(true);
  const [pin, setPin] = useState("");
  const [error, setError] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const viewerData = [
    {
      viewer: "Sachin gupta",
      date: "15-06-2021",
      viewerImg: viewer1,
      userImg: userImg1,
      count: "1856",
    },
    {
      viewer: "Sachin gupta",
      date: "15-06-2021",
      viewerImg: viewer2,
      userImg: userImg2,
      count: "1856",
    },
    {
      viewer: "Sachin gupta",
      date: "15-06-2021",
      viewerImg: viewer3,
      userImg: userImg3,
      count: "1856",
    },
  ];
  useEffect(() => {
    if (!IsMobileRegister) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  const handleUserLogin = async (e) => {
    e.preventDefault();
    if (pin.length !== 4) return setError(true);
    setError(false);
    const payload = {
      contact: getDataFromLocalStorage("contact"),
      country_code: getDataFromLocalStorage("countryCode"),
      pin: pin,
    };
    const res = await dispatch(handleLogin(payload));
    if (res.status === 200 && res?.data?.user?.id) {
      navigate(commonRoute.dashboard);
    } else if (res.status === 200 && res?.data?.user === null) {
      switch (res?.data?.step) {
        case "identifier":
          navigate(commonRoute.chooseUserName);
          break;
        case "detail":
          navigate(commonRoute.addDescription);
          break;
        case "links":
          navigate(commonRoute.addLinks);
          break;

        default:
          break;
      }
    } else {
      setError(true);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (pin.length !== 4) return setError(true);
    setError(false);
    const payload = {
      contact: getDataFromLocalStorage("contact"),
      country_code: getDataFromLocalStorage("countryCode"),
      code: pin,
    };
    const res = await dispatch(sendOtpForVerify(payload));
    if (res.status === 200 && getDataFromLocalStorage("isForgotPin")) {
      navigate(commonRoute.setPin);
      storeLocalStorageData({ IsMobileRegister: "1" });
    } else if (
      res.status === 200 &&
      !IsMobileRegister &&
      !getDataFromLocalStorage("isForgotPin")
    ) {
      navigate(commonRoute.addDetails);
    } else {
      setError(true);
    }
  };

  const handleResendOTP = async () => {
    setResetOTP(true);
    setError(false);
    const res = await dispatch(
      sendOtpEvent({
        contact: getDataFromLocalStorage("contact"),
        country_code: getDataFromLocalStorage("countryCode"),
      })
    );
    if (res.status === 200) {
      toast.dismiss();
      toast.success(res?.data?.message);
      setSeconds(60);
    }
  };
  const handleForgotPinRequest = () => {
    storeLocalStorageData({ isForgotPin: "0", IsMobileRegister: "0" });
    handleResendOTP();
    setIsMobileRegister(0);
  };
  return (
    <>
      <div id="security-container">
        <div className="row">
          {/* left side */}
          <div className="col-xxl-6 col-7">
            <div className="backRound-shadow">
              <span className="pink-dot">
                <div className="w-30 h-30 rounded-circle bg-1B3F"></div>
              </span>
              <span className="yellow-dot">
                <div className="w-11 h-11 rounded-circle bg-BC00"></div>
              </span>
              <div className="big-round-shadow"></div>
              <div className="medium-round-shadow"></div>
              <div className="small-round-shadow"></div>
              <div className="user-data-1">
                <UserViewerCard userData={viewerData[0]} />
              </div>
              <div className="user-data-2">
                <UserViewerCard userData={viewerData[1]} />
              </div>
              <div className="user-data-3">
                <UserViewerCard userData={viewerData[2]} />
              </div>
            </div>
            <div className="deep-analytics mt-5 ps-5 relative">
              <h2 className="text-35-500 color-ffff">Deep analytics</h2>
              <p className="text-35-300 color-C700">
                to measure the performance <br />
                of your profile link
              </p>
            </div>
          </div>
          {/* right side */}
          <div className="col-xxl-6 col-5 my-4">
            <div className="security-form">
              <span className="green-dot">
                <div className="w-11 h-11 rounded-circle bg-AE0C"></div>
              </span>
              <span className="yellow-dot2">
                <div className="w-16 h-16 rounded-circle bg-BC00"></div>
              </span>
              <div className="form-details">
                <img src={icons.logoImg} alt="logo" className="logo-img" />
                {parseInt(getDataFromLocalStorage("IsMobileRegister")) ? (
                  <>
                    <div className="mt-5 color-ffff">
                      <h3 className="text-26-750 ">Security Check</h3>
                      <p className="text-23-400 opacity-75">
                        please verify yourself
                      </p>
                      <p className="text-17-400 color-grey-500">
                        Enter your 4 digit pin
                      </p>
                    </div>

                    <form onSubmit={handleUserLogin}>
                      <div className="mt-3">
                        <InputOTP
                          onSubmit={(val) => {
                            setPin(val);
                          }}
                          blockSize={4}
                          resetOTP={resetOTP}
                          setResetOTP={setResetOTP}
                          error={error}
                          setError={setError}
                        />
                      </div>

                      {/* <div className="fb-center my-5"> */}
                      <button
                        type="reset"
                        className="text-14-400 color-5083 my-5"
                        onClick={handleForgotPinRequest}
                      >
                        Forgot pin
                      </button>
                      <Button
                        btnText="Next"
                        className="NEXT w-100"
                        btnType="submit"
                        rightIcon={icons.nextIcon}
                      />
                    </form>
                  </>
                ) : (
                  <>
                    <div className="mt-5 color-ffff">
                      <h3 className="text-26-750 ">Security</h3>
                      <p className="text-23-400 opacity-75">
                        please verify yourself
                      </p>
                      <p className="text-17-400 color-grey-500">
                        Enter the OTP you received on your <br />
                        entered mobile number
                      </p>
                    </div>
                    <form onSubmit={handleVerifyOtp}>
                      <div className="mt-3">
                        <InputOTP
                          onSubmit={(val) => {
                            setPin(val);
                          }}
                          blockSize={4}
                          resetOTP={resetOTP}
                          setResetOTP={setResetOTP}
                          error={error}
                          setError={setError}
                        />
                      </div>

                      <div className="fb-center my-5">
                        <button
                          className={`text-14-400  pointer ${
                            seconds !== 0 ? "color-7278" : "color-5083"
                          }`}
                          onClick={handleResendOTP}
                          disabled={seconds !== 0}
                        >
                          Resend OTP
                        </button>
                        {seconds === 0 ? null : (
                          <div className="text-17-400 color-grey-450">
                            {seconds}
                          </div>
                        )}
                      </div>
                      <Button
                        btnText="Next"
                        className="NEXT w-100"
                        btnType="submit"
                        rightIcon={icons.nextIcon}
                      />
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
