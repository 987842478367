import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBJc6RbMEODQLvanAoX-xLoJ4JEDsMt2_0",
    authDomain: "gridweb-test.firebaseapp.com",
    projectId: "gridweb-test",
    storageBucket: "gridweb-test.appspot.com",
    messagingSenderId: "443749166693",
    appId: "1:443749166693:web:41e5a5dfaaa0d63ec2608c",
    measurementId: "G-2WMMCY9SK1",
};
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const logPageClickEvent = (pageName) => {
    logEvent(analytics, `page_view`, { page_title: pageName });
};
export const logPrimeButtonClickEvent = (page) => {
    logEvent(analytics, "prime_button", {
        from_page: page,
    });
};