import Layout from "pages/Layout/Layout";
import { commonRoute, icons } from "utils/constants";
import "./Dashboard.scss";
import React, { useEffect, useState } from "react";
import TrendingSlider from "components/layouts/TrendingSlider";
// for slider
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import ConnectUserCard from "components/layouts/ConnectUserCard";
import DashboardLineChart from "components/layouts/DashboardLineChart";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardData,
  getDiscoverList,
  getPromotionBanner,
} from "store/slices";
import { NavLink } from "react-router-dom";
import UnreadMessageUsersList from "./UnreadMessageUsersList";
import { viewUserDetails } from "store/slices/connectSlice";
import { useCallback } from "react";
import ViewUserProfile from "components/layouts/ViewUserProfile";
// import { totalChartCount } from "utils/helpers";
// import { Player } from "@lottiefiles/react-lottie-player";
// import Dropdown from "components/inputs/Dropdown";
// import { FormSelect } from "react-bootstrap";
// import moment from "moment/moment";
// import CoinAnimation from '../../../assets/images/icons/Gridcoin.json'

const Dashboard = () => {
  const dispatch = useDispatch();
  const [isProfileView, setIsProfileView] = useState(false);
  const [isButtonShow, setIsButtonShow] = useState(false);

  const { discoverList } = useSelector((state) => ({
    discoverList: state.common.discoverList,
  }));
  const profileRDX = useSelector((state) => state.profile);
  const { bannaerList } = profileRDX || {};
  const fetchData = async () => {
    await dispatch(getDashboardData());
    await dispatch(getDiscoverList("page=1"));
    await dispatch(getPromotionBanner());
  };
  const handleViewProfile = useCallback((data) => {
    dispatch(viewUserDetails(data?.id));
    setIsProfileView(true);
    if (data?.connect_status === 4) {
      setIsButtonShow(true);
    } else {
      setIsButtonShow(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isUnreadMsg, SetIsUnreadMsg] = useState(false);
  return (
    <>
      <Layout
        id="dashboard-container"
        text="You are getting noticed!"
        isNotification={true}
      >
        <div className="manage-scroll mt-3">
          <div className="row mb-3">
            <div className="col-6">
              <div className="user-profile">
                <div className="fa-center gap-2">
                  <img src={icons.startFillIcon} alt="icon" width={17} />
                  <span className="text-15-400 font-Avenir">
                    You are getting noticed!
                  </span>
                </div>
                <div className="Card-5 mt-2 flex-grow-1 cmb-20">
                  <div className="profile-view flex-grow-1 pb-2">
                    <DashboardLineChart />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="fa-center gap-2">
                <img src={icons.flashIcon} alt="icon" width={24} />
                <span className="text-15-400 font-Avenir">trending</span>
              </div>
              <TrendingSlider data={bannaerList} />
            </div>
          </div>

          <div className="d-flex">
            <div className={`${!isUnreadMsg ? "w-100" : "wth-60"} px-2 `}>
              {/* className={`${!isUnreadMsg ? "w-100" : "wth-60"} fb-center  mb-3 `} */}
              <div
                className={`${
                  !isUnreadMsg ? "w-100" : "wth-50"
                } fb-center  mb-3 `}
              >
                <div className="fa-center gap-2">
                  <img src={icons.startFillIcon} alt="icon" width={17} />
                  <span className="text-15-400 font-Avenir">
                    Get rewarded on every network on establish
                  </span>
                </div>
                <NavLink
                  to={commonRoute.discoverAll}
                  className="bb-9EF6 color-9EF6 text-18-400 mt-2"
                >
                  Explore all
                </NavLink>
              </div>
              <div
                className={`connect-group-${!isUnreadMsg ? "1595" : "1024"}`}
              >
                <Swiper
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Navigation]}
                  slidesPerView={!isUnreadMsg ? 5 : 3}
                  spaceBetween={20}
                  className="mySwiper"
                  initialSlide={!isUnreadMsg ? 2 : 1}
                >
                  {discoverList?.map((data) => {
                    return (
                      <SwiperSlide key={data?.id}>
                        <ConnectUserCard
                          data={data}
                          setIsProfileView={setIsProfileView}
                          handleViewProfile={handleViewProfile}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            {/* unread messages */}
            <UnreadMessageUsersList
              SetIsUnreadMsg={SetIsUnreadMsg}
              isUnreadMsg={isUnreadMsg}
            />
          </div>
        </div>

        <div
          className={`discover-slider ${isProfileView ? "active" : ""}`}
          onClick={() => {
            setIsProfileView(false);
          }}
        >
          <div
            className={`discover-card-bg ${isProfileView ? "active" : ""}`}
            onClick={(e) => e.stopPropagation()}
          >
            <ViewUserProfile
              setIsProfileView={setIsProfileView}
              isButtonShow={isButtonShow}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;
