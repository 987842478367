import React, { useState } from "react";
import "./ViewUserProfile.scss";
import dummyBanner from "assets/images/common/dummyBanner.jpg";
import { icons } from "utils/constants";
import UserFaceImg from "assets/images/common/dummyProfile.png";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "lodash";
import {
  getUserRatingList,
  sendRatings,
  storeViewUserData,
} from "store/slices/connectSlice";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import {
  convertDescription,
  downloadURI,
  getDataFromLocalStorage,
} from "utils/helpers";
import { useSocket } from "context/socket";
import RatingCard from "../RatingCard";
import { useRef } from "react";
import Button from "components/inputs/Button";
import { Formik } from "formik";
import TextInput from "components/inputs/TextInput";
import TextArea from "components/inputs/TextArea";
import StarRatings from "../StarRatings";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import dummyBannerImg from "assets/images/common/dummyBanner.jpg";
import { Navigation } from "swiper/modules";
import * as Yup from "yup";
import { getTrackLink, sendEnquiry } from "store/slices";
import SocialLinkButton from "../SocialLinknButton/SocialLinkButton";
import RatingStar from "../RatingStar";
import toast from "react-hot-toast";
import Modal from "../Modal";
import CopyContactDetailsModal from "../CopyContactDetailsModal/CopyContactDetailsModal";

const ViewUserProfile = ({ setIsProfileView, isButtonShow }) => {
  const socketContext = useSocket();
  const { socket } = socketContext.state;
  const dispatch = useDispatch();
  const formikRef = useRef();

  const connectRDX = useSelector((state) => state.connect);
  const { viewUserData, isViewDataLoading, userRatingList, userCountRating } =
    connectRDX || {};
  // eslint-disable-next-line
  const { userData } = useSelector((state) => ({
    userData: state.profile.userData,
  }));
  const [intervalId, setIntervalId] = useState(null);
  const [isDetailModal, setIsDetailModal] = useState(false);
  const [contactDetails, setContactDetails] = useState([]);
  const [showEnquiryModel, setShowEnquiryModel] = useState(false);

  const [initialValues, setInitialValues] = useState({
    field1: userData?.name || "",
    field2: userData?.contact || "",
    field3: "",
  });

  useEffect(() => {
    setInitialValues({
      field1: userData?.name,
      field2: userData?.contact,
      field3: "",
    });
  }, [userData]);
  useEffect(() => {
    dispatch(getUserRatingList(viewUserData?.user?.businessCard?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewUserData?.user?.businessCard?.id]);
  const handleClose = () => {
    dispatch(storeViewUserData({}));
    setIsProfileView(false);
    if (intervalId) {
      clearInterval(intervalId);
    }
  };
  const swiperRef = useRef(null);

  const goPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const validationSchema = Yup.object().shape({
    field1: Yup.string()
      .transform((value) => value.trim())
      .matches(/^[a-zA-Z\s]+$/, "Number or Special character  is not allowed.")
      .required("Name is required."),
    field2: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
    field3: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
  });
  const handleSubmitForm = async (values) => {
    const payload = {
      ...values,
      userId: getDataFromLocalStorage("userId"),
      is_web: 1,
    };
    const res = await dispatch(sendEnquiry(payload));
    if (res?.status === 200) {
      formikRef.current.resetForm();
      // handleClose()
    }
  };
  const ShowContactDetail = async (key) => {
    const res = await dispatch(
      getTrackLink(
        viewUserData?.user?.id,
        viewUserData?.user?.businessCard?.contactDetail?.socialLinks?.[key]?.id
      )
    );
    if (res.status === 200) {
      setContactDetails(res?.data?.[key]?.data);
      if (key === "phone") setIsDetailModal(true);
      else {
        const formattedLink = res?.data?.[key]?.data?.[0]?.includes("https://")
          ? res?.data?.[key]?.data?.[0]
          : `https://${res?.data?.[key]?.data?.[0]}`;
        downloadURI(formattedLink);
      }
    }
  };
  useEffect(() => {
    if (viewUserData?.user?.id) {
      const id = setInterval(() => {
        if (socket) {
          socket.emit(
            "spent-time",
            JSON.stringify({ forUserId: viewUserData?.user?.id })
          );
        }
      }, 10000);
      setIntervalId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewUserData]);

  const [rating, setRating] = useState({ star: 0, feedback: "" });
  const handleSubmitRating = async () => {
    const res = await dispatch(
      sendRatings(
        { rating: rating?.star, feedback: rating?.feedback },
        viewUserData?.user?.businessCard?.id
      )
    );
    if (res?.status === 200) {
      toast.success("Thank you for your rating");
      setRating({ star: 0, feedback: "" });
      setIsProfileView(false);
    }
  };

  // const acceptConnectRequest = (catgory) => {
  // const payload = {
  //   category: catgory,
  //   connectId: requestedUserSelect?.connect?.id
  // };
  // const res = await dispatch(sendAcceptRequest(payload));
  // if (res.status === 200) {
  //   setShowCtgModal(false);
  //   SetIsConnect(true);
  //   setShowCoinAnimation(true);
  //   setIsCount(res?.data?.coins || 0);
  //   setConnectedUser(requestedUserSelect);
  //   setTab(1);
  //   handleRequestedUserSelect({});
  // }
  // };
  return (
    <React.Fragment>
      {/* <div className="col-6 d-flex flex-column h-100 overflow-y-auto slider"> */}
      {isViewDataLoading ? (
        <div className="fc-center h-100">
          {" "}
          <Spinner animation="grow" variant="secondary" />{" "}
        </div>
      ) : (
        <div className="left-sidebar">
          <button onClick={handleClose} className="close-btn">
            <img src={icons.crossIcon} alt="close" />
          </button>
          <div className="bg-shadow-wrap">
            <img
              className="user-bg-image"
              src={
                viewUserData?.user?.userIntroduction?.profileMedia
                  ? `${viewUserData?.user?.userIntroduction?.profileMedia?.cdn_url}/${viewUserData?.user?.userIntroduction?.profileMedia?.path}`
                  : dummyBanner
              }
              alt=""
            />
          </div>
          <div className="user-service-content">
            <div className="service-content-top">
              <div className="fc-center flex-column">
                <div className="rounded-circle position-relative">
                  {viewUserData?.user?.prime ? (
                    <img src={icons.primeumRing} alt="ring" />
                  ) : (
                    <img src={icons.photoRingIcon} alt="ring" />
                  )}
                  <img
                    src={
                      viewUserData?.user?.userIntroduction?.coverMedia
                        ? `${viewUserData?.user?.userIntroduction?.coverMedia?.cdn_url}/${viewUserData?.user?.userIntroduction?.coverMedia?.thumbnail}`
                        : UserFaceImg
                    }
                    alt="profile"
                    className="left-profile-img"
                  />
                </div>
                <p className="text-27-600 ">
                  {capitalize(viewUserData?.user?.name)}
                </p>
                <p className="text-14-400 color-grey-250 mb-0">
                  {capitalize(viewUserData?.user?.userIntroduction?.headline)}
                </p>
                <p className="text-14-400 color-grey-A6 mb-0">
                  {capitalize(viewUserData?.user?.city)},{" "}
                  {capitalize(viewUserData?.user?.state)},{" "}
                  {capitalize(viewUserData?.user?.country)}
                </p>
                {viewUserData?.user?.recentConnects?.length > 10 ? (
                  <>
                    <div className="fc-center mt-3">
                      <div className="connected-profile-group">
                        {viewUserData?.user?.recentConnects?.map((ele, i) => (
                          <span
                            className={`connected-profile-img${i + 1}`}
                            key={i}
                          >
                            <img
                              src={`${ele.cdn_url}/${ele.thumbnail}`}
                              alt="user-img"
                            />
                          </span>
                        ))}
                      </div>
                      <p className="text-28-600 mb-0 mx-2">
                        {viewUserData?.user?.recentConnects?.length}
                      </p>
                    </div>
                    <p className="text-15-400 color-grey-A6">connections</p>
                  </>
                ) : null}
              </div>
              <SocialLinkButton
                isButtonShow={isButtonShow}
                ShowContactDetail={ShowContactDetail}
                userData={viewUserData?.user}
                bannerf={false}
                isshow={true}
              />
              {isDetailModal && (
                <CopyContactDetailsModal
                  data={contactDetails}
                  onClose={() => {
                    setIsDetailModal(false);
                    setContactDetails([]);
                  }}
                />
              )}
              {viewUserData?.user?.services?.length > 0 && (
                <p className="text-17-700">What we offer</p>
              )}
              {viewUserData?.user?.prime &&
                viewUserData?.user?.services &&
                viewUserData?.user?.services?.map((ele, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="offer-img my-4">
                        <img
                          src={
                            ele?.media
                              ? ele?.media?.cdn_url + "/" + ele?.media?.path
                              : dummyBannerImg
                          }
                          alt="Banner"
                        />
                      </div>
                      <div className="fb-center my-2">
                        <div>
                          <p className="mb-0 text-21-600">{ele?.title}</p>
                          {ele?.price && (
                            <p className="text-18-600 color-grey-B8">
                              {ele?.price}
                            </p>
                          )}
                        </div>
                        {ele?.enquireButton === 1 && (
                          <Button
                            onClick={() => setShowEnquiryModel(true)}
                            btnText="Enquiry"
                            btnStyle="Rounded"
                            className="bg-76E3 text-17-400 cps-24 cpe-24 cpt-8 cpb-8 "
                          />
                        )}
                      </div>
                      <p
                        className="my-3 text-14-400 color-grey-97 "
                        dangerouslySetInnerHTML={{
                          __html: `${convertDescription(ele?.desc)}`,
                        }}
                      ></p>
                      {i < viewUserData?.user?.services?.length - 1 ? (
                        <div className="bb-grey-7878 w-100"></div>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              {/*------------------ offer details end --------------------*/}
              {/*-------------------- ask question form -----------------*/}
              <div className="bg-2531 br-11 cp-15">
                <p className="text-21-600">Have a question?</p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmitForm}
                  enableReinitialize
                  innerRef={formikRef}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                      setFieldError,
                    } = props;
                    const { field1, field2, field3 } = values;
                    const handleInputChange = (e) => {
                      const { value, id } = e.target;
                      if (!touched[id]) {
                        touched[id] = true;
                      }
                      if (value === "") {
                        setFieldError(id, "");
                      }
                      handleChange(e);
                    };
                    return (
                      <form>
                        <TextInput
                          placeholder="Your name"
                          id="field1"
                          className={`mb-3 text-14-400  br-11 bg-1016 ${
                            touched.field1 && errors.field1 ? "b-3030" : ""
                          }`}
                          value={field1}
                          onChange={handleInputChange}
                        />
                        <TextInput
                          placeholder="Mobile number"
                          id="field2"
                          className={`mb-3 text-14-400  br-11 bg-1016 ${
                            touched.field2 && errors.field2 ? "b-3030" : ""
                          }`}
                          value={field2}
                          onChange={handleInputChange}
                        />
                        <TextArea
                          placeholder="Message"
                          rows={8}
                          id="field3"
                          className={`mb-3 text-14-400  br-11 bg-1016 ${
                            touched.field3 && errors.field3 ? "b-3030" : ""
                          }`}
                          value={field3}
                          onChange={handleInputChange}
                        />
                        <div className="fe-center mt-3">
                          <Button
                            btnType="submit"
                            onClick={handleSubmit}
                            btnText="Send"
                            btnStyle="Rounded"
                            className="bg-76E3 text-17-400 send-btn"
                            rightIcon={icons.sendIcon}
                          />
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
              {/*-------------------- ask question form end -----------------*/}

              {/*-----------------rating slider--------------- */}
              <div className="bg-2531 br-11 cp-15 mb-3 mt-5">
                <div className="fb-center flex-nowrap">
                  <p className="text-18-400">Your ratings</p>
                  <StarRatings
                    rating={viewUserData?.user?.businessCard?.ratings?.avg || 0}
                    width={24}
                    height={24}
                  />
                </div>
                <p className="text-14-400 color-grey-96">
                  {userCountRating
                    ? `${userCountRating} people
                    rated you`
                    : "Not rated yet"}
                </p>
                <p className="text-center text-13-400 color-grey-96 mt-3">
                  {" "}
                  Rate your experiance working with {viewUserData?.user?.name}
                </p>
                <div className="d-flex justify-content-center mt-2 mb-3">
                  {[1, 2, 3, 4, 5].map((starIndex) => (
                    <span
                      key={starIndex}
                      onClick={() =>
                        setRating((prev) => ({ ...prev, star: starIndex }))
                      }
                      className="pointer mx-2"
                    >
                      {starIndex <= rating?.star ? (
                        <RatingStar color="#FFC700" width={25} height={25} />
                      ) : (
                        <RatingStar color="#b5b5b5" width={25} height={25} />
                      )}
                    </span>
                  ))}
                </div>
                <TextArea
                  placeholder="Feedback"
                  rows={3}
                  id="feedback"
                  className={`mb-3 text-14-400  br-11 bg-1016 `}
                  value={rating?.feedback}
                  onChange={(e) =>
                    setRating((prev) => ({ ...prev, feedback: e.target.value }))
                  }
                />
                <div className="fe-center mt-3">
                  <Button
                    btnType="submit"
                    onClick={handleSubmitRating}
                    btnText="Submit Rating"
                    btnStyle="Rounded"
                    className="bg-76E3 text-17-400 send-btn"
                    disabled={rating?.star === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="Rating-slider mt-3">
                <Swiper
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  modules={[Navigation]}
                  spaceBetween={20}
                  className="mySwiper"
                  ref={swiperRef}
                  clickedindex={1}
                >
                  {userRatingList?.map((ele, i) => (
                    <SwiperSlide key={i}>
                      <RatingCard ratingData={ele} />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="fb-center my-2">
                  {userRatingList.length ? (
                    <>
                      <button
                        className="pointer color-A6F6 text-11-400"
                        onClick={goPrev}
                      >
                        Previous
                      </button>
                      <button
                        className="pointer color-A6F6 text-11-400"
                        onClick={goNext}
                      >
                        Next
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showEnquiryModel && (
        <Modal
          onHide={() => setShowEnquiryModel(false)}
          closeBtn
          title="Enquiry About!"
        >
          <div className="cp-15">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
              enableReinitialize
              innerRef={formikRef}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldError,
                } = props;
                const { field1, field2, field3 } = values;
                const handleInputChange = (e) => {
                  const { value, id } = e.target;
                  if (!touched[id]) {
                    touched[id] = true;
                  }
                  if (value === "") {
                    setFieldError(id, "");
                  }
                  handleChange(e);
                };
                return (
                  <form>
                    <TextInput
                      placeholder="Your name"
                      id="field1"
                      className={`mb-3 text-14-400  br-11 bg-1016 ${
                        touched.field1 && errors.field1 ? "b-3030" : ""
                      }`}
                      value={field1}
                      onChange={handleInputChange}
                    />
                    <TextInput
                      placeholder="Mobile number"
                      id="field2"
                      className={`mb-3 text-14-400  br-11 bg-1016 ${
                        touched.field2 && errors.field2 ? "b-3030" : ""
                      }`}
                      value={field2}
                      onChange={handleInputChange}
                    />
                    <TextArea
                      placeholder="Message"
                      rows={8}
                      id="field3"
                      className={`mb-3 text-14-400  br-11 bg-1016 ${
                        touched.field3 && errors.field3 ? "b-3030" : ""
                      }`}
                      value={field3}
                      onChange={handleInputChange}
                    />
                    <div className="fe-center mt-3">
                      <Button
                        btnType="submit"
                        onClick={handleSubmit}
                        btnText="Send"
                        btnStyle="Rounded"
                        className="bg-76E3 text-17-400 send-btn"
                        rightIcon={icons.sendIcon}
                      />
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Modal>
      )}
      {/* </div> */}
    </React.Fragment>
  );
};

export default ViewUserProfile;
