import Layout from "pages/Layout";
import React, { useEffect, useRef } from "react";
import "./Analytics.scss";
import { icons } from "utils/constants";
import UserContactCard from "components/layouts/UserContactCard";
import DashboardLineChart from "components/layouts/DashboardLineChart";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsUsers } from "store/slices";
import { useSocket } from "context/socket";
import { handleSocketLister } from "store/slices/connectSlice";
const Analytics = () => {
  const dispatch = useDispatch();
  const profileRDX = useSelector((state) => state.profile);
  const { userData } = profileRDX;

  const socketContext = useSocket();
  const { socket } = socketContext.state;
  const analyticsRDX = useSelector((state) => state.analytics);
  const { analyticsUserList, userListTotalPage } = analyticsRDX;
  const pageRef = useRef(2);
  const analyticsUserListRef = useRef(null);
  const fetchData = async () => {
    await dispatch(getAnalyticsUsers(1));
  };
  useEffect(() => {
    if (socket) {
      dispatch(handleSocketLister(socket)); // socket listener events
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleScroll = async () => {
    const element = analyticsUserListRef.current;
    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      pageRef.current <= userListTotalPage
    ) {
      await dispatch(getAnalyticsUsers(pageRef.current));
      pageRef.current = pageRef.current + 1;
    }
  };
  const socialCountList =
    userData?.businessCard?.contactDetail?.socialAnalytics;

  const groups = analyticsUserList?.reduce((groups, item) => {
    const date = item.createdAt.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});

  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      userList: groups[date],
    };
  });
  return (
    <Layout id="analytics-container" isNotification={false}>
      <div className="row mt-3 flex-grow-1 h-1">
        {/* left side */}
        <div className="col-7 h-100 d-flex flex-column">
          <div>
            <span className="text-15-400">You are getting noticed</span>
            <div className="noticed-content">
              <div className="Card-5 mt-2 flex-grow-1 cmb-20">
                <div className="profile-view flex-grow-1 pb-2">
                  <DashboardLineChart />
                </div>
              </div>
            </div>
          </div>
          {/* overview section */}
          {socialCountList ? (
            <div className="overview-content">
              <span className="text-15-400">Overview</span>
              <div className="fa-center gap-2 flex-wrap mt-4">
                {Object.keys(socialCountList).map((key, index) => (
                  <React.Fragment key={index}>
                    {
                      {
                        phone: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon">
                                  <img src={icons.callIcon} alt="callIcon" />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        mail: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon">
                                  <img src={icons.emailIcon} alt="emailIcon" />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        twitter: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon h-45">
                                  <img
                                    src={icons.twitterIcon}
                                    alt="twitterIcon"
                                  />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        website: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon">
                                  <img
                                    src={icons.websiteIcon}
                                    alt="websiteIcon"
                                  />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        youtube: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon">
                                  <img
                                    src={icons.youtubeIcon}
                                    alt="youtubeIcon"
                                  />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        facebook: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon">
                                  <img
                                    src={icons.facebookIcon}
                                    alt="facebookIcon"
                                  />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        linkedin: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon">
                                  <img
                                    src={icons.linkedInWhiteIcon}
                                    alt="linkedInWhiteIcon"
                                  />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        location: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon">
                                  <img
                                    src={icons.locationIcon}
                                    alt="locationIcon"
                                  />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        whatsapp: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon">
                                  <img
                                    src={icons.whatsAppIcon}
                                    alt="whatsAppIcon"
                                  />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        instagram: (
                          <div className="wth-24 wth-32 mb-3">
                            <div className="Card-8">
                              <div className="fa-center gap-2">
                                <span className="social-icon">
                                  <img
                                    src={icons.istagramIcon}
                                    alt="instagram"
                                  />
                                </span>
                                <div className="flex-grow-1 text-center">
                                  <p className="text-25-600 mb-0">
                                    {socialCountList[key]}
                                  </p>
                                  <p className="text-14-400">Clicks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                      }[key]
                    }
                  </React.Fragment>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        {/* right side */}
        <div
          className="col-5 h-100 d-flex flex-column overflow-auto"
          ref={analyticsUserListRef}
          onScroll={handleScroll}
        >
          {/* {analyticsUserList?.map((ele,index) => {
            return (
              <React.Fragment key={ele?.id}>
                {<UserContactCard data={ele} />}
              </React.Fragment>
            ); 
          })} */}
          {groupArrays?.map((ele, index) => {
            return (
              <React.Fragment key={ele?.id}>
                {ele?.date ? (
                  <div className="f-center gap-4">
                    <div className="bb-2531 w-25  " />
                    <div className="text-center text-10-400 bg-2531 cpt-5 cpb-5 cps-10 cpe-10  br-8">
                      {ele?.date}
                    </div>
                    <div className="bb-2531 w-25" />
                  </div>
                ) : null}
                {ele?.userList.map((item, index) => {
                  return <UserContactCard data={item} userData={userData} />;
                })}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Analytics;
