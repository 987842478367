import Select, { components } from "react-select";
import "./Dropdown.scss";
import { icons } from "utils/constants";
const Dropdown = ({
  value,
  placeholder,
  options,
  error,
  id,
  optionKey,
  optionValue,
  onChange,
  isLoading,
  disabled,
  name,
  selectStyle,
  extraDisplayKey,
  isClearable,
  isSearchable = true,
  searchIcon = true,
  onInputChange,
  onMenuScrollToBottom,
  errorClass,
}) => {
  const optId = optionKey || "id";
  const optVal = optionValue || "label";
  let fillValue = options?.find((o) => `${o?.[optId]}` === `${value}`);
  if (!fillValue) {
    fillValue = null;
  }
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {isSearchable && searchIcon ? (
          <img src={icons.searchIcon} alt="icon" className="right-icon" />
        ) : null}
      </components.DropdownIndicator>
    );
  };
  return (
    <div id="Dropdown-container">
      <Select
        onChange={(e) => {
          onChange({
            target: {
              id: id,
              value: e ? e[optId] : "",
              data: e,
            },
          });
        }}
        getOptionLabel={(option) => {
          if (extraDisplayKey) {
            return `${option[optVal] || ""} ${
              option[extraDisplayKey] ? `- ${option[extraDisplayKey]}` : ""
            }`;
          } else {
            return option[optVal];
          }
        }}
        getOptionValue={(option) => option[optId]}
        placeholder={placeholder}
        className={`${selectStyle ? selectStyle : "basic-single"} ${
          value ? "" : "placehoder-val"
        } ${errorClass} `}
        classNamePrefix="select"
        value={fillValue}
        isDisabled={disabled}
        isLoading={isLoading}
        name={name}
        options={options}
        isClearable={isClearable}
        isSearchable={isSearchable}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onInputChange={(text, event) => {
          if (onInputChange && event?.action === "input-change") {
            onInputChange(text);
          }
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
      />

      {error && (
        <span className="text-13-400 pt-1">
          <span style={{ color: "red" }}>{error}</span>
        </span>
      )}
    </div>
  );
};
export default Dropdown;
