import React from "react";
import "./UserViewerCard.scss";
import Button from "components/inputs/Button";
import { icons } from "utils/constants";
const UserViewerCard = ({ userData }) => {
  return (
    <div id="user-viewers">
      <div className="views-count">
        <h2 className="text-32-750 mb-0">{userData?.count}</h2>
        <span className="text-15-500">views</span>
        <div className="user-img-box">
          <img src={userData?.userImg} alt="user" />
        </div>
      </div>
      <div className="last-viewer">
        <div className="mt-3 w-100">
          <p className="last-view-text">Last viewed by</p>
          <div className="d-flex align-items-end justify-content-between">
            <div className="fc-center">
              <div className="viewer-img">
                <img src={userData?.viewerImg} alt="view" />
              </div>
              <div className="">
                <p className="mb-0 text-9-900 color-ffff">{userData?.viewer}</p>
                <p className="mb-0 text-8-400 color-grey-600">
                  {userData?.date}
                </p>
                <Button btnText="View all" btnStyle="VIEW-ALL" />
              </div>
            </div>

            <Button
              btnText="Share"
              btnStyle="SHARE"
              rightIcon={icons.shareIcon}
              iconClass=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserViewerCard;
