import Button from "components/inputs/Button";
import React, { useEffect, useState, memo } from "react";
import { icons } from "utils/constants";
import UserFaceImg from "assets/images/common/dummyProfile.png";
import EmojiPicker from "components/inputs/EmojiPicker";
// import useDebounce from "hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { getDataFromLocalStorage } from "utils/helpers";
import moment from "moment";
import _, { capitalize } from "lodash";
import {
  sendAcceptRequest,
  storeChatMessages,
} from "store/slices/connectSlice";
import SelectCategoriesForAccept from "../SelectCategoriesForAccept";
import GridCoinSent from "../GridCoinSent";
const ChatWithConnect = ({
  socket,
  isChatView,
  connectedUser,
  setConnectedUser,
  handleViewProfile,
  requestedUserSelect,
  handleRequestedUserSelect,
  finalRequestedListUser,
  setTab,
}) => {
  const dispatch = useDispatch();
  const userId = getDataFromLocalStorage("userId");
  const { chatMessages, chatRoomData } = useSelector((state) => ({
    chatMessages: _.orderBy(state.connect.chatMessages, ["createdAt"], ["asc"]),
    chatRoomData: state.connect.chatRoomData,
  }));
  const [msg, setMsg] = useState("");
  const [showCtgModal, setShowCtgModal] = useState(false);
  const [showCoinAnimation, setShowCoinAnimation] = useState(false);
  const [isConnect, SetIsConnect] = useState(false);
  const [isCount, setIsCount] = useState(0);
  const lastUserMsg = document.querySelector(".user-msg:last-child");
  const connectMsg = document.querySelector(".connector-msg:last-child");

  if (lastUserMsg) {
    lastUserMsg.scrollIntoView({ behavior: "smooth" });
  }
  if (connectMsg) {
    connectMsg.scrollIntoView({ behavior: "smooth" });
  }
  const handleInputChange = (e) => {
    setMsg(e.target.value);
  };
  const handleSeenMessages = () => {
    if (connectedUser?.unreadCounts > 0) {
      chatMessages?.forEach((ele) => {
        socket.emit(
          "seen-message",
          JSON.stringify({
            messageId: ele?.id,
          })
        );
      });
    }
  };
  useEffect(() => {
    handleSeenMessages();
    const newUserMsg = document.querySelector(".user-msg:last-child");
    const newConnectMsg = document.querySelector(".connector-msg:last-child");
    const time = setTimeout(() => {
      if (newUserMsg) {
        newUserMsg.scrollIntoView({ behavior: "smooth" });
      }
      if (newConnectMsg) {
        newConnectMsg.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
    return () => clearTimeout(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);

  //for scroll msg end--
  const handleSendMsg = (e) => {
    e.preventDefault();
    if (msg === "") return;
    const payload = {
      chatRoomId: connectedUser?.roomId,
      text: msg,
    };
    const mapString = JSON.stringify(payload);
    socket.emit("send-message", mapString);
    dispatch(
      storeChatMessages([...chatMessages, { userId: userId, text: msg }])
    );
    setMsg("");
  };

  const getMessage = (roomId, beforeTime) => {
    if (roomId) {
      const payload = {
        chatRoomId: roomId,
        before: beforeTime,
      };
      const mapString = JSON.stringify(payload);
      socket.emit("get-messages", mapString);
    } else {
      dispatch(storeChatMessages([]));
    }
  };

  useEffect(() => {
    if (connectedUser && isChatView) {
      getMessage(connectedUser?.roomId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomData, connectedUser]);
  const acceptConnectRequest = async (catgory) => {
    const payload = {
      category: catgory,
      connectId: requestedUserSelect?.connect?.id,
    };
    const res = await dispatch(sendAcceptRequest(payload));
    if (res.status === 200) {
      setShowCtgModal(false);
      SetIsConnect(true);
      setShowCoinAnimation(true);
      setIsCount(res?.data?.coins || 0);
      setConnectedUser(requestedUserSelect);
      setTab(1);
      handleRequestedUserSelect({});
    }
  };
  useEffect(() => {
    let timer = setTimeout(() => SetIsConnect(false), 2500);

    let modalClose = setTimeout(() => setShowCoinAnimation(false), 10000);
    return () => {
      clearTimeout(timer);
      clearTimeout(modalClose);
    };
  }, [isConnect]);
  return (
    <>
      <div className={` col-7 Card-8 d-flex flex-column h-100`}>
        {isChatView === 1 && connectedUser?.id ? (
          <div className="user-details">
            <div className="fb-center">
              <div className="fc-center">
                <img
                  src={
                    connectedUser?.user_introduction?.profile_media
                      ? `${connectedUser?.user_introduction?.profile_media?.cdn_url}/${connectedUser?.user_introduction?.profile_media?.thumbnail}`
                      : UserFaceImg
                  }
                  alt="user"
                  width={33}
                  height={33}
                  className="rounded-circle m-2"
                />
                <p className="mb-0 text-15-400 ms-2">
                  {capitalize(connectedUser?.name)}
                </p>
              </div>
              <Button
                btnText="View Profile"
                btnStyle="BLUE-5"
                className="text-12-400 py-2"
                onClick={() => handleViewProfile(connectedUser)}
              />
            </div>
          </div>
        ) : null}

        <div className="chat-container">
          {/* message list */}
          {isChatView === 1 && (
            <div className="message-container">
              {chatMessages?.map((elem, i) => {
                return (
                  <div
                    className={`${
                      elem?.userId === userId ? "user-msg" : "connector-msg"
                    } ${elem.id ? "" : "opacity-75"}`}
                    key={i}
                  >
                    <div
                      className={`${
                        elem?.userId !== chatMessages?.[i - 1]?.userId
                          ? "msg-tail"
                          : ""
                      } message-content`}
                    >
                      <p
                        className="flex-grow-1 text-12-400 mb-0"
                        dangerouslySetInnerHTML={{
                          __html: `${elem.text}`,
                        }}
                      ></p>
                      <p
                        className={`d-flex align-items-end text-10-400 mb-0 h-100 ${
                          elem?.userId === userId ? "" : "color-grey-800"
                        }`}
                      >
                        {moment(elem.createdAt).format("HH:mm")}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {isChatView === 2 && (
            <div className="message-container">
              {requestedUserSelect?.id ? (
                <div className="fc-center flex-column h-100">
                  <img
                    src={
                      requestedUserSelect?.user_introduction?.profile_media
                        ? `${requestedUserSelect?.user_introduction?.profile_media?.cdn_url}/${requestedUserSelect?.user_introduction?.profile_media?.thumbnail}`
                        : UserFaceImg
                    }
                    alt="user"
                    width={147}
                    height={147}
                    className="rounded-circle m-2"
                  />
                  <p className="text-15-400">
                    {capitalize(requestedUserSelect?.name)}
                  </p>
                  <Button
                    btnText="View Profile"
                    btnStyle="BLUE-5"
                    className="text-12-400 py-2 mt-2 mb-4"
                    onClick={() => handleViewProfile(requestedUserSelect)}
                  />
                  <div className="connector-msg">
                    <div className="msg-tail message-content">
                      <p className="flex-grow-1 text-12-400 mb-0">
                        {requestedUserSelect?.lastMessage?.text}{" "}
                      </p>
                      <p
                        className={`d-flex align-items-end text-10-400 mb-0 h-100 color-grey-800`}
                      >
                        {moment(
                          requestedUserSelect?.lastMessage?.createdAt
                        ).format("HH:mm")}
                      </p>
                    </div>
                  </div>
                  <Button
                    btnText="Accept"
                    btnStyle="BLACK-BLUE"
                    className="text-10-400 w-100 text-center cps-36 cpe-36"
                    onClick={() => setShowCtgModal(true)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          {isChatView === 3 && (
            <div className="message-container">
              {finalRequestedListUser?.id ? (
                <div className="fc-center flex-column h-100">
                  <img
                    src={
                      finalRequestedListUser?.user_introduction?.profile_media
                        ? `${finalRequestedListUser?.user_introduction?.profile_media?.cdn_url}/${finalRequestedListUser?.user_introduction?.profile_media?.thumbnail}`
                        : UserFaceImg
                    }
                    alt="user"
                    width={147}
                    height={147}
                    className="rounded-circle m-2"
                  />
                  <p className="text-15-400">
                    {capitalize(finalRequestedListUser?.name)}
                  </p>
                  <Button
                    btnText="View Profile"
                    btnStyle="BLUE-5"
                    className="text-12-400 py-2 mt-2 mb-4"
                    onClick={() => handleViewProfile(finalRequestedListUser)}
                  />
                  <div className="connector-msg">
                    <div className="msg-tail message-content">
                      <p className="flex-grow-1 text-12-400 mb-0">
                        {finalRequestedListUser?.lastMessage?.text}{" "}
                      </p>
                      <p
                        className={`d-flex align-items-end text-10-400 mb-0 h-100 color-grey-800`}
                      >
                        {moment(
                          finalRequestedListUser?.lastMessage?.createdAt
                        ).format("HH:mm")}
                      </p>
                    </div>
                  </div>
                  <Button
                    btnText="Request Sent"
                    btnStyle="BLACK-BLUE"
                    className="text-10-400 w-100 text-center cps-36 cpe-36"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          {/* send msg */}
          <form className="send-msg-form">
            <div className="position-relative flex-grow-1">
              <div className="d-flex align-items-center">
                <div className="emoji-btn">
                  <EmojiPicker
                    isDisable={!isChatView || !connectedUser?.id}
                    onChange={(e) => {
                      let newMessage = `${msg} ${e?.value}`;
                      setMsg(newMessage);
                    }}
                  />
                </div>
                <div className="flex-grow-1">
                  <input
                    value={msg}
                    autoComplete="off"
                    placeholder="Message"
                    className="msg-input"
                    onChange={handleInputChange}
                    disabled={!isChatView || !connectedUser?.id}
                  />
                </div>
              </div>
            </div>
            <button
              className="send-msg-btn"
              type="submit"
              onClick={handleSendMsg}
              disabled={!isChatView || !connectedUser?.id}
            >
              <img src={icons.sendIcon} alt="send" />
            </button>
          </form>
        </div>
        {showCtgModal && (
          <SelectCategoriesForAccept
            onSave={acceptConnectRequest}
            onClose={() => setShowCtgModal(false)}
          />
        )}
        {showCoinAnimation && (
          <GridCoinSent
            onHide={() => {
              setShowCoinAnimation(true);
            }}
            isModal={showCoinAnimation}
            onClick={() => {
              setShowCoinAnimation(false);
            }}
            coins={isCount}
            isConnect={isConnect}
          />
        )}
      </div>
    </>
  );
};

export default memo(ChatWithConnect);
