// src/SocketContext.js
import React, { createContext, useContext, useReducer } from 'react';
import { io } from 'socket.io-client';
import { getDataFromLocalStorage } from 'utils/helpers';
const SocketContext = createContext();
export const useSocket = () => {
    return useContext(SocketContext);
};
const initialState = { socket: undefined }

const reducer = (state, action) => {
    switch (action.type) {
        case "SOCKET_CONNECTION":
            return {
                ...state,
                socket: action.data
            }
        default:
            break;
    }
}

const SocketProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const createConnection = () => {
        const token = getDataFromLocalStorage("token");
        const socketConnect = io.connect(process.env.REACT_APP_SOCKET_LOCAL_URL, {
            reconnection: false,
            transports: ["websocket"],
            query: {
                token: token,
            },
        });
        dispatch({
            type: "SOCKET_CONNECTION",
            data: socketConnect
        })
    }
    const value = { state, dispatch, createConnection }
    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketProvider;