import Layout from "pages/Layout";
import React, { useRef, useState } from "react";
import "./DiscoverAll.scss";
import TextInput from "components/inputs/TextInput";
import { icons } from "utils/constants";
import ConnectUserCard from "components/layouts/ConnectUserCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback } from "react";
import { getDiscoverList, getStateCityData, getUserData } from "store/slices";
import useDebounce from "hooks/useDebounce";
import ViewUserProfile from "components/layouts/ViewUserProfile";
import { handleSocketLister, viewUserDetails } from "store/slices/connectSlice";
import { getDataFromLocalStorage, storeLocalStorageData } from "utils/helpers";
import { useSocket } from "context/socket";
import Dropdown from "components/inputs/Dropdown";

const DiscoverAll = () => {
  const pageRef = useRef(2);
  const discoverCardRef = useRef(null);
  const isMoreDiscoverData = useRef(true);
  const [isProfileView, setIsProfileView] = useState(false);
  const [isButtonShow, setIsButtonShow] = useState(false);
  const socketContext = useSocket();
  const { socket } = socketContext.state;
  const dispatch = useDispatch();
  const { discoverList } = useSelector((state) => ({
    discoverList: state.common.discoverList,
  }));
  const connectRDX = useSelector((state) => state.connect);
  const { connectList } = connectRDX || {};
  const [search, setSearch] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const searchDebouncedValue = useDebounce(search, 300);
  const searchDebouncedState = useDebounce(searchState, 300);
  const searchDebouncedCity = useDebounce(searchCity, 300);
  const totalConnect = connectList?.filter((ele) => ele?.connect?.status === 4);

  const { userData, stateList, countryId } = useSelector((state) => ({
    stateList: state.profile.stateList?.map((ele, index) => ({
      id: ele.id,
      label: ele.name,
      city: ele.cities,
    })),
    userData: state.profile.userData,
    countryId: state.profile.countryId,
  }));
  const fetchData = async () => {
    if (!discoverList?.list?.length) {
      const query = new URLSearchParams({ page: 1 });
      await dispatch(getDiscoverList(query));
      await dispatch(getUserData());
    }
    if (countryId) {
      await dispatch(getStateCityData(1));
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //more data load on scroll
  const handleScroll = async () => {
    if (
      parseInt(
        discoverCardRef.current.scrollTop +
          discoverCardRef.current.clientHeight +
          1
      ) >= discoverCardRef.current.scrollHeight &&
      isMoreDiscoverData.current
    ) {
      const query = new URLSearchParams({
        page: pageRef.current,
        q: getDataFromLocalStorage("searchQ") || "",
      });
      const res = await dispatch(getDiscoverList(query, pageRef.current));
      if (res?.status === 200 && res?.data?.data?.length) {
        pageRef.current = pageRef.current + 1;
        isMoreDiscoverData.current = true;
      } else {
        isMoreDiscoverData.current = false;
      }
    }
  };
  const handleScrollState = async () => {
    if (
      parseInt(
        discoverCardRef.current.scrollTop +
          discoverCardRef.current.clientHeight +
          1
      ) >= discoverCardRef.current.scrollHeight &&
      isMoreDiscoverData.current
    ) {
      const query = new URLSearchParams({
        page: pageRef.current,
        state: getDataFromLocalStorage("stateQ") || "",
      });
      const res = await dispatch(getDiscoverList(query, pageRef.current));
      if (res?.status === 200 && res?.data?.data?.length) {
        pageRef.current = pageRef.current + 1;
        isMoreDiscoverData.current = true;
      } else {
        isMoreDiscoverData.current = false;
      }
    }
  };
  const handleScrollCity = async () => {
    if (
      parseInt(
        discoverCardRef.current.scrollTop +
          discoverCardRef.current.clientHeight +
          1
      ) >= discoverCardRef.current.scrollHeight &&
      isMoreDiscoverData.current
    ) {
      const query = new URLSearchParams({
        page: pageRef.current,
        city: getDataFromLocalStorage("cityQ") || "",
      });
      const res = await dispatch(getDiscoverList(query, pageRef.current));
      if (res?.status === 200 && res?.data?.data?.length) {
        pageRef.current = pageRef.current + 1;
        isMoreDiscoverData.current = true;
      } else {
        isMoreDiscoverData.current = false;
      }
    }
  };
  const handleViewProfile = useCallback((data) => {
    if (data.connect_status === 4) {
      setIsButtonShow(true);
    } else {
      setIsButtonShow(false);
    }
    dispatch(viewUserDetails(data.id));

    setIsProfileView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchState = async () => {
    storeLocalStorageData({
      stateQ: searchDebouncedState.toLowerCase(),
    });
    const query = new URLSearchParams({
      page: 1,
      state: searchDebouncedState.toLowerCase(),
    });
    const res = await dispatch(getDiscoverList(query, 1));
    if (res.status === 200 && discoverCardRef.current) {
      discoverCardRef.current.scrollTop = 0;
    }
  };
  const handleSearchCity = async () => {
    if (searchDebouncedCity) {
      storeLocalStorageData({
        cityQ: searchDebouncedCity.toLowerCase(),
      });
      const query = new URLSearchParams({
        page: 1,
        city: searchDebouncedCity.toLowerCase(),
      });
      const res = await dispatch(getDiscoverList(query, 1));
      if (res.status === 200 && discoverCardRef.current) {
        discoverCardRef.current.scrollTop = 0;
      }
    } else {
      const query = new URLSearchParams({
        page: 1,
        state: searchDebouncedState.toLowerCase(),
      });
      const res = await dispatch(getDiscoverList(query, 1));
      if (res.status === 200 && discoverCardRef.current) {
        discoverCardRef.current.scrollTop = 0;
      }
    }
  };
  const handleSearch = async () => {
    storeLocalStorageData({ searchQ: searchDebouncedValue.toLowerCase() });
    const query = new URLSearchParams({
      page: 1,
      q: searchDebouncedValue.toLowerCase(),
    });
    const res = await dispatch(getDiscoverList(query, 1));
    if (res.status === 200 && discoverCardRef.current) {
      discoverCardRef.current.scrollTop = 0;
    }
  };
  //data get on search
  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebouncedValue]);
  useEffect(() => {
    handleSearchState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebouncedState]);
  useEffect(() => {
    handleSearchCity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebouncedCity]);

  useEffect(() => {
    discoverCardRef?.current?.addEventListener("scroll", handleScroll);
    return () => {
      // eslint-disable-next-line
      discoverCardRef?.current?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    discoverCardRef?.current?.addEventListener("scroll", handleScrollState);
    return () => {
      // eslint-disable-next-line
      discoverCardRef?.current?.removeEventListener(
        "scroll",
        handleScrollState
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    discoverCardRef?.current?.addEventListener("scroll", handleScrollCity);
    return () => {
      // eslint-disable-next-line
      discoverCardRef?.current?.removeEventListener("scroll", handleScrollCity);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (socket) {
      dispatch(handleSocketLister(socket)); // socket listener events
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  let cityOption =
    stateList?.find((o) => o.label === searchDebouncedState)?.city || [];
  return (
    <Layout id="discover-all-container" isNotification={true}>
      <div className="mt-3">
        <div className="fb-center">
          <div className="wth-40">
            <TextInput
              placeholder="Search"
              className="bg-0000 br-8 font-Avenir text-15-400 "
              rightIcon={icons.searchIcon}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="wth-18">
            <Dropdown
              placeholder="state"
              // className="bg-0000 br-8 font-Avenir text-15-400"
              onChange={(e) => {
                setSearchState(e.target.value);
              }}
              options={stateList}
              optionValue="label"
              optionKey="label"
              isSearchable={true}
              value={searchState}
              selectStyle="SEARCH-0000"
              isClearable={true}
              searchIcon={false}
            />
          </div>
          <div className="wth-18">
            <Dropdown
              placeholder="City"
              onChange={(e) => setSearchCity(e.target.value)}
              options={cityOption}
              optionValue="name"
              optionKey="name"
              isSearchable={true}
              value={searchCity}
              selectStyle="SEARCH-0000"
              isClearable={true}
              searchIcon={false}
              disabled={searchState === ""}
            />
          </div>
          <div className="fc-center gap-3">
            <div className="discovered-profile-group">
              <div className="fc-center">
                <div className="discovered-profile-group">
                  {userData?.recentConnects?.slice(0, 5)?.map((ele, i) => (
                    <span className={`discovered-profile-img${i + 1}`} key={i}>
                      <img
                        src={`${ele.cdn_url}/${ele.thumbnail}`}
                        alt="user-img"
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div className="text-15-400 color-grey-E2">
              {totalConnect?.length || 0} connections
            </div>
          </div>
        </div>
        <div className="discover-listing mt-3" ref={discoverCardRef}>
          <div className="d-flex flex-wrap my-3">
            {discoverList?.map((data, i) => {
              return (
                <div className="wth-xxl-20 w-25 p-2" key={i}>
                  <ConnectUserCard
                    data={data}
                    setIsProfileView={setIsProfileView}
                    handleViewProfile={handleViewProfile}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isProfileView && (
        <div
          className={`discover-slider ${isProfileView ? "active " : ""}`}
          onClick={() => {
            setIsProfileView(false);
          }}
        >
          <div
            className={`discover-card-bg ${isProfileView ? "active" : ""}`}
            onClick={(e) => e.stopPropagation()}
          >
            <ViewUserProfile
              setIsProfileView={setIsProfileView}
              isButtonShow={isButtonShow}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default DiscoverAll;
