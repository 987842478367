import React, { useEffect } from "react";
import "./PaymentSuccessModal.scss";
import { commonRoute, icons } from "utils/constants";
import BootrsapModal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setPrimeCoin, setPrimeMemberJoin } from "store/slices";
import Button from "components/inputs/Button";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import Confiette from "assets/images/icons/confiette.json";
import CountUp from "react-countup";
import goldCoinImg from "assets/images/common/gold-coin.png";
import moment from "moment";
import { getDataFromLocalStorage } from "utils/helpers";

const PaymentSuccessModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedValues = getDataFromLocalStorage("primeDataStore");
  const globalRDX = useSelector((state) => state.global);
  const { PrimeCoin } = globalRDX || {};

  const profileRDX = useSelector((state) => state.profile);
  const { userData, primeMebmberShipCoinCount } = profileRDX || {};
  useEffect(() => {
    let timer = setTimeout(() => {
      dispatch(setPrimeCoin(false));
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PrimeCoin]);

  return (
    <BootrsapModal className="p-0" fullscreen={true} centered show>
      {PrimeCoin ? (
        <div id="Primecoins-id">
          <div className="f-center mt-5">
            <div className="color-ffff text-33-700">Your have earned!</div>
          </div>
          <div className="fc-center mt-3 image-animation-relative">
            <div className="color-ffff text-100-900 ">
              <CountUp start={0} end={primeMebmberShipCoinCount} duration={5} />
            </div>

            <Player
              autoplay
              loop
              src={Confiette}
              style={{ height: "500px", width: "500px" }}
              className="image-animation-absolute"
            />
          </div>
          <div className="fc-center mt-3">
            <div className="color-ffff text-33-700">GRID COINS</div>
          </div>
          <div className="fc-center mt-3">
            <div className="color-grey-350 text-21-600">
              Your total coin are
            </div>
          </div>
          <div className="fc-center mt-4">
            <span className="gold-coin-block m-0 px-4 py-3">
              <img src={goldCoinImg} alt="coin" height={28} width={28} />
              <span className="text-22-700 color-ffff">
                {userData?.availableCoins}
              </span>
            </span>
          </div>
        </div>
      ) : (
        <div id="modal-container-payment-id">
          <BootrsapModal.Body id="Prime-member-ship-payment-id">
            <div className="cpt-80 modal-gradient">
              <img
                src={icons.crossIcon}
                alt="close"
                className="pointer modal-close-button"
                onClick={() => {
                  dispatch(setPrimeMemberJoin(false));
                }}
              />
              <div className="f-center">
                <img
                  src={icons.PrimeMemberShipIcon}
                  alt="PrimeMemberShipIcon"
                  width={194}
                  height={194}
                ></img>
              </div>
              <div className="cmt-30 color-ffff f-center text-24-700 font-lt-pro">
                Thank you for making the purchase
              </div>
              <div className="cmt-15 color-ffff f-center text-46-700 font-lt-pro">
                YOU ARE A PRIME
              </div>
              <div className="cmt-15 color-ffff f-center text-24-700 font-lt-pro">
                <div className="d-flex">
                  <img
                    src={icons.RoundBlueCheck}
                    alt="RoundBlueCheck"
                    width={25}
                    height={25}
                    className="cmt-5"
                  />
                  <p className="cms-15"> Unlock all the superpowers</p>
                </div>
              </div>
              <div className="cmt-30 color-ffff f-center text-24-700 font-lt-pro">
                {storedValues?.packageId === 3
                  ? " 1 month"
                  : storedValues?.packageId === 2
                  ? "1 year "
                  : storedValues?.packageId === 5
                  ? " 3 month"
                  : null}{" "}
                Premium
              </div>
              <div className="cmt-15 color-ffff f-center text-24-700 font-lt-pro">
                Expiring on:{" "}
                {moment(storedValues?.expireAt).format("DD-MM-YYYY")}
              </div>
              <div className="cmt-50 color-ffff f-center">
                <Button
                  btnText="Back to home"
                  btnStyle="BLUE-Back text-26-750"
                  onClick={() => {
                    dispatch(setPrimeMemberJoin(false));
                    navigate(commonRoute.profile);
                  }}
                />
              </div>
            </div>
          </BootrsapModal.Body>
        </div>
      )}
    </BootrsapModal>
  );
};

export default PaymentSuccessModal;
