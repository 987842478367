import React from 'react'
import RatingStar from '../RatingStar';

const StarRatings = ({ rating , height, width }) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i}>
          {i <= rating ? (
            <RatingStar color="#FFC700" width={width || 10} height={height || 10} />
          ) : (
            <RatingStar color="#b5b5b5" width={width || 10} height={height || 10} />
          )}
        </span>
      );
    }

    return <div className="fa-center gap-1">{stars}</div>;
  };
export default StarRatings;