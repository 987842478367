import "./PrimeMemberShip.scss";
import BootrsapModal from "react-bootstrap/Modal";
import { icons } from "utils/constants";
import { getPaymentPlan, setPrimeMemberModal } from "store/slices";
import { useDispatch } from "react-redux";
import PaymentGetAway from "../PaymentGetAway";
import { Player } from "@lottiefiles/react-lottie-player";
import { useEffect } from "react";
import Button from "components/inputs/Button";
import PrimeBackGround from "assets/images/icons/Animation-bg.json";
import { downloadURI } from "utils/helpers";

const PrimeMemberShip = () => {
  const dispatch = useDispatch();

  const fetchData = async () => {
    await dispatch(getPaymentPlan());
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BootrsapModal className="p-0 prime-modal" fullscreen={true} centered show>
      <div id="modal-container-id">
        <BootrsapModal.Body id="Prime-member-ship-id">
          <Player
            autoplay
            loop
            src={PrimeBackGround}
            style={{ height: "850px", width: "100vw" }}
            className="modal-relative"
          />
          <div className="row cpt-50 modal-absolute">
            <div className="col-5 fa-center fe-center">
              <div className="">
                <p className="color-ffff text-24-700 cmb-40 f-center">
                  Watch video to understand about prime!
                </p>
                <div className="">
                  <iframe
                    width="566"
                    height="315"
                    src="https://www.youtube.com/embed/_6NVzJSrXfo?si=PuWxlHUhMeUEv4xC"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="cpt-10">
                <img
                  src={icons.crossIcon}
                  alt="close"
                  className="pointer modal-close-button"
                  onClick={() => {
                    dispatch(setPrimeMemberModal(false));
                  }}
                />
                <div className="f-center">
                  <img
                    src={icons.PrimeMemberShipIcon}
                    alt="PrimeMemberShipIcon"
                    width={72}
                    height={72}
                  ></img>
                  <div className="cms-40">
                    <div className="cmt-10 color-ffff text-27-700 font-lt-pro">
                      Be a PRIME
                    </div>
                    <div className="cmt-10 color-ffff text-24-500 font-lt-pro">
                      Unlock all the superpowers
                    </div>
                  </div>
                </div>

                <div className="f-center cmt-30 color-ffff text-16-700 font-lt-pro">
                  <div className="cme-60">
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8"> Deeper analytics</p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8">Enquiry buttons</p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8"> Email signature</p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8">Unlimited social greetings</p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8"> Connect with unlimited people</p>
                    </div>
                  </div>
                  <div className="cms-60">
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8">More Grid Coins </p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8">Virtual backgrounds</p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8"> Exclusive Club Items</p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8">Create template messages</p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={icons.RoundBlueCheck}
                        alt="RoundBlueCheck"
                        width={16.87}
                        height={16.87}
                        className="cmt-5"
                      />
                      <p className="cms-8"> Add direct products and services</p>
                    </div>
                  </div>
                </div>
                <PaymentGetAway />
                <div className="fc-center cmt-50">
                  <div className="bg-33af px-4 py-2 br-15">
                    <div className="row">
                      <div className="col-8">
                        <div className="">
                          <span className="color-ffff text-17-700 cmb-5">
                            Have a question?
                          </span>
                          <p className="text-10-400 color-ffff">
                            Chat with one of our agent to clear your boubts!
                          </p>
                          <Button
                            btnText="Start a chat!"
                            btnStyle="color-ffff text-10-400 bg-0C4D px-4 py-2"
                            onClick={() => {
                              downloadURI(
                                "https://api.whatsapp.com/send/?phone=+918125840839&text=hello"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BootrsapModal.Body>
      </div>
    </BootrsapModal>
  );
};

export default PrimeMemberShip;
