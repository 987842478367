import React, { useEffect, useState } from "react";
import "./AddDescription.scss";
import businessCoupon from "assets/images/common/Business-Social-Media.png";
import flashSaleImg from "assets/images/common/flash-sale.png";
import freshCakeImg from "assets/images/common/fresh-cake.png";
import { useNavigate } from "react-router-dom";
import { commonRoute, icons } from "utils/constants";
import TextInput from "components/inputs/TextInput/TextInput";
import Button from "components/inputs/Button/Button";
import TextArea from "components/inputs/TextArea/TextArea";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addUserIntroductions } from "store/slices";
import { getDataFromLocalStorage, storeLocalStorageData } from "utils/helpers";
const AddDescription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    headline: "",
    description: "",
  });
  const validationSchema = Yup.object().shape({
    headline: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
    description: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
  });
  const handleSubmitForm = async (values) => {
    storeLocalStorageData({ registerValues: values });
    const res = await dispatch(addUserIntroductions(values));
    if (res.status === 200) navigate(commonRoute.addLinks);
  };

  useEffect(() => {
    const storedValues = getDataFromLocalStorage("registerValues");
    if (storedValues) {
      setInitialValues(storedValues);
    }
  }, []);
  return (
    <div id="description-container">
      <span className="pink-dot">
        <div className="w-30 h-30 rounded-circle bg-1B3F"></div>
      </span>
      <span className="yellow-dot">
        <div className="w-15 h-15 rounded-circle bg-BC00"></div>
      </span>
      <span className="yellow-dot2">
        <div className="w-14 h-14 rounded-circle bg-BC00"></div>
      </span>
      <span className="yellow-dot3">
        <div className="w-14 h-14 rounded-circle bg-BC00"></div>
      </span>
      <span className="green-dot">
        <div className="w-7 h-7 rounded-circle bg-AE0C"></div>
      </span>
      <span className="green-dot1">
        <div className="w-8 h-8 rounded-circle bg-AE0C"></div>
      </span>
      <div className="big-round-shadow"></div>
      <div className="medium-round-shadow"></div>
      <div className="small-round-shadow"></div>
      <div className="row">
        <div className="col-xxl-6 col-7 coupon-block">
          <div className="add-product">
            <h2 className="text-35-500 color-ffff">
              Add products to your link
            </h2>
            <p className="text-35-300 color-C700">
              and increase the sales of your <br /> products and services
              directly.
            </p>
          </div>
          <div className="row coupon-group">
            <div className="col-6 fe-center">
              <div className="coupons-1">
                <img src={flashSaleImg} alt="coupon" />
              </div>
            </div>
            <div className="col-6">
              <div className="coupons-2">
                <img src={businessCoupon} alt="coupon" />
              </div>
              <div className="coupons-3">
                <img src={freshCakeImg} alt="coupon" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-6 col-5">
          <div className="user-info-form">
            <div className="form-details">
              <img src={icons.logoImg} alt="logo" className="logo-img" />
              <div className="mt-5 color-ffff">
                <h3 className="text-26-750 ">
                  Your profile helps you discover new people and opportunities
                </h3>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmitForm}
                enableReinitialize
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                    setFieldError,
                  } = props;
                  const { headline, description } = values;
                  const handleInputChange = (e) => {
                    const { value, id } = e.target;
                    if (!touched[id]) {
                      touched[id] = true;
                    }
                    if (value === "") {
                      setFieldError(id, "");
                    }
                    handleChange(e);
                  };
                  return (
                    <form>
                      <div className="my-5">
                        <TextInput
                          placeholder="You are a"
                          id="headline"
                          className={`mb-3 ${
                            touched.headline && errors.headline ? "b-3030" : ""
                          }`}
                          value={headline}
                          onChange={handleInputChange}
                        />
                        <p className="d-block w-100 text-end color-B2FF text-16-400">
                          Choose from our expert samples
                        </p>
                        <TextArea
                          placeholder="Description about you."
                          rows={10}
                          id="description"
                          className={`mb-3 ${
                            touched.description && errors.description
                              ? "b-3030"
                              : ""
                          }`}
                          value={description}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="fe-center">
                        <Button
                          btnText="Next"
                          className="NEXT w-100 px-4"
                          rightIcon={icons.nextIcon}
                          onClick={handleSubmit}
                        />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDescription;
