import React, { useEffect, useState } from "react";
import "./ManageProductAndService.scss";
import Button from "components/inputs/Button";
import { analytics, icons, logPrimeButtonClickEvent } from "utils/constants";
import bannerImg from "assets/images/common/banner.png";
import TextInput from "components/inputs/TextInput";
import TextArea from "components/inputs/TextArea";
import Modal from "../Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import RadioInput from "components/inputs/RadioInput";
import imageCompression from "browser-image-compression";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, cloneDeepWith } from "lodash";
import { objectToFormData } from "utils/helpers";
import {
  addNewServiceDetails,
  getServicesList,
  handleDeleteService,
  setPrimeMemberModal,
  updateServiceDetails,
  uploadServiceMedia,
} from "store/slices";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import DeleteModal from "../DeleteModal";
import { Blurhash } from "react-blurhash";
const ManageProductAndService = ({ DataPrime }) => {
  const dispatch = useDispatch();

  const profileRDX = useSelector((state) => state.profile);
  const { servicesList } = profileRDX || {};
  //   {
  //     field: "Title",
  //     isDisable: true,
  //     value: "",
  //     inputType: "text",
  //     placeholder: "Title",
  //   },
  //   {
  //     field: "Price",
  //     isDisable: true,
  //     value: "",
  //     inputType: "text",
  //     placeholder: "Price",
  //   },
  //   {
  //     field: "Description",
  //     isDisable: true,
  //     value: "",
  //     inputType: "textArea",
  //     placeholder: "Description",
  //   },
  // ]);
  const [initialValue, setInitialValue] = useState({
    title: "",
    price: "",
    desc: "",
    enquireButton: "0",
    media: [],
    imgURL: "",
  });
  const [isAddModel, setIsAddModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const fetchData = async () => {
    await dispatch(getServicesList());
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validationSchema = Yup.object().shape({
    // title: Yup.string()
    //   .transform((value) => value.trim())
    //   .required("Required"),
    // price: Yup.string()
    //   .transform((value) => value.trim())
    //   .required("Required"),
    // desc: Yup.string()
    //   .transform((value) => value.trim())
    //   .required("Required"),
    media: Yup.mixed()
      .required("Image is required.")
      .test("fileType", "Unsupported file type", (value) => {
        if (value) {
          return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
        }
        return true; // Allow empty files
      }),
  });
  const validationEditSchema = Yup.object().shape({
    title: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
    price: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
    desc: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
  });

  const submitServiceDetails = async (values) => {
    const serviceDetails = cloneDeep(values);
    delete serviceDetails.imgURL;
    if (serviceDetails.id) {
      const payload = {
        title: serviceDetails?.title,
        desc: serviceDetails?.desc,
        price: serviceDetails?.price,
        enquireButton: serviceDetails?.enquireButton,
      };
      if (serviceDetails.media?.name) {
        const formData = objectToFormData({ media: serviceDetails?.media });
        await dispatch(uploadServiceMedia(formData, serviceDetails.id));
      }
      const res = await dispatch(
        updateServiceDetails(payload, serviceDetails.id)
      );
      if (res.status === 200) {
        setIsAddModal(false);
      }
      setInitialValue({});
      return;
    } else {
      const formData = objectToFormData(serviceDetails);
      const res = await dispatch(addNewServiceDetails(formData));
      if (res.status === 200) {
        setIsAddModal(false);
        setInitialValue({});
      }
    }
  };
  const deleteService = async () => {
    await dispatch(handleDeleteService(selectedService?.id));
    setSelectedService({});
    setIsDeleteModal(false);
  };
  const showEditModal = (data) => {
    setInitialValue({
      id: data?.id,
      title: data?.title,
      price: data?.price,
      desc: data?.desc,
      enquireButton: data?.enquireButton.toString(),
      media: [],
      imgURL: data?.media?.cdn_url + "/" + data?.media?.thumbnail,
    });
    setIsAddModal(true);
  };

  const updateServiceImg = async (event, id) => {
    if (!event.target.files.length) return;
    const file = event.target.files[0];
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);
    const formData = objectToFormData({ media: compressedFile });
    dispatch(uploadServiceMedia(formData, id));
  };
  const hanldeEnquiryButtonView = (data, index) => {
    const newList = cloneDeepWith(servicesList);
    newList[index].enquireButton = data.enquireButton ? 0 : 1;
    const payload = {
      desc: newList[index]?.desc,
      enquireButton: newList[index]?.enquireButton ? 1 : 0,
      price: newList[index]?.price,
      title: newList[index]?.title,
    };
    dispatch(updateServiceDetails(payload, newList[index]?.id));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <div id="product-service-manage">
      <div className="fb-center">
        <p className="text-16-700 font-Avenir">Products/Service</p>

        {DataPrime ? (
          <Button
            onClick={() => {
              setInitialValue({});
              setIsAddModal(true);
            }}
            btnText="+ Add a product/service"
            className="text-16-400 p-3 b-half-ffff br-8"
          />
        ) : (
          <Button
            onClick={() => {
              dispatch(setPrimeMemberModal(true));
              logPrimeButtonClickEvent("profile");
            }}
            btnText="+ Add a product/service"
            className="text-16-400 p-3 b-half-ffff br-8"
          />
        )}
      </div>
      {DataPrime ? (
        <div className="Card-8 my-3">
          {servicesList?.map((ele, i) => {
            const defaultBlurhash = "LEHV6nWB2yk8pyo0adR*.7kCMdnj";

            const blurhashToUse =
              ele.media?.hashed && ele.media?.hashed === 86
                ? ele.media?.hashed
                : defaultBlurhash;
            return (
              <React.Fragment key={i}>
                <p className="text-16-700 font-Avenir">Products {i + 1} </p>
                <div className="row my-2">
                  <div className="col-2">
                    {ele?.media ? (
                      <img
                        src={
                          ele?.media
                            ? ele?.media?.cdn_url + "/" + ele?.media?.thumbnail
                            : bannerImg
                        }
                        className="banner-image"
                        alt="userImg"
                      />
                    ) : (
                      <div
                        style={{
                          borderRadius: "10px",
                          overflow: "hidden",
                          display: "inline-block",
                          height: "94px",
                        }}
                      >
                        <Blurhash
                          hash={blurhashToUse}
                          width={94}
                          height={94}
                          punch={1}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-8 fa-center">
                    <div className="position-relative">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => updateServiceImg(e, ele.id)}
                        // disabled={loading}
                      />
                      <Button
                        btnText="Upload Image"
                        className="font-Avenir text-16-400 pointer"
                        btnStyle="BLUE-8"
                      />
                    </div>
                  </div>
                  <div className="col-2 fc-center gap-4">
                    <button
                      className="fc-center flex-column"
                      onClick={() => {
                        setIsDeleteModal(true);
                        setSelectedService(ele);
                      }}
                    >
                      <img src={icons.deleteIcon} alt="delete" />
                      <span className="font-Avenir text-10-400 mb-0 mt-2 color-ffff">
                        Remove
                      </span>
                    </button>
                    <button
                      className="fc-center flex-column"
                      onClick={() => showEditModal(ele)}
                    >
                      <img src={icons.pencilIcon} alt="edit" height={19} />
                      <span className="font-Avenir text-10-400 mb-0 mt-2 color-ffff">
                        Edit
                      </span>
                    </button>
                  </div>
                </div>
                {/* product/service details */}
                <div className="mt-5">
                  <div className="row my-2">
                    <div className="col-2 text-16-400 pt-2">Title</div>
                    <div className="col-9 fa-center">
                      <TextInput
                        placeholder={ele?.placeholder}
                        disabled
                        value={ele.title}
                        className="text-14-400"
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-2 text-16-400 pt-2">Price</div>
                    <div className="col-9 fa-center">
                      <TextInput
                        placeholder={ele?.placeholder}
                        disabled
                        value={ele.price}
                        className="text-14-400"
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-2 text-16-400 pt-2">Description</div>
                    <div className="col-9 fa-center">
                      <TextArea
                        placeholder={ele?.placeholder}
                        disabled
                        rows={5}
                        value={ele.desc}
                        className="text-14-400"
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-2 text-16-400 pt-2">Enquire button</div>
                    <div className="col-9 fa-center pt-2">
                      {/* {JSON.parse(ele?.enquireButton) ? "Visible" : "Not visible"} */}
                      <button
                        className="d-flex flex-grow-1 gap-3 ps-2"
                        onClick={() => hanldeEnquiryButtonView(ele, i)}
                      >
                        {JSON.parse(ele?.enquireButton) ? (
                          <FaEye color="#ffffff" />
                        ) : (
                          <FaEyeSlash color="#ffffff" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {i < servicesList?.length - 1 ? (
                  <div className="bb-grey-7878 my-3"></div>
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
      ) : null}

      {isAddModel ? (
        <Modal
          closeBtn
          title={`${initialValue?.id ? "Edit" : "Add"} Product/Service`}
          onHide={() => setIsAddModal(false)}
        >
          <div className=" add-service-details">
            <Formik
              initialValues={initialValue}
              validationSchema={
                initialValue?.id ? validationEditSchema : validationSchema
              }
              onSubmit={submitServiceDetails}
              enableReinitialize
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setFieldError,
                } = props;
                const { title, desc, price, enquireButton, imgURL } = values;
                const handleInputChange = (e) => {
                  const { value, id } = e.target;
                  if (!touched[id]) {
                    touched[id] = true;
                  }
                  if (value === "") {
                    setFieldError(id, "");
                  }
                  handleChange(e);
                };
                return (
                  <form className="row my-2 gy-3">
                    <div className="col-4">
                      <img
                        src={imgURL || bannerImg}
                        alt="service-logo"
                        className={`banner-image ${
                          errors.media ? "b-3030" : ""
                        }`}
                      />
                    </div>
                    <div className="col-8 fa-center">
                      <div className="position-relative">
                        <input
                          id="media"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            setFieldValue("media", e.target.files?.[0]);
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onload = function (event) {
                              setFieldValue("imgURL", event.target.result);
                            };
                            reader.readAsDataURL(file);
                          }}
                        />
                        <Button
                          btnText="Upload Image"
                          className="font-Avenir text-16-400"
                          btnStyle="BLUE-8"
                        />
                      </div>
                    </div>
                    <div className="col-3 text-16-400 pt-2 color-ffff">
                      Title
                    </div>
                    <div className="col-9">
                      <TextInput
                        placeholder="Title"
                        id="title"
                        className={`${
                          touched.title && errors.title ? "b-3030" : ""
                        }`}
                        value={title}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-3 text-16-400 pt-2 color-ffff">
                      Price
                    </div>
                    <div className="col-9 ">
                      <TextInput
                        placeholder="Price"
                        id="price"
                        className={`${
                          touched.price && errors.price ? "b-3030" : ""
                        }`}
                        value={price}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-3 text-16-400 pt-2 color-ffff">
                      Description
                    </div>
                    <div className="col-9">
                      <TextArea
                        placeholder="Description"
                        rows={4}
                        id="desc"
                        className={`w-100 ${
                          touched.desc && errors.desc ? "b-3030" : ""
                        }`}
                        value={desc}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-3 text-16-400 pt-2 color-ffff">
                      Enquire button
                    </div>
                    <div className="col-9 pt-2 ">
                      <div className="d-flex flex-grow-1 gap-3 ps-2">
                        <RadioInput
                          name="enquireButton"
                          label="Visible"
                          // className="pe-4"
                          value={"1"}
                          onChange={handleChange}
                          checked={enquireButton === "1"}
                        />
                        <RadioInput
                          name="enquireButton"
                          label="Not visible"
                          value={"0"}
                          onChange={handleChange}
                          checked={enquireButton === "0"}
                        />
                      </div>
                    </div>
                    <div className="fe-center mt-5 values-listing">
                      <Button
                        btnType="submit"
                        btnText={`+ ${
                          initialValue?.id ? "Edit" : "Add"
                        } Details`}
                        btnStyle="BLACK-BLUE"
                        className="rounded-pill text-17-400 w-150"
                        onClick={handleSubmit}
                      />
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Modal>
      ) : null}
      {isDeleteModal && (
        <DeleteModal
          onClose={() => setIsDeleteModal(false)}
          onDelete={deleteService}
          question="Do you want to delete this Service/Product?"
        />
      )}
    </div>
  );
};

export default ManageProductAndService;
