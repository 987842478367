import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { commonRoute, logPrimeButtonClickEvent } from "utils/constants";
import ImageEditor from "components/layouts/ImageEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  getGoogleFont,
  getImagesByCategory,
  getSocialCategories,
  getTodaysGreetingImages,
  setPrimeMemberModal,
} from "store/slices";
import { capitalize, map } from "lodash";
import Dropdown from "components/inputs/Dropdown";
import { Spinner } from "react-bootstrap";
import "./Greetings.scss";
import { Blurhash } from "react-blurhash";

const Greetings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { socialCategories, imageListByCategory, isCtgImagesLoading } =
    useSelector((state) => ({
      socialCategories: state.greeting.socialCategories,
      imageListByCategory: state.greeting.imageListByCategory,
      isCtgImagesLoading: state.greeting.isCtgImagesLoading,
    }));
  const { userData } = useSelector((state) => ({
    userData: state.profile.userData,
  }));
  const [searchCtg, setSearchCtg] = useState("");
  const [selectImg, setSelectImg] = useState("");
  const fetchData = async () => {
    await dispatch(getTodaysGreetingImages());
    await dispatch(getSocialCategories());
    await dispatch(getGoogleFont());
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSelectCategories = async (e) => {
    setSearchCtg(e.target.value);
    await dispatch(getImagesByCategory(e.target.value));
  };
  const handleSelectImg = (data) => {
    setSelectImg(data);
  };
  const handleNavigate = () => {
    navigate(commonRoute.dashboard);
  };
  useEffect(() => {}, [selectImg]);

  return (
    <div id="greeting-container">
      <div className="left-content">
        <button
          onClick={handleNavigate}
          className="color-ffff text-19-400 font-poppins"
        >
          Back to dashboard
        </button>
        <div className="mt-5 mb-3">
          <Dropdown
            placeholder="What event you are looking for?"
            options={map(socialCategories, (elm) => {
              return {
                id: elm.id,
                label: capitalize(elm.name?.split("-")?.[0]),
              };
            })}
            id="fontFamily"
            onChange={handleSelectCategories}
            value={searchCtg}
            className="bg-1B1B text-13-400 br-8"
            selectStyle="SEARCH-DARK"
            isSearchable={true}
          />
          {isCtgImagesLoading ? (
            <div className="fc-center h-100 pt-5">
              {" "}
              <Spinner animation="border" variant="secondary" />{" "}
            </div>
          ) : (
            <div className="fa-center flex-wrap gap-3 mt-4 img-list-scroll">
              {imageListByCategory?.map((imgData, index) => {
                const defaultBlurhash = "LEHV6nWB2yk8pyo0adR*.7kCMdnj";

                const blurhashToUse =
                  imgData?.media?.hashed && imgData?.media?.hashed === 86
                    ? imgData?.media?.hashed
                    : defaultBlurhash;
                const imgPath =
                  imgData?.media?.cdn_url + "/" + imgData?.media?.thumbnail;
                return (
                  <>
                    {imgData ? (
                      <div
                        className={`card-img ${
                          selectImg?.id !== imgData?.id ? "opacity-50" : " "
                        }`}
                        key={imgData?.id}
                        onClick={() => {
                          if (userData?.prime) {
                            handleSelectImg(imgData);
                          }
                          if (index > 0 && !userData?.prime) {
                            logPrimeButtonClickEvent("Social greeting");

                            dispatch(setPrimeMemberModal(true));
                          } else {
                            handleSelectImg(imgData);
                          }
                        }}
                      >
                        {imgPath ? (
                          <img src={imgPath} alt={imgData?.media?.name} />
                        ) : (
                          <div
                            style={{
                              borderRadius: "8px",
                              overflow: "hidden",
                              display: "inline-block",
                              width: "102px",
                              height: "102px",
                            }}
                          >
                            <Blurhash
                              hash={blurhashToUse}
                              punch={1}
                              style={{
                                borderRadius: "8px",
                                width: "102px",
                                height: "102px",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="right-content">
        <ImageEditor imgPath={selectImg} />
      </div>
    </div>
  );
};

export default Greetings;
