import { createSlice } from "@reduxjs/toolkit";
import { handelLogout } from "./authSlice";
import { api } from "services/api";
const initialState = {
  researchPediaData: null,
  isSessionExpires: false,
  isCitetion: false,
  isShare: false,
  errorData: {},
  primeMemberModal: false,
  primeMemberJoin: false,
  PrimeCoin: true,
  paymentPlanData: {},

};
const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setErrorData(state, action) {
      state.errorData = action.payload;
    },
    setSessionExpired(state, action) {
      state.isSessionExpires = action.payload;
    },
    setIsCitetion(state, action) {
      state.isCitetion = action.payload;
    },
    setPrimeMemberModal(state, action) {
      state.primeMemberModal = action.payload;

    },
    setPrimeMemberJoin(state, action) {
      state.primeMemberJoin = action.payload;
    },
    setPrimeCoin(state, action) {
      state.PrimeCoin = action.payload;
    },
    setPaymentPlanData(state, action) {
      state.paymentPlanData = action.payload;
    },

  },
});

export const paymentsSent = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/verify-payments`, payload);
    const responseData = await dispatch(handelResponse(res));
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
}
export const getPaymentPlan = () => async (dispatch) => {
  try {
    const res = await api.get(`/packages`);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(setPaymentPlanData(responseData?.data))
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const handelResponse = (res) => async (dispatch) => {
  let returnValue = null;
  const status = res?.status;
  switch (status) {
    case 200:
      returnValue = { ...res, data: res?.data ? res?.data : {} };
      break;
    case 400:
      dispatch(throwError(res?.message || "Error: Please try again!"));
      returnValue = {
        status: status,
        message: res?.message,
      };
      break;
    default:
      throwError("Error: Please try again!");
      returnValue = {
        status: status,
        message: "Error: Please try again!",
      };
      break;
  }
  return returnValue;
};
export const handelCatch = (error) => async (dispatch, getState) => {
  let status = error?.response?.status;
  let messsage = error?.response?.data?.error || "Error: Please try again!";
  let returnCatch = {
    status: status,
    data: error?.response?.data?.errors,
    messsage: messsage,
  };
  if (status === 401 && !getState().global.isSessionExpires) {
    dispatch(handelLogout())
    dispatch(setSessionExpired(true));
  } else {
    dispatch(
      setErrorData({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  }
  return returnCatch;
};
export const showSuccess = (message) => async (dispatch) => {
  dispatch(
    setErrorData({
      show: true,
      message: message,
      type: "success",
    })
  );
};
export const throwError = (message) => async (dispatch) => {
  dispatch(
    setErrorData({
      show: true,
      message: message,
      type: "danger",
    })
  );
};
export const { setErrorData, setSessionExpired, setIsCitetion, setPrimeMemberModal, setPrimeMemberJoin, setPrimeCoin, setPaymentPlanData } =
  globalSlice.actions;

export default globalSlice.reducer;
