import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import dummyBannerImg from "assets/images/common/dummyBanner.jpg";
import { icons } from "utils/constants";
import { capitalize } from "lodash";
import Button from "components/inputs/Button";
import StarRatings from "components/layouts/StarRatings";
import RatingCard from "components/layouts/RatingCard";
import CopyContactDetailsModal from "components/layouts/CopyContactDetailsModal/CopyContactDetailsModal";
import {
  convertDescription,
  downloadURI,
  getDataFromLocalStorage,
} from "utils/helpers";
import "./RightProfileSection.scss";
import SocialLinkButton from "components/layouts/SocialLinknButton/SocialLinkButton";
import { handleSocketLister } from "store/slices/connectSlice";
import { useSocket } from "context/socket";
import { Blurhash } from "react-blurhash";

const RightProfileSection = ({
  profileImg,
  logoImg,
  imageUrl,
  logoHash,
  bannerHash,
}) => {
  const profileRDX = useSelector((state) => state.profile);
  const { userData, servicesList, ratingList } = profileRDX || {};
  const [contactDetails, setContactDetails] = useState([]);
  const [isDetailModal, setIsDetailModal] = useState(false);
  const UserRatingNumber = getDataFromLocalStorage("UserRatingNumber");
  const connectRDX = useSelector((state) => state.connect);
  const { connectList } = connectRDX || {};
  const totalConnect = connectList?.filter((ele) => ele?.connect?.status === 4);

  const dispatch = useDispatch();

  const socketContext = useSocket();
  const { socket } = socketContext.state;
  const ShowContactDetail = async (key) => {
    setContactDetails(
      userData?.businessCard?.contactDetail?.socialLinks[key]?.data
    );
    if (
      userData?.businessCard?.contactDetail?.socialLinks[key]?.data.length >
        1 ||
      key === "phone"
    ) {
      setIsDetailModal(true);
    } else if (key === "mail") {
      const formattedLink = userData?.businessCard?.contactDetail?.socialLinks[
        key
      ]?.data?.[0]?.includes("mailto:")
        ? userData?.businessCard?.contactDetail?.socialLinks[key]?.data?.[0]
        : `mailto:${userData?.businessCard?.contactDetail?.socialLinks[key]?.data?.[0]}`;
      downloadURI(formattedLink);
    } else {
      const formattedLink = userData?.businessCard?.contactDetail?.socialLinks[
        key
      ]?.data?.[0]?.includes("https://")
        ? userData?.businessCard?.contactDetail?.socialLinks[key]?.data?.[0]
        : `https://${userData?.businessCard?.contactDetail?.socialLinks[key]?.data?.[0]}`;
      downloadURI(formattedLink);
    }
  };
  //for rating slider
  const swiperRef = useRef(null);

  const goPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };
  useEffect(() => {
    if (socket) {
      dispatch(handleSocketLister(socket)); // socket listener events
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  useEffect(() => {}, [ratingList]);
  return (
    <div className="col-4" id="rightprofilesection-id">
      <div className="left-sidebar">
        <div className="bg-shadow-wrap">
          <img
            className="user-bg-image"
            src={
              userData?.userIntroduction?.profileMedia
                ? profileImg
                : dummyBannerImg
            }
            alt=""
          />
        </div>
        <div className="user-service-content">
          <div className="service-content-top">
            <div className="fc-center flex-column">
              {userData?.userIntroduction?.coverMedia ? (
                <div className="rounded-circle position-relative">
                  {userData.prime ? (
                    <img src={icons.primeumRing} alt="ring" />
                  ) : (
                    <img src={icons.photoRingIcon} alt="ring" />
                  )}
                  {logoImg ? (
                    <img
                      src={logoImg}
                      alt={logoImg ? "profile" : null}
                      className="left-profile-img"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        overflow: "hidden",
                        display: "inline-block",
                        height: " 134px",
                      }}
                      className="left-profile-img"
                    >
                      <Blurhash
                        hash={logoHash}
                        width={135}
                        height={135}
                        punch={1}
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="logo-img"></div>
              )}

              <p className="text-27-600 ">
                {userData?.name}
                {/* <span className="mx-2">
                  <img src={icons.blueCheckBudgeIcon} alt="" />
                </span> */}
              </p>
              <p className="text-14-400 color-grey-250 mb-0 text-center">
                {capitalize(userData?.userIntroduction?.headline)}
              </p>
              <p className="text-14-400 color-grey-A6 mb-0">
                {capitalize(userData?.city)}, {capitalize(userData?.state)},{" "}
                {capitalize(userData?.country)}
              </p>
              <div className="fc-center mt-3">
                {userData?.recentConnects?.length ? (
                  <>
                    <div className="fc-center">
                      <div className="connected-profile-group">
                        {userData?.recentConnects
                          ?.slice(0, 3)
                          ?.map((ele, i) => {
                            return (
                              <span
                                className={`connected-profile-img${i + 1}`}
                                key={i}
                              >
                                {ele?.hashed ? (
                                  <img
                                    src={`${ele.cdn_url}/${ele.thumbnail}`}
                                    alt="user-img"
                                  />
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "50%",
                                      overflow: "hidden",
                                      display: "inline-block",
                                      height: "30px",
                                    }}
                                  >
                                    <Blurhash
                                      hash={ele?.hashed}
                                      width={32}
                                      height={32}
                                      punch={1}
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </div>
                                )}
                              </span>
                            );
                          })}
                      </div>
                    </div>
                  </>
                ) : null}
                <p className="text-28-600 mb-0 mx-2">
                  <>{totalConnect?.length || 0}</>
                </p>
              </div>
              <p className="text-15-400 color-grey-A6">connections</p>
            </div>

            {userData?.businessCard?.contactDetail?.socialLinks ? (
              <>
                <SocialLinkButton
                  ShowContactDetail={ShowContactDetail}
                  imageUrl={imageUrl}
                  isshow={true}
                  userData={userData}
                  isButtonShow={true}
                  bannerHash={bannerHash}
                  bannerf={true}
                />
              </>
            ) : null}
            {isDetailModal && (
              <CopyContactDetailsModal
                data={contactDetails}
                onClose={() => {
                  setIsDetailModal(false);
                  setContactDetails([]);
                }}
              />
            )}
            {/*------------------ offer details start --------------------*/}
            {servicesList?.map((ele, i) => {
              return (
                <React.Fragment key={i}>
                  <p className="text-17-700">What we offer</p>
                  <div className="offer-img1 my-4">
                    {ele?.media ? (
                      <img
                        src={
                          ele?.media
                            ? ele?.media?.cdn_url + "/" + ele?.media?.path
                            : dummyBannerImg
                        }
                        alt="Banner"
                      />
                    ) : (
                      <div
                        className="cmt-10"
                        style={{
                          borderRadius: "10px",
                          overflow: "hidden",
                          display: "inline-block",
                          width: "100%",
                          height: "300px",
                        }}
                      >
                        <Blurhash
                          hash={ele?.media?.hashed}
                          punch={1}
                          style={{
                            borderRadius: "10px",
                            width: "100%",
                            height: "300px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="fb-center my-2">
                    <div>
                      <p className="mb-0 text-21-600">{ele?.title}</p>
                      {ele?.price && (
                        <p className="text-18-600 color-grey-B8">
                          {ele?.price}
                        </p>
                      )}
                    </div>
                    {JSON.parse(ele?.enquireButton) === 1 && (
                      <Button
                        btnText="Enquiry"
                        btnStyle="Rounded"
                        className="bg-76E3 text-17-400 cps-24 cpe-24 cpt-8 cpb-8 "
                        disabled
                      />
                    )}
                  </div>
                  <p
                    className="my-3 text-14-400 color-grey-97"
                    dangerouslySetInnerHTML={{
                      __html: `${convertDescription(ele?.desc)}`,
                    }}
                  ></p>
                  {i < servicesList?.length - 1 ? (
                    <div className="bb-grey-7878 w-100"></div>
                  ) : null}
                </React.Fragment>
              );
            })}
            {/*------------------ offer details end --------------------*/}
            {/*-------------------- ask question form -----------------*/}

            {/*-------------------- ask question form end -----------------*/}
            {userData?.businessCard?.ratings?.count ? (
              <div className="bg-2531 br-11 cp-15 mb-3 mt-5">
                <div className="fb-center flex-nowrap">
                  <p className="text-18-400">Your ratings</p>
                  <StarRatings
                    rating={userData?.businessCard?.ratings?.avg}
                    width={24}
                    height={24}
                  />
                </div>
                <p className="text-14-400 color-grey-96">
                  {UserRatingNumber} people rated you
                </p>
              </div>
            ) : null}
            {/*-----------------rating slider--------------- */}
            <div className="Rating-slider">
              <Swiper
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                modules={[Navigation]}
                spaceBetween={20}
                className="mySwiper"
                ref={swiperRef}
                clickedindex={1}
              >
                {ratingList?.map((ele, i) => (
                  <SwiperSlide key={i}>
                    <RatingCard ratingData={ele} />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="fb-center my-2">
                {ratingList.length ? (
                  <>
                    <button
                      className="pointer color-A6F6 text-11-400"
                      onClick={goPrev}
                    >
                      Previous
                    </button>
                    <button
                      className="pointer color-A6F6 text-11-400"
                      onClick={goNext}
                    >
                      Next
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RightProfileSection;
