import { createSlice } from '@reduxjs/toolkit'
import { handelCatch, handelResponse } from './globalSlice';
import { api } from 'services/api';

const initialState = {
  socialCategories: [],
  imageListByCategory: [],
  isCtgImagesLoading: false,
  isGoogleFont: [],
}


const greetingSlice = createSlice({
  name: "greeting",
  initialState,
  reducers: {
    resetGreetingState: (state) => initialState,
    storeSocialCategories(state, action) {
      state.socialCategories = action.payload;
    },
    storeImageListByCategory(state, action) {
      state.imageListByCategory = action.payload;
    },
    storeCtgImagesLoading(state, action) {
      state.isCtgImagesLoading = action.payload;
    },
    storeGoogleFont(state, action) {
      state.isGoogleFont = action.payload;
    },
  }
});
export const { resetGreetingState, storeSocialCategories, storeImageListByCategory, storeCtgImagesLoading, storeGoogleFont } = greetingSlice.actions
export const getSocialCategories = () => async (dispatch) => {
  try {
    const res = await api.get(`/social-greeting-categories`);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeSocialCategories(responseData?.data?.categories));
    }
    return responseData
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getImagesByCategory = (id) => async (dispatch) => {
  try {
    dispatch(storeCtgImagesLoading(true))
    const res = await api.get(`/social-greeting-categories/${id}`);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeImageListByCategory(responseData?.data?.socialGreetings));
    }
    dispatch(storeCtgImagesLoading(false))
    return responseData
  } catch (error) {
    dispatch(storeCtgImagesLoading(false))
    return dispatch(handelCatch(error));
  }
};
export const getTodaysGreetingImages = () => async (dispatch) => {
  try {
    dispatch(storeCtgImagesLoading(true))
    const res = await api.get(`/today-social-greetings`);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeImageListByCategory([responseData?.data?.socialGreeting]));

    }
    dispatch(storeCtgImagesLoading(false))
    return responseData
  } catch (error) {
    dispatch(storeCtgImagesLoading(false))
    return dispatch(handelCatch(error));
  }
};
export const getGoogleFont = () => async (dispatch) => {
  try {
    const response = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONT_KEY}`);
    const data = await response.json();
    const fontFamilyList = data.items.map(font => ({
      id: `'${font.family}', ${font.category}`,
      label: font.family,
    }));

    return dispatch(storeGoogleFont(fontFamilyList));
  } catch (error) {
    console.error('Error fetching Google Fonts:', error);
    return [];
  }
};
export default greetingSlice.reducer