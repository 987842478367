import React, { useEffect, useRef } from 'react';
import "./Layout.scss";
import SideBar from 'components/layouts/SideBar'
import NavBar from 'components/layouts/NavBar'
import { useSocket } from 'context/socket';

const Layout = ({id,text,isNotification, children}) => {
  const socketContext = useSocket();
  const isFirstRenderRef = useRef(true);

  const { socket } = socketContext.state;
  useEffect(() => {
    // if (isFirstRenderRef.current && socket) {
      socket?.emit("connects", {});
      isFirstRenderRef.current = false;
    // }
 // eslint-disable-next-line
  }, [socket]);
  return (
      <div id='layout-container'>
          <SideBar />
          <main id={id}>
              <NavBar text={text} isNotification={isNotification} />
              {children}
          </main>
    
    </div>
  )
}

export default Layout