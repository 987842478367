import React from "react";
import "./SocialLinkButton.scss";
import { icons } from "utils/constants";
import { Player } from "@lottiefiles/react-lottie-player";
import { convertDescription } from "utils/helpers";
import { Blurhash } from "react-blurhash";

const SocialLinkButton = ({
  ShowContactDetail,
  imageUrl,
  isshow,
  userData,
  isButtonShow,
  bannerHash,
  bannerf,
}) => {
  const linkSeq = [
    "phone",
    "whatsapp",
    "mail",
    "website",
    "linkedin",
    "instagram",
    "facebook",
    "location",
    "youtube",
    "telegram",
    "vimeo",
    "twitter",
    "upi",
    "messenger",
    "skype",
    "artstation",
    "behance",
    "github",
    "deviantart",
    "dribble",
    "slack",
    "wechat",
    "qq",
    "tumblr",
    "qzone",
    "reddit",
    "tiktok",
    "baidutieda",
    "viber",
    "snapchat",
    "line",
    "medium",
    "signal",
    "pinterest",
    "yelp",
    "venom",
    "paypal",
    "cashapp",
    "dayflash",
    "thedots",
    "quora",
    "xbox",
    "soundcloud",
    "wikipedia",
    "phonpe",
    "gpay",
    "paytm",
    "link",
  ];
  const socialLinks = userData?.businessCard?.contactDetail?.socialLinks;
  const obj = linkSeq.filter(
    (element) => socialLinks && socialLinks[element] !== undefined
  );
  const sortedObj1 = obj
    .sort((a, b) => {
      return linkSeq.indexOf(a) - linkSeq.indexOf(b);
    })
    .slice(0, 8);

  const sortedObj2 = obj.sort((a, b) => {
    return linkSeq.indexOf(a) - linkSeq.indexOf(b);
  });

  const endIndex = sortedObj2?.length - 1;
  const result = sortedObj2?.slice(8, endIndex + 1);
  const defaultBlurhash = "LEHV6nWB2yk8pyo0adR*.7kCMdnj";

  const blurhashToUse =
    bannerHash && bannerHash === 86 ? bannerHash : defaultBlurhash;
  return (
    <>
      <div className="fc-center gap-3 mt-2">
        {sortedObj1?.slice(0, 4)?.map((key, index) => (
          <React.Fragment key={index}>
            {
              {
                phone: (
                  <>
                    {isButtonShow ? (
                      <button
                        className="br-18 cp-15 bg-0086"
                        onClick={() => ShowContactDetail(key)}
                      >
                        <Player
                          autoplay
                          loop
                          src={icons.Phone}
                          style={{ height: "25px", width: "25px" }}
                        />
                      </button>
                    ) : null}
                  </>
                ),
                whatsapp: (
                  <>
                    {isButtonShow ? (
                      <button
                        className="br-18 cp-15 bg-00A"
                        onClick={() => ShowContactDetail(key)}
                      >
                        <Player
                          autoplay
                          loop
                          src={icons.Whatsapp}
                          style={{ height: "25px", width: "25px" }}
                        />
                      </button>
                    ) : null}
                  </>
                ),
                website: (
                  <button
                    className="br-18 cp-15 bg-001F"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Website}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                facebook: (
                  <button
                    className="br-18 cp-15 bg-006D"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.FaceBook}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                mail: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Mail}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                location: (
                  <button
                    className="br-18 cp-15 bg-ED0B"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.location}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                artstation: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.artStationIcon}
                      width={25}
                      height={25}
                      alt="ArtStation"
                    />
                  </button>
                ),
                youtube: (
                  <button
                    className="br-18 cp-15 bg-BF00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Youtube}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                vimeo: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.vimeoIcon}
                      width={25}
                      height={25}
                      alt="Vimeo"
                    />
                  </button>
                ),
                yelp: (
                  <button
                    className="br-18 cp-15 bg-BF22"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.yelpIcon}
                      width={25}
                      height={25}
                      alt="Yelp"
                    />
                  </button>
                ),
                twitter: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Twitter}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                messenger: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.messangerIcon}
                      width={25}
                      height={25}
                      alt="Messenger"
                    />
                  </button>
                ),
                viber: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.viberIcon}
                      width={25}
                      height={25}
                      alt="Viber"
                    />
                  </button>
                ),
                linkedin: (
                  <button
                    className="br-18 cp-15 bg-0096"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Linkedin}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                instagram: (
                  <button
                    className="br-18 cp-15 bg-D725"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Instagram}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                upi: (
                  <button
                    className="br-18 cp-15 bg-02B6"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img src={icons.upiLink} width={25} height={25} alt="Upi" />
                  </button>
                ),
                phonpe: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.PhonePeLink}
                      width={25}
                      height={25}
                      alt="phonpe"
                    />
                  </button>
                ),
                gpay: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.GpayLink}
                      width={25}
                      height={25}
                      alt="gpay"
                    />
                  </button>
                ),
                paytm: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.PaytmLink}
                      width={25}
                      height={25}
                      alt="Paytm"
                    />
                  </button>
                ),
                telegram: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Telegram}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                skype: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.Skypeicon}
                      width={25}
                      height={25}
                      alt="skype"
                    />
                  </button>
                ),
                behance: (
                  <button
                    className="br-18 cp-15 bg-004C"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Behance}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                github: (
                  <button
                    className="br-18 cp-15 bg-0000"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.GithubIcon}
                      width={25}
                      height={25}
                      alt="github"
                    />
                  </button>
                ),
                devianart: (
                  <button
                    className="br-18 cp-15 bg-3145"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.DeviantartIcon}
                      width={25}
                      height={25}
                      alt="devianart"
                    />
                  </button>
                ),
                dribble: (
                  <button
                    className="br-18 cp-15 bg-F019"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Dribble}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                slack: (
                  <button
                    className="br-18 cp-15 bg-8E00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.SlackIcon}
                      width={25}
                      height={25}
                      alt="slack"
                    />
                  </button>
                ),
                wechat: (
                  <button
                    className="br-18 cp-15 bg-04B0"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.WeixinIcon}
                      width={25}
                      height={25}
                      alt="wechat"
                    />
                  </button>
                ),
                qq: (
                  <button
                    className="br-18 cp-15 bg-0024"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img src={icons.QqIcon} width={25} height={25} alt="qq" />
                  </button>
                ),
                tumblr: (
                  <button
                    className="br-18 cp-15 bg-2E3C"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.TumblrIcon}
                      width={25}
                      height={25}
                      alt="tumblr"
                    />
                  </button>
                ),
                qzone: (
                  <button
                    className="br-18 cp-15 bg-F4CD"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.QzoneIcon}
                      width={25}
                      height={25}
                      alt="qzone"
                    />
                  </button>
                ),
                reddit: (
                  <button
                    className="br-18 cp-15 bg-FF00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.RedditIcon}
                      width={25}
                      height={25}
                      alt="reddit"
                    />
                  </button>
                ),
                tiktok: (
                  <button
                    className="br-18 cp-15 bg-0000"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.TiktokIcon}
                      width={25}
                      height={25}
                      alt="tiktok"
                    />
                  </button>
                ),
                baidutieda: (
                  <button
                    className="br-18 cp-15 bg-002F"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.BaiduoIcon}
                      width={25}
                      height={25}
                      alt="baidutieda"
                    />
                  </button>
                ),
                snapchat: (
                  <button
                    className="br-18 cp-15 bg-FFE6"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.SnapchatIcon}
                      width={25}
                      height={25}
                      alt="snapchat"
                    />
                  </button>
                ),
                line: (
                  <button
                    className="br-18 cp-15 bg-32BF"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.LineIcon}
                      width={25}
                      height={25}
                      alt="line"
                    />
                  </button>
                ),
                medium: (
                  <button
                    className="br-18 cp-15 bg-0000"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Medium}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                signal: (
                  <button
                    className="br-18 cp-15 bg-0068 "
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.SignalMessengerIcon}
                      width={25}
                      height={25}
                      alt="signal"
                    />
                  </button>
                ),
                pinterest: (
                  <button
                    className="br-18 cp-15 bg-BF00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Pintrest}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                venom: (
                  <button
                    className="br-18 cp-15 bg-0000"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img src={icons.Venom} width={25} height={25} alt="venom" />
                  </button>
                ),
                paypal: (
                  <button
                    className="br-18 cp-15 bg-0062"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.PaypalIcon}
                      width={25}
                      height={25}
                      alt="paypal"
                    />
                  </button>
                ),
                cashapp: (
                  <button
                    className="br-18 cp-15 bg-00CB"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.CashAppIcon}
                      width={25}
                      height={25}
                      alt="cashapp"
                    />
                  </button>
                ),
                dayflash: (
                  <button
                    className="br-18 cp-15 bg-0041"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.DayFlash}
                      width={25}
                      height={25}
                      alt="dayflash"
                    />
                  </button>
                ),
                thedots: (
                  <button
                    className="br-18 cp-15 bg-D804"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.thedots}
                      width={25}
                      height={25}
                      alt="thedots"
                    />
                  </button>
                ),
                quora: (
                  <button
                    className="br-18 cp-15 bg-D70D"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.QuoraIcon}
                      width={25}
                      height={25}
                      alt="quora"
                    />
                  </button>
                ),
                xbox: (
                  <button
                    className="br-18 cp-15 bg-007C"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.XboxIcon}
                      width={25}
                      height={25}
                      alt="xbox"
                    />
                  </button>
                ),
                soundcloud: (
                  <button
                    className="br-18 cp-15 bg-EA62"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.SoundCloudIcon}
                      width={25}
                      height={25}
                      alt="soundcloud"
                    />
                  </button>
                ),
                wikipedia: (
                  <button
                    className="br-18 cp-15 bg-572A"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.WikipediaIcon}
                      width={25}
                      height={25}
                      alt="wikipedia"
                    />
                  </button>
                ),
                link: (
                  <button
                    className="br-18 cp-15 bg-1B00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.LinkSolidIcon}
                      width={25}
                      height={25}
                      alt="link"
                    />
                  </button>
                ),
              }[key.replace("-", "")]
            }
          </React.Fragment>
        ))}
      </div>
      <div className="fc-center gap-3 mt-3  flex-wrap">
        {sortedObj2?.slice(4, 8)?.map((key, index) => (
          <React.Fragment key={index}>
            {
              {
                phone: (
                  <>
                    {isButtonShow ? (
                      <button
                        className="br-18 cp-15 bg-0086"
                        onClick={() => ShowContactDetail(key)}
                      >
                        <Player
                          autoplay
                          loop
                          src={icons.Phone}
                          style={{ height: "25px", width: "25px" }}
                        />
                      </button>
                    ) : null}
                  </>
                ),
                whatsapp: (
                  <>
                    {isButtonShow ? (
                      <button
                        className="br-18 cp-15 bg-00A"
                        onClick={() => ShowContactDetail(key)}
                      >
                        <Player
                          autoplay
                          loop
                          src={icons.Whatsapp}
                          style={{ height: "25px", width: "25px" }}
                        />
                      </button>
                    ) : null}
                  </>
                ),
                website: (
                  <button
                    className="br-18 cp-15 bg-001F"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Website}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                facebook: (
                  <button
                    className="br-18 cp-15 bg-006D"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.FaceBook}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                mail: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Mail}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                location: (
                  <button
                    className="br-18 cp-15 bg-ED0B"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.location}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                artstation: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.artStationIcon}
                      width={25}
                      height={25}
                      alt="ArtStation"
                    />
                  </button>
                ),
                youtube: (
                  <button
                    className="br-18 cp-15 bg-BF00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Youtube}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                vimeo: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.vimeoIcon}
                      width={25}
                      height={25}
                      alt="Vimeo"
                    />
                  </button>
                ),
                yelp: (
                  <button
                    className="br-18 cp-15 bg-BF22"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.yelpIcon}
                      width={25}
                      height={25}
                      alt="Yelp"
                    />
                  </button>
                ),
                twitter: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Twitter}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                messenger: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.messangerIcon}
                      width={25}
                      height={25}
                      alt="Messenger"
                    />
                  </button>
                ),
                viber: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.viberIcon}
                      width={25}
                      height={25}
                      alt="Viber"
                    />
                  </button>
                ),
                linkedin: (
                  <button
                    className="br-18 cp-15 bg-0096"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Linkedin}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                instagram: (
                  <button
                    className="br-18 cp-15 bg-D725"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Instagram}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                upi: (
                  <button
                    className="br-18 cp-15 bg-02B6"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img src={icons.upiLink} width={25} height={25} alt="Upi" />
                  </button>
                ),
                phonpe: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.PhonePeLink}
                      width={25}
                      height={25}
                      alt="phonpe"
                    />
                  </button>
                ),
                gpay: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.GpayLink}
                      width={25}
                      height={25}
                      alt="gpay"
                    />
                  </button>
                ),
                paytm: (
                  <button
                    className="br-18 cp-15 bg-58A9"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.PaytmLink}
                      width={25}
                      height={25}
                      alt="Paytm"
                    />
                  </button>
                ),
                telegram: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Telegram}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                skype: (
                  <button
                    className="br-18 cp-15 bg-0086"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.Skypeicon}
                      width={25}
                      height={25}
                      alt="skype"
                    />
                  </button>
                ),
                behance: (
                  <button
                    className="br-18 cp-15 bg-004C"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Behance}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                github: (
                  <button
                    className="br-18 cp-15 bg-0000"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.GithubIcon}
                      width={25}
                      height={25}
                      alt="github"
                    />
                  </button>
                ),
                deviantart: (
                  <button
                    className="br-18 cp-15 bg-3145"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.DeviantartIcon}
                      width={25}
                      height={25}
                      alt="devianart"
                    />
                  </button>
                ),
                dribble: (
                  <button
                    className="br-18 cp-15 bg-F019"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Dribble}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                slack: (
                  <button
                    className="br-18 cp-15 bg-8E00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.SlackIcon}
                      width={25}
                      height={25}
                      alt="slack"
                    />
                  </button>
                ),
                wechat: (
                  <button
                    className="br-18 cp-15 bg-04B0"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.WeixinIcon}
                      width={25}
                      height={25}
                      alt="wechat"
                    />
                  </button>
                ),
                qq: (
                  <button
                    className="br-18 cp-15 bg-0024"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img src={icons.QqIcon} width={25} height={25} alt="qq" />
                  </button>
                ),
                tumblr: (
                  <button
                    className="br-18 cp-15 bg-2E3C"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.TumblrIcon}
                      width={25}
                      height={25}
                      alt="tumblr"
                    />
                  </button>
                ),
                qzone: (
                  <button
                    className="br-18 cp-15 bg-F4CD"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.QzoneIcon}
                      width={25}
                      height={25}
                      alt="qzone"
                    />
                  </button>
                ),
                reddit: (
                  <button
                    className="br-18 cp-15 bg-FF00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.RedditIcon}
                      width={25}
                      height={25}
                      alt="reddit"
                    />
                  </button>
                ),
                tiktok: (
                  <button
                    className="br-18 cp-15 bg-0000"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.TiktokIcon}
                      width={25}
                      height={25}
                      alt="tiktok"
                    />
                  </button>
                ),
                baidutieda: (
                  <button
                    className="br-18 cp-15 bg-002F"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.BaiduoIcon}
                      width={25}
                      height={25}
                      alt="baidutieda"
                    />
                  </button>
                ),
                snapchat: (
                  <button
                    className="br-18 cp-15 bg-FFE6"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.SnapchatIcon}
                      width={25}
                      height={25}
                      alt="snapchat"
                    />
                  </button>
                ),
                line: (
                  <button
                    className="br-18 cp-15 bg-32BF"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.LineIcon}
                      width={25}
                      height={25}
                      alt="line"
                    />
                  </button>
                ),
                medium: (
                  <button
                    className="br-18 cp-15 bg-0000"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Medium}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                signal: (
                  <button
                    className="br-18 cp-15 bg-0068 "
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.SignalMessengerIcon}
                      width={25}
                      height={25}
                      alt="signal"
                    />
                  </button>
                ),
                pinterest: (
                  <button
                    className="br-18 cp-15 bg-BF00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <Player
                      autoplay
                      loop
                      src={icons.Pintrest}
                      style={{ height: "25px", width: "25px" }}
                    />
                  </button>
                ),
                venom: (
                  <button
                    className="br-18 cp-15 bg-0000"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img src={icons.Venom} width={25} height={25} alt="venom" />
                  </button>
                ),
                paypal: (
                  <button
                    className="br-18 cp-15 bg-0062"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.PaypalIcon}
                      width={25}
                      height={25}
                      alt="paypal"
                    />
                  </button>
                ),
                cashapp: (
                  <button
                    className="br-18 cp-15 bg-00CB"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.CashAppIcon}
                      width={25}
                      height={25}
                      alt="cashapp"
                    />
                  </button>
                ),
                dayflash: (
                  <button
                    className="br-18 cp-15 bg-0041"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.DayFlash}
                      width={25}
                      height={25}
                      alt="dayflash"
                    />
                  </button>
                ),
                thedots: (
                  <button
                    className="br-18 cp-15 bg-D804"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.thedots}
                      width={25}
                      height={25}
                      alt="thedots"
                    />
                  </button>
                ),
                quora: (
                  <button
                    className="br-18 cp-15 bg-D70D"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.QuoraIcon}
                      width={25}
                      height={25}
                      alt="quora"
                    />
                  </button>
                ),
                xbox: (
                  <button
                    className="br-18 cp-15 bg-007C"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.XboxIcon}
                      width={25}
                      height={25}
                      alt="xbox"
                    />
                  </button>
                ),
                soundcloud: (
                  <button
                    className="br-18 cp-15 bg-EA62"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.SoundCloudIcon}
                      width={25}
                      height={25}
                      alt="soundcloud"
                    />
                  </button>
                ),
                wikipedia: (
                  <button
                    className="br-18 cp-15 bg-572A"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.WikipediaIcon}
                      width={25}
                      height={25}
                      alt="wikipedia"
                    />
                  </button>
                ),
                link: (
                  <button
                    className="br-18 cp-15 bg-1B00"
                    onClick={() => ShowContactDetail(key)}
                  >
                    <img
                      src={icons.LinkSolidIcon}
                      width={25}
                      height={25}
                      alt="link"
                    />
                  </button>
                ),
              }[key.replace("-", "")]
            }
          </React.Fragment>
        ))}
      </div>
      <>
        {userData?.bannerMedia ? (
          <div className="banner-w-h cpt-10">
            <img
              src={
                userData?.bannerMedia?.cdn_url +
                "/" +
                userData?.bannerMedia?.path
              }
              alt="bannerimage"
            />
          </div>
        ) : (
          <div>
            {bannerf === "true" ? (
              <div
                className="cmt-10"
                style={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  display: "inline-block",
                  width: "100%",
                  height: "250px",
                }}
              >
                <Blurhash
                  hash={blurhashToUse}
                  punch={1}
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "250px",
                  }}
                />
              </div>
            ) : null}
          </div>
        )}
      </>

      <p
        className="my-3 text-14-400 color-grey-97"
        dangerouslySetInnerHTML={{
          __html: `${convertDescription(
            userData?.userIntroduction?.description
          )}`,
        }}
      ></p>

      {isshow && (
        <>
          {result?.map((key, i) => {
            let socialLinks =
              userData?.businessCard?.contactDetail?.socialLinks?.[key]
                ?.heading;
            return (
              <div className=" gradient-blue-1 my-3 br-18" key={i}>
                <button
                  className="fc-center cpt-22 cpb-22 w-100 color-ffff text-18-400 bg-2531 br-18"
                  onClick={() => ShowContactDetail(key)}
                >
                  {socialLinks}
                </button>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default SocialLinkButton;
