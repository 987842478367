import React, { useEffect } from "react";
import "./Congratulation.scss";
import goldCoinImg from "assets/images/common/gold-coin.png";
import playStoreImg from "assets/images/common/play-store.png";
import appStoreImg from "assets/images/common/app-store.png";
import { useDispatch } from "react-redux";
import { getUserData, handleLogin } from "store/slices";
import { getDataFromLocalStorage } from "utils/helpers";
import Confiette from "assets/images/icons/confiette.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Button from "components/inputs/Button";
const Congratulation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(async () => {
      const payload = {
        contact: getDataFromLocalStorage("contact"),
        country_code: getDataFromLocalStorage("countryCode"),
        pin: getDataFromLocalStorage("pin"),
      };
      const res = await dispatch(handleLogin(payload));
      if (res?.data?.user !== null) {
        dispatch(getUserData(res?.data?.user?.id));
      }
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginData = async () => {
    const payload = {
      contact: getDataFromLocalStorage("contact"),
      country_code: getDataFromLocalStorage("countryCode"),
      pin: getDataFromLocalStorage("pin"),
    };
    const res = await dispatch(handleLogin(payload));
    if (res?.data?.user !== null) {
      dispatch(getUserData(res?.data?.user?.id));
    }
  };
  return (
    <div id="congrats-container">
      <span className="yellow-dot">
        <div className="w-15 h-15 rounded-circle bg-BC00"></div>
      </span>
      <div className="container">
        <div className="row">
          <div className="col-6 mx-auto  color-ffff text-center">
            <h1 className="text-53-750 my-5">Congratulations</h1>
            <h2 className="text-30-750 my-3">Signup bonus</h2>
            <div className="image-animation">
              <p className="text-90-700 ">3000</p>
              <Player
                autoplay
                loop
                src={Confiette}
                style={{ height: "500px", width: "500px" }}
                className="image-absolute"
              />
            </div>
            <p className="text-16-500 mb-5">GRID COINS!</p>
            <p className="text-16-400 color-grey-300  mb-2">
              Your total coins are
            </p>
            <span className="gold-coin-block">
              <img src={goldCoinImg} alt="coin" />
              <span className="text-22-500">3000</span>
            </span>
            <div className="fc-center cmt-30">
              <Button
                btnText="Go to dashboard"
                btnStyle="dashboard-btn"
                onClick={handleLoginData}
              />
            </div>
            <p className="text-20-400 mt-5 ">
              You can download the app and login to network like a pro!
            </p>
            <div className="fc-center">
              <img
                src={playStoreImg}
                alt="play-store"
                height={88}
                width={300}
              />
              <img src={appStoreImg} alt="app-store" height={88} width={300} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
