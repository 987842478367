import React, { useEffect, useState } from "react";
import "./AddLinks.scss";
import { commonRoute, icons } from "utils/constants";
import TextInput from "components/inputs/TextInput";
import Button from "components/inputs/Button";
import { useNavigate } from "react-router-dom";
import { getDataFromLocalStorage, storeLocalStorageData } from "utils/helpers";
import { useDispatch } from "react-redux";
// import { getLinksList } from "store/slices";
import Modal from "components/layouts/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateBusinessCards } from "store/slices";
const AddLinks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { links } = useSelector((state) => ({
  //   links: state.auth.links,
  // }));
  const [isAddModal, setIsAddModal] = useState(false);
  const refactorLinksList = async () => {
    const socialLinks = getDataFromLocalStorage("socialLinks");
    if (socialLinks) {
      const tempList = [];
      for (const key in socialLinks) {
        if (Object.hasOwnProperty.call(socialLinks, key)) {
          const element = socialLinks[key];
          if (element) {
            const matchIconData = linkOptions.find((elem) => elem.id === key);
            const linkObj = {
              name: key,
              url: element?.data[0],
              iconName: matchIconData?.iconName,
              notes: element?.heading,
            };
            tempList.push(linkObj);
          }
        }
      }
      setLinksList(tempList);
    }
  };
  useEffect(() => {
    refactorLinksList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [linksList, setLinksList] = useState([]);
  const [linkOptions, setLinkOptions] = useState([
    {
      name: "Add Links",
      iconName: icons.addIcon,
      isSelected: false,
      id: "new",
    },
    {
      name: "Call",
      iconName: icons.callIcon,
      isSelected: false,
      id: "phone",
      defaultValue: "add a link",
      followMe: " Follow me on connect",
    },
    {
      name: "Website",
      iconName: icons.websiteIcon,
      isSelected: false,
      id: "website",
      defaultValue: "www.",
      followMe: " Follow me on Website",
    },
    {
      name: "Email",
      iconName: icons.emailIcon,
      isSelected: false,
      id: "mail",
      defaultValue: "add a link",
      followMe: " Follow me on mail",
    },
    {
      name: "Whatsapp",
      iconName: icons.whatsAppIcon,
      isSelected: false,
      id: "whatsapp",
      defaultValue: "wa.me/+91",
      followMe: " Follow me on whatsapp",
    },
    {
      name: "Location",
      iconName: icons.locationIcon,
      isSelected: false,
      id: "location",
      defaultValue: "add a link",
      followMe: " Follow me on location",
    },
    {
      name: "Linkedin",
      iconName: icons.linkedInWhiteIcon,
      isSelected: false,
      id: "linkedin",
      defaultValue: "linkedin.com/in/",
      followMe: " Follow me on linkedin",
    },
    {
      name: "Art Station",
      iconName: icons.artStationIcon,
      isSelected: false,
      id: "art-station",
      defaultValue: "add a link",
      followMe: " Follow me on art-station",
    },
    {
      name: "Youtube",
      iconName: icons.youtubeIcon,
      isSelected: false,
      id: "youtube",
      defaultValue: "youtube.com/",
      followMe: " Follow me on youtube",
    },
    {
      name: "Vimeo",
      iconName: icons.vimeoIcon,
      isSelected: false,
      id: "vimeo",
      defaultValue: "vimeo.com/",
      followMe: " Follow me on vimeo",
    },
    {
      name: "Facebook",
      iconName: icons.facebookIcon,
      isSelected: false,
      id: "facebook",
      defaultValue: "facebook.com/",
      followMe: " Follow me on facebook",
    },
    {
      name: "Yelp",
      iconName: icons.yelpIcon,
      isSelected: false,
      id: "yelp",
      defaultValue: "yelp.com/",
      followMe: " Follow me on yelp",
    },
    {
      name: "Twitter",
      iconName: icons.twitterIcon,
      isSelected: false,
      id: "twitter",
      defaultValue: "twitter.com/",
      followMe: " Follow me on twitter",
    },
    {
      name: "instagram",
      iconName: icons.istagramIcon,
      isSelected: false,
      id: "instagram",
      defaultValue: "instagram.com/",
      followMe: " Follow me on instagram",
    },
    {
      name: "Messenger",
      iconName: icons.messangerIcon,
      isSelected: false,
      id: "messenger",
      defaultValue: "add a link",
      followMe: " Follow me on messenger",
    },
    {
      name: "Viber",
      iconName: icons.viberIcon,
      isSelected: false,
      id: "viber",
      defaultValue: "add a link",
      followMe: " Follow me on viber",
    },
    {
      name: "Upi",
      iconName: icons.upiLink,
      isSelected: false,
      id: "upi",
      defaultValue: "add a link",
      followMe: " Follow me on upi",
    },
    {
      name: "PhonPe",
      iconName: icons.PhonePeLink,
      isSelected: false,
      id: "phonpe",
      defaultValue: "add a link",
      followMe: " Follow me on phonpe",
    },
    {
      name: "GPay",
      iconName: icons.GpayLink,
      isSelected: false,
      id: "gpay",
      defaultValue: "add a link",
      followMe: " Follow me on gpay",
    },
    {
      name: "Paytm",
      iconName: icons.PaytmLink,
      isSelected: false,
      id: "paytm",
      defaultValue: "add a link",
      followMe: " Follow me on paytm",
    },
    {
      name: "telegram",
      iconName: icons.TelegramIcon,
      isSelected: false,
      id: "telegram",
      defaultValue: "telegram.me/",
      followMe: " Follow me on telegram",
    },
    {
      name: "skype",
      iconName: icons.Skypeicon,
      isSelected: false,
      id: "skype",
      defaultValue: "add a link",
      followMe: " Follow me on skype",
    },
    {
      name: "behance",
      iconName: icons.Behanceicon,
      isSelected: false,
      id: "behance",
      defaultValue: "behance.net/",
      followMe: " Follow me on behance",
    },
    {
      name: "github",
      iconName: icons.GithubIcon,
      isSelected: false,
      id: "github",
      defaultValue: "add a link",
      followMe: " Follow me on github",
    },
    {
      name: "devianart",
      iconName: icons.DeviantartIcon,
      isSelected: false,
      id: "devianart",
      defaultValue: "add a link",
      followMe: " Follow me on devianart",
    },
    {
      name: "dribble",
      iconName: icons.DribbbleIcon,
      isSelected: false,
      id: "dribble",
      defaultValue: "add a link",
      followMe: " Follow me on dribble",
    },
    {
      name: "slack",
      iconName: icons.SlackIcon,
      isSelected: false,
      id: "slack",
      defaultValue: "add a link",
      followMe: " Follow me on slack",
    },
    {
      name: "wechat",
      iconName: icons.WeixinIcon,
      isSelected: false,
      id: "wechat",
      defaultValue: "add a link",
      followMe: " Follow me on wechat",
    },
    {
      name: "qq",
      iconName: icons.QqIcon,
      isSelected: false,
      id: "qq",
      defaultValue: "add a link",
      followMe: " Follow me on qq",
    },
    {
      name: "tumblr",
      iconName: icons.TumblrIcon,
      isSelected: false,
      id: "tumblr",
      defaultValue: "tumblr.com/",
      followMe: " Follow me on tumblr",
    },
    {
      name: "qzone",
      iconName: icons.QzoneIcon,
      isSelected: false,
      id: "qzone",
      defaultValue: "add a link",
      followMe: " Follow me on qzone",
    },
    {
      name: "reddit",
      iconName: icons.RedditIcon,
      isSelected: false,
      id: "reddit",
      defaultValue: "add a link",
      followMe: " Follow me on reddit",
    },
    {
      name: "tiktok",
      iconName: icons.TiktokIcon,
      isSelected: false,
      id: "tiktok",
      defaultValue: "tiktok.com/",
      followMe: " Follow me on tiktok",
    },
    {
      name: "baidutieda",
      iconName: icons.BaiduoIcon,
      isSelected: false,
      id: "baidutieda",
      defaultValue: "add a link",
      followMe: " Follow me on baidutieda",
    },
    {
      name: "snapchat",
      iconName: icons.SnapchatIcon,
      isSelected: false,
      id: "snapchat",
      defaultValue: "snapchat.com/add/",
      followMe: " Follow me on snapchat",
    },
    {
      name: "line",
      iconName: icons.LineIcon,
      isSelected: false,
      id: "line",
      defaultValue: "add a link",
      followMe: " Follow me on line",
    },
    {
      name: "medium",
      iconName: icons.MediumIcon,
      isSelected: false,
      id: "medium",
      defaultValue: "add a link",
      followMe: " Follow me on medium",
    },
    {
      name: "signal",
      iconName: icons.SignalMessengerIcon,
      isSelected: false,
      id: "signal",
      defaultValue: "Add a link",
      followMe: " Follow me on signal",
    },
    {
      name: "pinterest",
      iconName: icons.PinterestIcon,
      isSelected: false,
      id: "pinterest",
      defaultValue: "pinterest.com/",
      followMe: " Follow me on pinterest",
    },
    {
      name: "venom",
      iconName: icons.Venom,
      isSelected: false,
      id: "venom",
      defaultValue: "Add a link",
      followMe: " Follow me on venom",
    },
    {
      name: "paypal",
      iconName: icons.PaypalIcon,
      isSelected: false,
      id: "paypal",
      defaultValue: "PayPal.me/",
      followMe: " Follow me on paypal",
    },
    {
      name: "cashapp",
      iconName: icons.CashAppIcon,
      isSelected: false,
      id: "cashapp",
      defaultValue: "cash.app/",
      followMe: " Follow me on cashapp",
    },
    {
      name: "dayflash",
      iconName: icons.DayFlash,
      isSelected: false,
      id: "dayflash",
      defaultValue: "Add a link",
      followMe: " Follow me on dayflash",
    },
    {
      name: "thedots",
      iconName: icons.thedots,
      isSelected: false,
      id: "thedots",
      defaultValue: "Add a link",
      followMe: " Follow me on thedots",
    },
    {
      name: "quora",
      iconName: icons.QuoraIcon,
      isSelected: false,
      id: "quora",
      defaultValue: "quora.com/profile/",
      followMe: " Follow me on quora",
    },
    {
      name: "xbox",
      iconName: icons.XboxIcon,
      isSelected: false,
      id: "xbox",
      defaultValue: "Add a link",
      followMe: " Follow me on xbox",
    },
    {
      name: "soundcloud",
      iconName: icons.SoundCloudIcon,
      isSelected: false,
      id: "soundcloud",
      defaultValue: "soundcloud.com/",
      followMe: " Follow me on soundcloud",
    },
    {
      name: "wikipedia",
      iconName: icons.WikipediaIcon,
      isSelected: false,
      id: "wikipedia",
      defaultValue: "Add a link",
      followMe: " Follow me on wikipedia",
    },
    {
      name: "link",
      iconName: icons.LinkSolidIcon,
      isSelected: false,
      id: "link",
      defaultValue: "Add a link",
      followMe: " Follow me on link",
    },
  ]);
  const [selectedLink, setSelectedLink] = useState({});
  const [initialValue, setInitialValue] = useState({
    name: "",
    url: "",
    notes: "",
  });
  const [isNameEditable, setIsNameEditable] = useState(false);

  const handleLinkDivClick = (data, index) => {
    if (index === undefined) setIsNameEditable(true);
    setSelectedLink({ ...data, index });
    setInitialValue({
      name: index !== undefined ? data.name : "",
      url: data?.defaultValue || "",
      notes: data?.followMe || "",
    });
    const datalist = { ...data, isSelected: true };
    linkOptions.splice(index, 1);
    linkOptions.splice(index, 0, datalist);
    setIsAddModal(true);
  };

  const handleModalClose = () => {
    const datalist = { ...selectedLink, isSelected: false };
    linkOptions.splice(selectedLink.index, 1);
    linkOptions.splice(selectedLink.index, 0, datalist);
    setIsAddModal(false);
    setSelectedLink({});
  };
  const handleRemoveValues = (data, index) => {
    const linkList = linkOptions.map((ele) => {
      if (ele.name === data.name) ele.isSelected = false;
      return ele;
    });
    setLinkOptions(linkList);
    linksList.splice(index, 1);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
    url: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
    notes: Yup.string()
      .transform((value) => value.trim())
      .required("Required"),
  });
  const addLinkDetails = (values) => {
    const newLinkData = { ...values, iconName: selectedLink?.iconName };
    setLinksList([...linksList, newLinkData]);
    handleModalClose();
  };
  const submitLinksValue = async () => {
    const socialLinks = {};
    linksList.forEach((link) => {
      socialLinks[link?.name?.toLocaleLowerCase()] = {
        data: [link.url],
        heading: link.notes,
      };
    });
    const res = await dispatch(
      updateBusinessCards({ socialLinks: socialLinks })
    );
    if (res.status === 200) {
      storeLocalStorageData({ socialLinks: socialLinks });
      navigate(commonRoute.congratulation);
    }
  };
  return (
    <div id="add-links-container">
      <div className="left-block">
        <div className="links-block">
          <div className="link-container">
            <h3 className="text-26-750  color-ffff">Links</h3>
            <p className="text-19-400 color-ffff opacity-50 mb-3">
              Choose from suggested platform
            </p>
            <TextInput
              placeholder="Choose the social handle"
              className="bg-1B23"
              rightIcon={icons.searchIcon}
            />
            <div className="left-link-modal">
              <div className="py-3 row ">
                {linkOptions?.map((ele, i) => {
                  return (
                    <div className="col-3">
                      <div
                        onClick={() => {
                          handleLinkDivClick(ele, i);
                        }}
                        className={`link-div ${
                          ele.isSelected ? "link-active" : "bg-grey-700"
                        }`}
                      >
                        <img src={ele.iconName} alt="icon" />
                        <span className="text-12-400 color-ffff">
                          {ele.name}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-block">
        <div className="link-values">
          <p className="text-15-400 color-ffff opacity-50 mb-4">
            We found you on the below platforms
          </p>
          <div className="flex-grow-1 right-link-modal">
            {linksList?.map((val, i) => {
              return (
                <div className="values-listing">
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="social-icon">
                      <img src={val?.iconName} alt="" />
                    </div>
                    <div className="value-inputs">
                      <div className="text-round mb-2">{val?.name}</div>
                      <TextInput
                        placeholder="Note"
                        id="notes"
                        className="text-round mb-2"
                        value={val?.url}
                      />
                    </div>
                    <div
                      className="cross-icon"
                      onClick={() => handleRemoveValues(val, i)}
                    >
                      <img src={icons.crossIcon} alt="cross" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="fe-center mt-5 values-listing">
            <Button
              btnText="Next"
              className="NEXT w-100 px-4"
              rightIcon={icons.nextIcon}
              onClick={submitLinksValue}
            />
          </div>
        </div>
      </div>
      {isAddModal ? (
        <Modal closeBtn title="Add Link" onHide={handleModalClose} size="md">
          <div className="w-100 mx-auto">
            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              onSubmit={addLinkDetails}
              enableReinitialize
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldError,
                } = props;
                const { notes, url } = values;
                const handleInputChange = (e) => {
                  const { value, id } = e.target;
                  if (!touched[id]) {
                    touched[id] = true;
                  }
                  if (value === "") {
                    setFieldError(id, "");
                  }
                  handleChange(e);
                };
                return (
                  <form className="value-inputs">
                    {/* <TextInput
                      placeholder="Link name"
                      id="name"
                      className={`text-input-border-round mb-3 ${
                        touched.name && errors.name ? "b-3030" : ""
                      }`}
                      disabled={!isNameEditable}
                      value={name}
                      onChange={handleInputChange}
                    /> */}
                    <div className="d-flex mb-3">
                      <p className="w-25 color-ffff f-center">Add Note</p>
                      <TextInput
                        placeholder="Note"
                        id="notes"
                        className={`text-input-border-round  f-center ${
                          touched.notes && errors.notes ? "b-3030" : ""
                        }`}
                        value={notes}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="d-flex mb-3">
                      <p className="w-25 color-ffff f-center">Add Url</p>
                      <TextInput
                        placeholder="URL"
                        id="url"
                        className={`text-input-border-round mb-3  ${
                          touched.url && errors.url ? "b-3030" : ""
                        }`}
                        value={url}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="fe-center mt-5 values-listing">
                      <Button
                        btnText="ADD"
                        btnType="submit"
                        className="NEXT w-100 px-4"
                        onClick={handleSubmit}
                      />
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default AddLinks;
