import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "pages/App/Home";
import Security from "pages/App/Security";
import AddUserDetails from "pages/App/AddUserDetails";
import SetPin from "pages/App/SetPin";
import ChooseUserName from "pages/App/ChooseUserName";
import AddDescription from "pages/App/AddDescription/AddDescription";
import AddLinks from "pages/App/AddLinks";
import Congratulation from "pages/App/Congratulation";
import { commonRoute } from "utils/constants";
const AuthRoute = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={commonRoute.home} element={<Home />} />
        <Route exact path={commonRoute.verifyOTP} element={<Security />} />
        <Route exact path={commonRoute.addDetails} element={<AddUserDetails />} />
        <Route exact path={commonRoute.setPin} element={<SetPin />} />
        <Route exact path={commonRoute.chooseUserName} element={<ChooseUserName />} />
        <Route exact path={commonRoute.addDescription} element={<AddDescription />} />
        <Route exact path={commonRoute.addLinks} element={<AddLinks />} />
        <Route exact path={commonRoute.congratulation} element={<Congratulation />} />
        <Route path="*" element={<Navigate replace to={commonRoute.home} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AuthRoute;
