import logoImg from "assets/images/common/logo.png";
import flashIcon from "assets/images/icons/flash.png";
import nextIcon from "assets/images/icons/next.svg";
import linkedInIcon from "assets/images/icons/linkedin-fill.svg";
import googleIcon from "assets/images/icons/google.svg";
import shareIcon from "assets/images/icons/share-2.svg";
import rightIcon from "assets/images/icons/tick-correct.svg";
import cameraIcon from "assets/images/icons/Camera.svg";
import addIcon from "assets/images/icons/addIcon.svg";
import callIcon from "assets/images/icons/call.svg";
import websiteIcon from "assets/images/icons/website.svg";
import emailIcon from "assets/images/icons/email.svg";
import whatsAppIcon from "assets/images/icons/watsapp.svg";
import locationIcon from "assets/images/icons/location.svg";
import linkedInWhiteIcon from "assets/images/icons/linkedin.svg";
import artStationIcon from "assets/images/icons/art-station.svg";
import youtubeIcon from "assets/images/icons/youtub.svg";
import vimeoIcon from "assets/images/icons/vimeo.svg";
import facebookIcon from "assets/images/icons/facebook.svg";
import yelpIcon from "assets/images/icons/yelp.png";
import twitterIcon from "assets/images/icons/twitter.svg";
import istagramIcon from "assets/images/icons/instagram.svg";
import messangerIcon from "assets/images/icons/messanger.svg";
import viberIcon from "assets/images/icons/viber.svg";
import budgeIcon from "assets/images/common/medal.png";
import searchIcon from "assets/images/icons/search.svg";
import crossIcon from "assets/images/icons/cross.svg";
import caseIcon from "assets/images/icons/case.svg";
import usersIcon from "assets/images/icons/users.svg";
import checkoutIcon from "assets/images/icons/checkout.svg";
import chartIcon from "assets/images/icons/chart.svg";
import logoutIcon from "assets/images/icons/LogOut.svg";
import bellIcon from "assets/images/icons/bell.svg";
import startFillIcon from "assets/images/icons/star-fill.svg";
import premiumQuaIcon from "assets/images/icons/premium-quality.svg";
import smileIcon from "assets/images/icons/smile.svg";
import sendIcon from "assets/images/icons/send.svg";
import pencilIcon from "assets/images/icons/pencil.svg";
import blueCheckIcon from "assets/images/icons/Tick-Square.svg";
import blueCheckBudgeIcon from "assets/images/icons/blueCheckBudge.svg";
import deleteIcon from "assets/images/icons/delete.svg";
import clockIcon from "assets/images/icons/timeClock.svg";
import photoRingIcon from "assets/images/icons/photoRing.svg";
import downloadIcon from "assets/images/icons/download.svg";
import underlineIcon from "assets/images/icons/underline.svg";
import lineHeightIcon from "assets/images/icons/lineHeight.svg";
import leftAlignmentIcon from "assets/images/icons/leftAlignment.svg";
import italicIcon from "assets/images/icons/italic.svg";
import bulletsIcon from "assets/images/icons/bullets.svg";
import uploadIcon from "assets/images/icons/upload.svg";
import textIcon from "assets/images/icons/text.svg";
import forwardIcon from "assets/images/icons/forward.svg";
import backwardIcon from "assets/images/icons/backward.svg";
import qrCodeIcon from "assets/images/icons/qrcode.svg";
import greetingLogoIcon from "assets/images/icons/greetingImgeLogo.svg";
import greenWhatsAppIcon from "assets/images/icons/greenWhatsapp.png";
import copyIcon from "assets/images/icons/copyIcon.svg";
import primeumRing from "assets/images/icons/primeumring.svg";
import openLink from "assets/images/icons/right-arrow.svg";
import upiLink from "assets/images/icons/upi.svg";
import PhonePeLink from "assets/images/icons/phonepe.png";
import GpayLink from "assets/images/icons/GPay.png"
import PaytmLink from "assets/images/icons/paytm.png";
import Twitter from "assets/images/icons/twitter.json";
import ContactNumber from "assets/images/icons/contact_number.json";
import FaceBook from "assets/images/icons/facebook.json";
import Whatsapp from "assets/images/icons/whatsapp.json";
import Youtube from "assets/images/icons/youtube.json";
import Website from "assets/images/icons/website.json";
import WebsiteUrl from "assets/images/icons/website_url.json";
import Mail from "assets/images/icons/mail.json";
import location from "assets/images/icons/location.json";
import Phone from "assets/images/icons/phone.json";
import Linkedin from "assets/images/icons/linkedin.json";
import Instagram from "assets/images/icons/instagram.json";
import Telegram from "assets/images/icons/telegram.json";
import Behance from "assets/images/icons/behance.json";
import Dribble from "assets/images/icons/dribble.json";
import Medium from "assets/images/icons/medium.json";
import Pintrest from "assets/images/icons/pintrest.json";
import TelegramIcon from "assets/images/icons/telegramIcon.svg";
import Skypeicon from "assets/images/icons/skypeicon.svg";
import Behanceicon from "assets/images/icons/behanceicon.svg";
import GithubIcon from "assets/images/icons/githubIcon.svg";
import DeviantartIcon from "assets/images/icons/deviantartIcon.svg";
import SlackIcon from "assets/images/icons/slackIcon.svg";
import WeixinIcon from "assets/images/icons/weixinicon.svg";
import QqIcon from "assets/images/icons/qqIcon.svg";
import TumblrIcon from "assets/images/icons/tumblr.svg";
import RedditIcon from "assets/images/icons/square-reddit.svg";
import TiktokIcon from "assets/images/icons/tiktokIcon.svg";
import SnapchatIcon from "assets/images/icons/snapchatIcon.svg";
import LineIcon from "assets/images/icons/lineIcon.svg";
import MediumIcon from "assets/images/icons/mediumIcon.svg";
import SignalMessengerIcon from "assets/images/icons/signal-messengerIcon.svg";
import PinterestIcon from "assets/images/icons/pinterestIcon.svg";
import PaypalIcon from "assets/images/icons/paypalIcon.svg";
import QuoraIcon from "assets/images/icons/quoraIcon.svg";
import XboxIcon from "assets/images/icons/xboxIcon.svg";
import SoundCloudIcon from "assets/images/icons/soundCloud.svg";
import WikipediaIcon from "assets/images/icons/wikkipedia.svg";
import LinkSolidIcon from "assets/images/icons/link.svg";
import BaiduoIcon from "assets/images/icons/baidu-svgrepoIcon.svg";
import QzoneIcon from "assets/images/icons/qzoneIcon.svg";
import DribbbleIcon from "assets/images/icons/dribbleIcon.svg";
import CashAppIcon from "assets/images/icons/cashappIcon.svg";
import DayFlash from "assets/images/icons/dayflash.svg";
import thedots from "assets/images/icons/the dots.svg";
import Venom from "assets/images/icons/venom.svg";
import PrimeMemberShipIcon from "assets/images/icons/PrimeMemberCoin.svg";
import RoundBlueCheck from "assets/images/icons/RoundblueCheck.svg";
import Dashboard from "assets/images/icons/dashboard.svg";
import GreenTick from "assets/images/icons/check-symbol.svg";

export const icons = {
  logoImg,
  flashIcon,
  nextIcon,
  googleIcon,
  callIcon,
  linkedInIcon,
  shareIcon,
  rightIcon,
  cameraIcon,
  websiteIcon,
  emailIcon,
  whatsAppIcon,
  locationIcon,
  linkedInWhiteIcon,
  artStationIcon,
  youtubeIcon,
  vimeoIcon,
  facebookIcon,
  yelpIcon,
  twitterIcon,
  istagramIcon,
  messangerIcon,
  viberIcon,
  budgeIcon,
  addIcon,
  searchIcon,
  crossIcon,
  chartIcon,
  checkoutIcon,
  usersIcon,
  caseIcon,
  logoutIcon,
  bellIcon,
  startFillIcon,
  premiumQuaIcon,
  smileIcon,
  sendIcon,
  pencilIcon,
  blueCheckIcon,
  blueCheckBudgeIcon,
  deleteIcon,
  clockIcon,
  photoRingIcon,
  downloadIcon,
  underlineIcon,
  lineHeightIcon,
  leftAlignmentIcon,
  italicIcon,
  bulletsIcon,
  uploadIcon,
  textIcon,
  backwardIcon,
  forwardIcon,
  qrCodeIcon,
  greetingLogoIcon,
  greenWhatsAppIcon,
  copyIcon,
  primeumRing,
  openLink,
  upiLink,
  PhonePeLink,
  GpayLink,
  PaytmLink,
  Twitter,
  ContactNumber,
  FaceBook,
  WebsiteUrl,
  Youtube,
  Whatsapp,
  Website,
  Mail,
  location,
  Phone,
  Linkedin,
  Instagram,
  Telegram,
  Behance,
  Dribble,
  Medium,
  Pintrest,
  TelegramIcon,
  Skypeicon,
  Behanceicon,
  GithubIcon,
  DeviantartIcon,
  SlackIcon,
  WeixinIcon,
  QqIcon,
  TumblrIcon,
  RedditIcon,
  TiktokIcon,
  SnapchatIcon,
  LineIcon,
  MediumIcon,
  SignalMessengerIcon,
  PinterestIcon,
  PaypalIcon,
  QuoraIcon,
  XboxIcon,
  SoundCloudIcon,
  WikipediaIcon,
  LinkSolidIcon,
  BaiduoIcon,
  QzoneIcon,
  DribbbleIcon,
  CashAppIcon,
  thedots,
  DayFlash,
  Venom,
  PrimeMemberShipIcon,
  RoundBlueCheck,
  Dashboard,
  GreenTick,
}
