import React, { useEffect } from "react";
import "./Home.scss";
import { commonRoute, icons } from "utils/constants";
import user1 from "assets/images/common/user1.png";
import user2 from "assets/images/common/user2.png";
import user3 from "assets/images/common/user3.png";
import hi1 from "assets/images/common/hi1.png";
import TextInput from "components/inputs/TextInput";
import Button from "components/inputs/Button";
import indiaFlag from "assets/images/common/Flag-India.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendMobileNumber, sendOtpEvent } from "store/slices";
import { Formik } from "formik";
import * as Yup from "yup";
import { storeLocalStorageData } from "utils/helpers";
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
  }, []);
  const initialValues = {
    contact: "",
    country_code: "+91",
  };

  const validationSchema = Yup.object().shape({
    contact: Yup.string().required("Required").min(8).max(12),
    country_code: Yup.string().required("Required"),
  });

  const handleSubmitForm = async (values) => {
    const res = await dispatch(sendMobileNumber(values));
    if (res) {
      if (!res?.data?.user?.id) {
        dispatch(sendOtpEvent(values));
      }
      const IsMobileRegister = res.data?.user?.id ? "1" : "0";

      navigate(commonRoute.verifyOTP, {
        state: {
          IsMobileRegister: IsMobileRegister,
        },
      });
      storeLocalStorageData({
        IsMobileRegister: IsMobileRegister,
        contact: values.contact,
        countryCode: values.country_code,
      });
    }
  };
  return (
    <div id="home-container">
      <div className="row">
        {/* left side */}
        <div className="col-xxl-6 col-7">
          <div className="backRound-shadow">
            <div className="big-round-shadow"></div>
            <div className="medium-round-shadow"></div>
            <div className="small-round-shadow"></div>
            {/* userData */}
            <div className="user-data-1">
              <div className="d-flex align-items-end color-ffff">
                <div className="img-group">
                  <img src={user1} alt="Rahul" className="user-1-img" />
                  <img src={hi1} alt="hi" className="hi-img" />
                </div>
                <div className="user-detail">
                  <p className="text-15-500 mb-0">Rahul Sharma</p>
                  <span className="text-12-300 opacity-50">
                    I am a content writer
                  </span>
                </div>
              </div>
            </div>
            <div className="user-data-2">
              <div className="d-flex align-items-end color-ffff">
                <img src={user2} alt="Rahul" />
                <div className="user-detail">
                  <p className="text-15-500 mb-0">Rakesh Gupta</p>
                  <span className="text-12-300 opacity-50">
                    I am a Photorapher
                  </span>
                </div>
              </div>
            </div>
            <div className="user-data-3">
              <div className="d-flex align-items-end color-ffff">
                <img src={user3} alt="Rahul" />
                <div className="user-detail">
                  <p className="text-15-500 mb-0">Reshmi Choudhary</p>
                  <span className="text-12-300 opacity-50">
                    I am a Software developer
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="one-link mt-5 ms-5 ps-5 relative">
            <h2 className="text-35-500 color-ffff">One link</h2>
            <p className="text-35-300 color-C700">
              to showcase everything you got!
            </p>
            <span className="yellow-dot">
              <div className="w-11 h-11 rounded-circle bg-C700"></div>
            </span>
          </div>
        </div>
        {/* right side */}
        <div className="col-xxl-6 col-5 my-4">
          <div className="welcome-form">
            <span className="green-dot">
              <div className="w-11 h-11 rounded-circle bg-AE0C"></div>
            </span>
            <img src={icons.logoImg} alt="logo" className="logo-img" />

            <div className="mt-5 color-ffff">
              <h3 className="text-26-750 ">Welcome!</h3>
              <p className="text-23-400 d-flex align-items-center opacity-75">
                to speed networking app
                <span>
                  <img
                    src={icons.flashIcon}
                    alt="flash"
                    height={33}
                    width={33}
                  />
                </span>
              </p>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldError,
                } = props;
                const { contact, country_code } = values;
                const handleInputChange = (e) => {
                  const { value, id } = e.target;
                  if (!touched[id]) {
                    touched[id] = true;
                  }
                  if (value === "") {
                    setFieldError(id, "");
                  }
                  handleChange(e);
                };
                return (
                  <form className="w-50 ">
                    <div className="d-flex gap-2 align-items-center mt-4">
                      <span className="country-code">
                        <img alt="flag" src={indiaFlag} className="me-1" />
                        {country_code}
                      </span>
                      <TextInput
                        id="contact"
                        className={`${
                          touched.contact && errors.contact ? "b-3030" : ""
                        }`}
                        placeholder="Mobile number"
                        value={contact}
                        onChange={handleInputChange}
                        type="number"
                      />
                    </div>
                    <Button
                      btnText="Next"
                      className="NEXT w-100"
                      btnType="submit"
                      rightIcon={icons.nextIcon}
                      onClick={handleSubmit}
                    />
                    <div className=" mt-4">
                      <p className="text-center color-ffff text-17-400">
                        Sign up with
                      </p>
                      <div className="fc-center">
                        <img
                          alt="google"
                          src={icons.googleIcon}
                          className="me-4"
                        />
                        <img
                          alt="linkedin"
                          src={icons.linkedInIcon}
                          className=""
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
