import React from "react";
import goldCoinImg from "assets/images/common/gold-coin.png";
import { icons } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { handelLogout } from "store/slices";
import { useNavigate } from "react-router-dom";
import Button from "components/inputs/Button";
import toast from "react-hot-toast";
import "./NavBar.scss";
const NavBar = ({ text, isNotification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSignOff = () => {
    dispatch(handelLogout(navigate("/")));
  };
  const profileRDX = useSelector((state) => state.profile);
  const { userData } = profileRDX || {};
  const copyLink = `https://mygrid.club/${userData?.businessCard?.identifier}`;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(copyLink)
      .then(() => {
        toast.dismiss();
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy to clipboard: ", err);
      });
  };
  return (
    <div className="Card-5 w-100">
      <div className="fb-center mx-3">
        <p className="mb-0 text-15-400 font-Avenir">{text}</p>
        <div className="fc-center gap-2 ">
          <Button
            btnText="Copy your grid profile link"
            btnStyle="copy-block text-15-400"
            onClick={copyToClipboard}
          />
          <span className="gold-coin-block m-0 px-3">
            <img src={goldCoinImg} alt="coin" height={28} width={28} />
            <span className="text-21-500">{userData?.availableCoins || 0}</span>
          </span>
          {isNotification && (
            <button className="px-2">
              <img src={icons.bellIcon} alt="notification" />
            </button>
          )}

          <button className="ps-2" onClick={handleSignOff}>
            <img src={icons.logoutIcon} alt="logout" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
