import ConnectUnreadUserCard from "components/layouts/ConnectUnreadUserCard";
import { useSocket } from "context/socket";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleSocketLister } from "store/slices/connectSlice";
import { commonRoute } from "utils/constants";
import { getDataFromLocalStorage } from "utils/helpers";

const UnreadMessageUsersList = ({ SetIsUnreadMsg, isUnreadMsg }) => {
  const userId = getDataFromLocalStorage("userId");
  const dispatch = useDispatch();
  const isFirstRenderRef = useRef(true);
  const socketContext = useSocket();
  const { socket } = socketContext.state;
  const connectRDX = useSelector((state) => state.connect);
  const { connectList, chatRoomsList } = connectRDX || {};
  const [unreadUserList, setUnreadUserList] = useState([]);
  useEffect(() => {
    if (isFirstRenderRef.current && socket) {
      socket?.emit("connects", {});
      socket?.emit("chat-rooms", {});
      isFirstRenderRef.current = false;
    }
  }, [socket]);

  useEffect(() => {
    handleUpdateConnectList(connectList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomsList, connectList]);

  const handleUpdateConnectList = (connectData) => {
    const matchedRooms = [];
    connectData?.forEach((ele) => {
      const room = chatRoomsList.find((room) =>
        room.members.some((member) => member.userId === ele.id)
      );
      if (ele?.connect?.status === 4 && _.get(room, ["unreadCount", userId])) {
        matchedRooms.push({
          ...ele,
          lastMessage: room?.lastMessage,
          unreadCounts: _.get(room, ["unreadCount", userId], 0),
          roomId: room?.id,
        });
      }
    });

    // Filter out the null elements
    const sortedRooms = _.orderBy(
      matchedRooms.filter(Boolean),
      "unreadCounts",
      "desc"
    );
    if (sortedRooms?.length > 0) {
      setUnreadUserList(sortedRooms);
      SetIsUnreadMsg(true);
    }
  };
  const navigate = useNavigate();
  const handleNaviget = (ele) => {
    navigate(commonRoute.myConnects, { state: { selectedUser: ele } });
  };
  useEffect(() => {
    if (socket) {
      dispatch(handleSocketLister(socket));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  return (
    <div
      id="unreadmessage-id"
      className={`wth-40 ps-3 ${!isUnreadMsg ? "d-none" : "d-block"} `}
    >
      <p className="text-18-400 font-Avenir mb-3">
        You have some unread messages!
      </p>
      <div className="card-overflow">
        {unreadUserList?.map((ele) => (
          <div
            onClick={() => {
              handleNaviget(ele);
            }}
          >
            <ConnectUnreadUserCard className="my-2" data={ele} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnreadMessageUsersList;
