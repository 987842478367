import { createSlice } from "@reduxjs/toolkit";
import { api } from "services/api";
import { handelCatch, handelResponse } from "./globalSlice";
import { getDataFromLocalStorage, storeLocalStorageData } from "utils/helpers";
import { resetCommonState } from "./commonSlice";
import { resetConnectState } from "./connectSlice";
import { resetProfileState, storeBannerList } from "./profileSlice";
import { resetGreetingState } from "./greetingSlice";
import { resetAnalyticsState } from "./analyticsSlice";
const initialState = {
  isAuthenticate: false,
  userData: {},
  authError: "",
  loading: false,
  links: [],
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: (state) => initialState,
    setLoading(state, action) {
      state.loading = action.payload;
    },
    storeUserAuth(state, action) {
      state.isAuthenticate = action.payload;
    },
    storeUserLinks(state, action) {
      state.links = action.payload;
    },
  },
});

export const { resetAuthState, storeUserAuth, storeUserLinks, setLoading } =
  authSlice.actions;

export const sendMobileNumber = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/auth/check", payload);
    const responseData = await dispatch(handelResponse(res));
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const sendOtpEvent = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/auth/send-otp", payload);
    const responseData = await dispatch(handelResponse(res));
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const sendOtpForVerify = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/auth/verify-otp", payload);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.data?.verifyToken) {
      storeLocalStorageData({ verifyToken: responseData?.data?.verifyToken });
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const handleResetPin = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/auth/reset-pin", payload);
    const responseData = await dispatch(handelResponse(res));
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const handleRegisterUser = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/auth/register", payload);
    const responseData = await dispatch(handelResponse(res));
    if (responseData.status === 200) {
      storeLocalStorageData({
        token: responseData?.data["x-access-token"],
        coins: responseData?.data?.coins,
        pin: payload.pin,
      });
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const uploadUserMedia = (formData) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.post("/profile-media", formData, {
      "Content-type": "multipart/form-data",
    });
    const responseData = await dispatch(handelResponse(res));
    if (responseData.status === 200) {
      storeLocalStorageData(responseData?.data);
    }
    dispatch(setLoading(false));
    return responseData;
  } catch (error) {
    dispatch(setLoading(false));
    return dispatch(handelCatch(error));
  }
};
export const addBusinessName = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/business-cards", payload);
    const responseData = await dispatch(handelResponse(res));
    if (responseData.status === 200 && responseData?.data?.businessCard) {
      storeLocalStorageData({
        identifier: responseData?.data?.businessCard?.identifier,
      });
      const { socialLinks } = responseData?.data?.businessCard?.contactDetail;
      storeLocalStorageData({ socialLinks: socialLinks });
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const addUserIntroductions = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/user-introductions", payload);
    const responseData = await dispatch(handelResponse(res));
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const updateBusinessCards = (payload) => async (dispatch) => {
  try {
    const res = await api.put("/business-cards", payload);
    const responseData = await dispatch(
      handelResponse({ ...res, status: 200 })
    );
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const handleLogin = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/auth/login", payload);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      storeLocalStorageData({ pin: payload?.pin });
      storeLocalStorageData({
        token: responseData?.data["x-access-token"],
        userId: responseData?.data?.user?.id,
        businessCardId: responseData?.data?.user?.businessCard?.id
      });
      if (responseData?.data?.user !== null) {
        dispatch(storeUserAuth(true));
      }
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const handleIsAuthenticate = () => async (dispatch) => {
  const isLogin =
    getDataFromLocalStorage("token") && getDataFromLocalStorage("userId");
  if (isLogin) {
    dispatch(storeUserAuth(true));
  } else {
    dispatch(storeUserAuth(false));
  }
};

export const handelLogout = () => async (dispatch) => {
  localStorage.clear();
  // caches?.keys().then((names) => {
  //   names.forEach((name) => {
  //     caches?.delete(name);
  //   });
  // });
  dispatch(storeUserAuth(false));
  dispatch(resetAuthState());
  dispatch(resetCommonState());
  dispatch(resetConnectState());
  dispatch(resetGreetingState());
  dispatch(resetProfileState());
  dispatch(resetAnalyticsState());
  dispatch(storeBannerList());
};

export default authSlice.reducer;
