import { createSlice } from "@reduxjs/toolkit";
import { api } from "services/api";
import { handelCatch, handelResponse } from "./globalSlice";
import _, { cloneDeep } from "lodash";
import { getDataFromLocalStorage } from "utils/helpers";
const initialState = {
  connectError: "",
  offerData: [],
  connectList: [],
  updateConnectList: [],
  connectsListByCategory: [],
  chatRoomsList: [],
  chatMessages: [],
  chatRoomData: {},
  requestedList: [],
  requestedPendingList: [],
  isChatRoomUpdate: false,
  viewUserData: {},
  isViewDataLoading: false,
  userRatingList: [],
  connectCount: 0,
  userCountRating: {},
};
const connectSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {
    resetConnectState: (state) => initialState,
    setConnectError(state, action) {
      state.connectError = action.payload;
    },
    storeConnectList(state, action) {
      state.connectList = action.payload;
    },
    storeUpdateConnectsList(state, action) {
      state.updateConnectList = action.payload;
    },
    storeConnectsListByCategory(state, action) {
      state.connectsListByCategory = action.payload;
    },
    storeChatRoomsList(state, action) {
      state.chatRoomsList = action.payload;
    },
    storeChatMessages(state, action) {
      state.chatMessages = action.payload;
    },
    storeChatRoom(state, action) {
      state.chatRoomData = action.payload;
    },
    storeRequestedList(state, action) {
      state.requestedList = action.payload;
    },
    storeRequestedPendingList(state, action) {
      state.requestedPendingList = action.payload;
    },
    storeViewUserData(state, action) {
      state.viewUserData = action.payload;
    },
    storeUserRatingList(state, action) {
      state.userRatingList = action.payload;
    },
    storeViewDataLoading(state, action) {
      state.isViewDataLoading = action.payload;
    },
    setIsChatRoomUpdate(state, action) {
      state.isChatRoomUpdate = action.payload;
    },
    setRecentConnecttCounts(state, action) {
      state.connectCount = action.payload;
    },
    storeUserCountRating(state, action) {
      state.userCountRating = action.payload;
    },

  }
});

export const {
  resetConnectState,
  setConnectError,
  storeConnectList,
  storeUpdateConnectsList,
  storeConnectsListByCategory,
  storeChatRoomsList,
  storeChatMessages,
  storeChatRoom,
  storeRequestedList,
  storeRequestedPendingList,
  storeViewUserData,
  storeUserRatingList,
  storeViewDataLoading,
  setIsChatRoomUpdate,
  setRecentConnecttCounts,
  storeUserCountRating,
} = connectSlice.actions;

// export const getConnectsList = () => async (dispatch, getState) => {
//     try {
//         const res = await api.get(`/connects`);
//         const responseData = await dispatch(handelResponse(res));
//         if (responseData.status === 200) {
//             dispatch(storeConnectList(responseData?.data))
//         }
//         return responseData
//     } catch (error) {
//         return dispatch(handelCatch(error));
//     }
// };
export const updateConnectsList = (roomData) => (dispatch, getState) => {
  dispatch(setIsChatRoomUpdate(true));
  const newChatRoomList = getState().connect.chatRoomsList?.map((ele) => {
    const chatDetails = cloneDeep(ele);
    if (chatDetails.id === roomData.id) {
      chatDetails.unreadCount = roomData.unreadCount;
      chatDetails.lastMessage = roomData.lastMessage;
    }
    return chatDetails;
  });
  dispatch(storeChatRoomsList(newChatRoomList));
  dispatch(storeChatRoom(roomData));
};

export const viewUserDetails = (id) => async (dispatch, getState) => {
  try {
    if (getState().auth.loading) return;
    dispatch(storeViewDataLoading(true));
    const res = await api.get(`/users/${id}`);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeViewUserData(responseData?.data));
    }
    dispatch(storeViewDataLoading(false));
    return responseData;
  } catch (error) {
    dispatch(storeViewDataLoading(false));
    return dispatch(handelCatch(error));
  }
};
export const getUserRatingList = (id) => async (dispatch, getState) => {
  try {
    const res = await api.get(`/business-cards/${id}/card-ratings?page=0`);
    const responseData = await dispatch(handelResponse(res));
    if (responseData?.status === 200) {
      dispatch(storeUserRatingList(responseData?.data?.data));
      dispatch(storeUserCountRating(responseData?.data?.total));

    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const sendRatings = (payload, id) => async (dispatch, getState) => {
  try {
    const res = await api.post(`/business-cards/${id}/card-ratings`, payload);
    const responseData = await dispatch(handelResponse(res));
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const handleOnlineStatus =
  (onlineData, socket) => (dispatch, getState) => {
    const onlineUsersId = onlineData?.filter(
      (ele) => Object.values(ele)[0] === 1
    );
    if (onlineUsersId?.length) {
      onlineUsersId?.forEach((onlineUser) => {
        const room = _.find(getState().connect.chatRoomsList, (room) =>
          _.some(room.members, { userId: parseInt(Object.keys(onlineUser)[0]) })
        );
        socket.emit(
          "delivered-message",
          JSON.stringify({ messageId: room?.lastMessage?.id })
        );
      });
    }
  };
export const sendAcceptRequest = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/connects/${payload.connectId}/accept`, {
      category: payload?.category
    });

    const responseData = await dispatch(handelResponse(res));
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getConnectsCount = (connectList) => async (dispatch) => {
  let count = 0;
  const userId = getDataFromLocalStorage("userId");
  connectList?.forEach((ele) => {
    if (ele?.connect?.shared_by_id === userId) {
      count = count + 1;
    }
  });
  dispatch(setRecentConnecttCounts(count));
}
export const handleSocketLister = (socket) => (dispatch, getState) => {
  if (socket) {
    socket.on(`user_${getDataFromLocalStorage("userId")}`, (data) => {
      const { type } = JSON.parse(data);


      switch (type) {
        case "connects":
          dispatch(getConnectsCount(JSON.parse(data).connects))
          dispatch(storeConnectList(JSON.parse(data).connects));
          break;
        case "dashboard":
          // handleConnectsList(JSON.parse(data));
          break;
        case "connect":
          socket?.emit("connects", {});
          socket?.emit("chat-rooms", {});
          break;
        case "chat_rooms":
          dispatch(storeChatRoomsList(JSON.parse(data).chat_rooms));
          break;
        case "messages":
          dispatch(storeChatMessages([...JSON.parse(data).messages]));
          break;
        case "chat_room":
          dispatch(updateConnectsList(JSON.parse(data).chat_room));
          break;
        case "clear_unread_count":
          dispatch(updateConnectsList(JSON.parse(data).chat_room));
          break;
        case "offline":
          // handleConnectsList(JSON.parse(data));
          break;
        case "online-status":
          dispatch(handleOnlineStatus(JSON.parse(data).online_status, socket));
          break;
        case "seen":
          // handleConnectsList(JSON.parse(data));
          break;
        default:
          break;
      }
    });
  }
};
export default connectSlice.reducer;
