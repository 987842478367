import React from "react";
import Modal from "../Modal";
import TextInput from "components/inputs/TextInput";

const CopyContactDetailsModal = ({ onClose, data }) => {
  return (
    <Modal onHide={onClose} size="md" title="Copy Contact Detail" closeBtn>
      <div className="w-75 mx-auto">
        {data?.map((ele, i) => {
          return (
            <React.Fragment key={i}>
              <TextInput
                value={ele}
                className="b-1B23"
                disabled
                onChange={() => {}}
                isCopy
                isTab
                linkValue={ele}
              />
            </React.Fragment>
          );
        })}
      </div>
    </Modal>
  );
};

export default CopyContactDetailsModal;
