import toast from "react-hot-toast";
import Label from "../Label";
import "./TextInput.scss";
import { icons } from "utils/constants/icons";
import { downloadURI } from "utils/helpers";
const TextInput = ({
  className,
  error,
  placeholder,
  onChange,
  name,
  value,
  reference,
  id,
  disabled,
  labelClass,
  autoFocus,
  onBlur,
  label,
  isRequired,
  rightIcon,
  type,
  isCopy,
  isTab,
  linkValue,
  center,
}) => {
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast.dismiss();
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy to clipboard: ", err);
      });
  };

  const openLink = (value) => {
    const trimmedLinkValue = value ? value.trim() : "";
    const formattedLink = trimmedLinkValue?.startsWith("https://")
      ? trimmedLinkValue
      : `https://${trimmedLinkValue}`;
    downloadURI(formattedLink);
  };

  return (
    <div id="TextInput-container">
      {label && (
        <Label label={label} required={isRequired} className={labelClass} />
      )}
      <div className="input-container">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <input
              id={id}
              autoComplete="off"
              placeholder={placeholder}
              name={name}
              type={type || "text"}
              className={`noscroll text-truncate ${
                className ? `text-input ${className} ` : "text-input"
              }`}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={reference}
              disabled={disabled}
              autoFocus={autoFocus}
            />
          </div>
          {isTab && (
            <div
              className="copy-icon-container"
              onClick={() => {
                openLink(linkValue);
              }}
            >
              <img src={icons.openLink} alt="" width={20} height={20} />
            </div>
          )}
          {isCopy && (
            <div className="copy-icon-container" onClick={copyToClipboard}>
              <img src={icons.copyIcon} alt="" width={20} height={20} />
            </div>
          )}
          {rightIcon && (
            <img src={rightIcon} alt="icon" className="right-icon" />
          )}
        </div>
        {error && (
          <span
            className={`text-13-400 pt-1 d-flex align-items-center gap-2 ${center}`}
          >
            <span style={{ color: "red" }}>{error}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default TextInput;
