import React, { useState } from "react";
import Modal from "../Modal";
import Dropdown from "components/inputs/Dropdown";
import Button from "components/inputs/Button";
import { useSelector } from "react-redux";

const SelectCategoriesForAccept = ({ onClose, onSave }) => {
  const [selectCtg, setSelectCtg] = useState("Others");
  const [error, setError] = useState(false);
  const profileRDX = useSelector((state) => state.profile);
  const { userData } = profileRDX || {};
  const ctgList = Object.keys(userData?.categories || {}).length
    ? Object.keys(userData?.categories)?.map((ctg) => ({ id: ctg, label: ctg }))
    : [{ id: "Others", label: "Others" }];
  const handleSave = () => {
    if (selectCtg === "") return setError(true);
    onSave(selectCtg);
  };
  return (
    <Modal onHide={onClose} size="md" title="Select category" closeBtn>
      <div className="w-75 mx-auto">
        <Dropdown
          id="Category"
          placeholder="Category"
          onChange={(e) => {
            setSelectCtg(e.target.value);
            if (error) setError(false);
          }}
          options={ctgList}
          value={selectCtg}
          className={`mb-3`}
          errorClass={`${error ? "b-3030 border-redius-3030" : ""}`}
          selectStyle="SEARCH-2531"
          isSearchable={false}
        />
        <div className="fe-center p-3 pt-4 gap-2">
          <Button
            btnText="Add to Category"
            btnStyle="BLACK-BLUE"
            className="rounded-pill text-17-400"
            onClick={handleSave}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SelectCategoriesForAccept;
