import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import 'assets/css/color.scss';
import 'assets/css/common.scss'
import 'assets/css/typography.scss'
import AppRoute from 'route/appRoute';
import AuthRoute from 'route/authRoute';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSocket } from 'context/socket';
import { Toaster } from 'react-hot-toast';
import { getDataFromLocalStorage } from 'utils/helpers';

function App() {
  const socketContext = useSocket();
  // eslint-disable-next-line no-unused-vars
  const { userData } = useSelector((state) => ({
    userData: state.profile.userData
  }))

  const isAuthenticate = getDataFromLocalStorage("token") && getDataFromLocalStorage("userId") ? true : false;
  useEffect(() => {
    if (isAuthenticate) {
      socketContext.createConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticate])

  return (
    <div className="App">
      <Toaster
        position="bottom-center"
        reverseOrder={false} />
      {isAuthenticate ? <AppRoute /> : <AuthRoute />}

    </div>
  );
}

export default App;
