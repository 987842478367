import React, { useEffect, useState } from "react";
import "./ConnectUserCard.scss";
import { commonRoute, logPrimeButtonClickEvent } from "utils/constants";
import Button from "components/inputs/Button";
import StarRatings from "../StarRatings";
import userConnectImg from "assets/images/common/dummyBanner.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  sendConnectRequest,
  setPrimeMemberModal,
  storeDiscoverList,
} from "store/slices";
import { capitalize, cloneDeep } from "lodash";
import { getDataFromLocalStorage } from "utils/helpers";
import { useSocket } from "context/socket";
import GridCoinSent from "../GridCoinSent/GridCoinSent";
import { useLocation, useNavigate } from "react-router-dom";
import { setRecentConnecttCounts } from "store/slices/connectSlice";

// import { handleSocketLister } from "store/slices/connectSlice";
// eslint-disable-next-line
const userId = getDataFromLocalStorage("userId");
const ConnectUserCard = ({
  data,
  setIsProfileView,
  handleViewProfile,
  CardId,
}) => {
  const [isConnect, SetIsConnect] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isCount, setIsCount] = useState(0);
  const socketContext = useSocket();
  // eslint-disable-next-line
  const { socket } = socketContext.state;
  const dispatch = useDispatch();
  const location = useLocation();
  const { discoverList } = useSelector((state) => ({
    discoverList: state.common.discoverList,
  }));
  const profileRDX = useSelector((state) => state.profile);
  // eslint-disable-next-line
  const { userData } = profileRDX || {};
  const connectRDX = useSelector((state) => state.connect);
  const { connectCount } = connectRDX || {};

  const sendRequest = async () => {
    if (!userData?.prime && connectCount > 4) {
      if (location?.pathname === "/dashboard") {
        logPrimeButtonClickEvent("dashboard");
      } else if (location?.pathname === "/discover-all") {
        logPrimeButtonClickEvent("discover");
      }
      return dispatch(setPrimeMemberModal(true));
    }
    if (data.connect_status === 3) return;
    const res = await dispatch(sendConnectRequest({ userId: data.id }));
    // if (res.status === 200 && socket) {10
    //   dispatch(handleSocketLister(socket));
    // }
    if (res?.data?.message) {
      SetIsConnect(true);
      dispatch(setRecentConnecttCounts(connectCount + 1));

      let modalOpen = setTimeout(() => setIsModal(true));

      if (data?.connect_status === 3 || data?.connect_status === 4) {
      }
      let temp = cloneDeep(discoverList)?.map((ele, i) => {
        if (ele?.id === data?.id) {
          ele.connect_status = 3;
        }
        return ele;
      });
      setIsCount(res?.data?.coins);
      dispatch(storeDiscoverList(temp));
    }
  };

  const navigate = useNavigate();
  const handleNaviget = (ele) => {
    navigate(commonRoute.myConnects, { state: { selectedUserId: ele } });
  };
  useEffect(() => {
    let timer = setTimeout(() => SetIsConnect(false), 2500);

    let modalClose = setTimeout(() => setIsModal(false), 10000);
    return () => {
      clearTimeout(timer);
      clearTimeout(modalClose);
    };
  }, [isConnect]);

  return (
    <div className="connect-user-card" key={data?.id}>
      {isModal && (
        <GridCoinSent
          onHide={() => {
            setIsModal(true);
          }}
          isModal={isModal}
          onClick={() => {
            setIsModal(false);
          }}
          coins={isCount}
          isConnect={isConnect}
        />
      )}

      <div className="bg-shadow-wrap">
        <img
          className="connect-user-bg-image"
          src={
            data?.user_introduction?.profile_media
              ? `${data?.user_introduction?.profile_media?.cdn_url}/${data?.user_introduction?.profile_media?.path}`
              : userConnectImg
          }
          alt=""
        />
      </div>
      {data?.prime ? (
        <span className="prime-btn prime-absolute">Prime</span>
      ) : null}
      <div className="connect-user-content">
        <div className="content-top">
          <h6 className="text-14-700 mt-2 mb-0  text-nowrap">
            {capitalize(data?.name)}
          </h6>
          <div className="rating-block">
            <div>
              <p className="text-11-600 text-nowrap">
                {data?.user_introduction?.headline?.length > 18
                  ? `${
                      data?.user_introduction?.headline?.slice(0, 18) + " ..."
                    }`
                  : `${data?.user_introduction?.headline}`}
              </p>
              {data?.user_rating ? (
                <StarRatings rating={parseInt(data?.user_rating?.rating)} />
              ) : null}
            </div>
            {data?.recent_connections.length > 10 ? (
              <div>
                <p className="text-12-600">
                  {data?.connections}
                  <span className="text-11-600"> connects</span>
                </p>

                <div className="connected-profile-group">
                  {data?.recent_connections?.map((imgs, i) => {
                    return (
                      <span className={`connected-profile-img${i + 1}`} key={i}>
                        <img
                          src={`${imgs?.cdn_url}/${imgs?.thumbnail}`}
                          alt="user-img"
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className="text-9-400 color-grey-350 flex-grow-1"
            dangerouslySetInnerHTML={{
              __html: `${
                data?.user_introduction?.description?.length > 150
                  ? `${
                      data?.user_introduction?.description?.slice(0, 150) +
                      " ..."
                    }`
                  : `${data?.user_introduction?.description}`
              }`,
            }}
          ></div>

          <div className="mt-2 row gx-2">
            <div className="col-6">
              <Button
                btnText="View Profile"
                btnStyle="BLACK-BLUE"
                className="text-10-400 w-100 text-center"
                onClick={() => {
                  if (!userData?.prime && connectCount > 4) {
                    dispatch(setPrimeMemberModal(true));
                  } else {
                    handleViewProfile(data);
                    setIsProfileView(true);
                  }
                }}
              />
            </div>
            <div className="col-6">
              {data.connect_status === 3 && (
                <Button
                  btnText="Sent"
                  btnStyle="BLUE-5"
                  className="text-10-400 w-100 text-center b-9EF8"
                />
              )}
              {data.connect_status === 4 && (
                <Button
                  btnText="Chat"
                  btnStyle="BLUE-5"
                  className="text-10-400 w-100 text-center b-9EF8"
                  onClick={() => {
                    handleNaviget(data?.id);
                  }}
                />
              )}
              {!(data.connect_status === 4 || data.connect_status === 3) && (
                <Button
                  btnText="Connect"
                  btnStyle="BLUE-5"
                  className="text-10-400 w-100 text-center b-9EF8"
                  onClick={() => {
                    sendRequest();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConnectUserCard;
