import { createSlice } from "@reduxjs/toolkit";
import { api } from "services/api";
import toast, { Toaster } from "react-hot-toast";

import { handelCatch, handelResponse } from "./globalSlice";
const initialState = {
  dashLoading: false,
  discoverLoading: false,
  offerData: [],
  discoverList: [],
  analyticsData: [],
  dashError: "",
};
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetCommonState: (state) => initialState,
    setDashLoading(state, action) {
      state.dashLoading = action.payload;
    },
    setDiscoverLoading(state, action) {
      state.discoverLoading = action.payload;
    },
    setDashError(state, action) {
      state.dashError = action.payload;
    },
    storeOfferData(state, action) {
      state.offerData = action.payload;
    },
    storeAnalyticsData(state, action) {
      state.analyticsData = action.payload;
    },
    storeDiscoverList(state, action) {
      state.discoverList = action.payload;
    },
  },
});

export const {
  resetCommonState,
  storeOfferData,
  storeDiscoverList,
  storeAnalyticsData,
  setDashLoading,
  setDiscoverLoading,
  setDashError,
} = commonSlice.actions;

export const getDashboardData = () => async (dispatch, getState) => {
  // try {
  //     if (getState().common.dashLoading) return;
  //     dispatch(setDashLoading(true));
  //     const res = await api.get("/dashboard");
  //     const responseData = await dispatch(handelResponse(res));
  //     if (responseData.status === 200) {
  //         dispatch(storeUserData(responseData?.data?.data?.user));
  //         dispatch(storeDiscoverList(responseData?.data?.data?.discover));
  //     }
  //     dispatch(setDashLoading(false));
  //     return responseData
  // } catch (error) {
  //     dispatch(setDashLoading(false));
  //     return dispatch(handelCatch(error));
  // }
};
export const getDiscoverList =
  (queryValue, page) => async (dispatch, getState) => {
    try {
      const res = await api.get(`/discover?${queryValue}`);
      const responseData = await dispatch(handelResponse(res));
      if (responseData.status === 200) {
        if (page === 1) {
          dispatch(storeDiscoverList(responseData?.data?.data));
        } else {
          dispatch(
            storeDiscoverList([
              ...getState().common.discoverList,
              ...responseData?.data?.data,
            ])
          );
        }
      }
      return responseData;
    } catch (error) {
      return dispatch(handelCatch(error));
    }
  };
export const sendConnectRequest = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/connects`, payload);
    const responseData = await dispatch(handelResponse(res));
    if (responseData.data.message) {
      toast.dismiss();
      toast.success("Connect request has been sent.");
    }
    return responseData;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export default commonSlice.reducer;
